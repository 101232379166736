import { CasinoHero } from '../../components/casino/CasinoHero';
import { LiveCasino } from '../../components/casino/LiveCasino';
import { CasinoFeatures } from '../../components/casino/CasinoFeatures';
import { CasinoGuide } from '../../components/casino/CasinoGuide';
import { CasinoSEOContent } from '../../components/casino/CasinoSEOContent';
import { CoinRain } from '../../components/effects/CoinRain';
import { Helmet } from 'react-helmet-async';

export const CasinoPage = () => {
  return (
    <>
      <Helmet>
        <title>คาสิโนออนไลน์ - NIGOAL</title>
      </Helmet>
      <div className="min-h-screen bg-black">
        <CasinoHero />
        <LiveCasino />
        <CasinoFeatures />
        <CasinoGuide />
        <CasinoSEOContent />
        <CoinRain />
      </div>
    </>
  );
};