
import { DemoHero } from '../../components/demo/DemoHero';
import { DemoGameGrid } from '../../components/demo/DemoGameGrid';
import { DemoGames } from '../../components/demo/DemoGames';
import { DemoFeatures } from '../../components/demo/DemoFeatures';
import { DemoGuide } from '../../components/demo/DemoGuide';
import { CoinRain } from '../../components/effects/CoinRain';

export const DemoPage = () => {
  return (
    <div className="min-h-screen bg-black pt-16">
      <DemoHero />
      <DemoGameGrid />
      <DemoGames />
      <DemoFeatures />
      <DemoGuide />
      <CoinRain />
    </div>
  );
};