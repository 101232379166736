import React from 'react';
import { motion } from 'framer-motion';
import { Gamepad2, Gift, Target, Shield } from 'lucide-react';
import { BrandLink } from '../common/BrandLink';

export const DemoHero: React.FC = () => {
  return (
    <div className="relative bg-gradient-to-b from-black to-red-900/20 py-16">
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center max-w-4xl mx-auto"
        >
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            <BrandLink name="NIGOAL" /> ทดลองเล่น <span className="text-red-500">เกมส์ฟรี</span>
          </h1>
          <p className="text-xl text-gray-300 mb-8">
            <BrandLink name="nigoal555" /> ทดลองเล่นเกมส์ฟรีได้ทุกค่าย พร้อมเครดิตฟรี 100,000 บาท ไม่ต้องฝาก ไม่ต้องสมัคร เล่นได้ไม่จำกัด
          </p>
          
          <div className="grid md:grid-cols-4 gap-6">
            <div className="bg-gradient-to-br from-red-900/30 to-black p-6 rounded-xl">
              <Gamepad2 className="text-red-500 w-12 h-12 mx-auto mb-4" />
              <h3 className="text-xl font-bold text-white mb-2"><BrandLink name="nigoal" /> เกมส์ครบครัน</h3>
              <p className="text-gray-300">ทดลองเล่นได้ทุกเกมส์</p>
            </div>
            
            <div className="bg-gradient-to-br from-red-900/30 to-black p-6 rounded-xl">
              <Gift className="text-red-500 w-12 h-12 mx-auto mb-4" />
              <h3 className="text-xl font-bold text-white mb-2"><BrandLink name="nigoal555" /> เครดิตฟรี</h3>
              <p className="text-gray-300">รับเครดิตฟรี 100,000</p>
            </div>
            
            <div className="bg-gradient-to-br from-red-900/30 to-black p-6 rounded-xl">
              <Target className="text-red-500 w-12 h-12 mx-auto mb-4" />
              <h3 className="text-xl font-bold text-white mb-2"><BrandLink name="NIGOAL" /> ไม่ต้องสมัคร</h3>
              <p className="text-gray-300">เล่นได้ทันที</p>
            </div>

            <div className="bg-gradient-to-br from-red-900/30 to-black p-6 rounded-xl">
              <Shield className="text-red-500 w-12 h-12 mx-auto mb-4" />
              <h3 className="text-xl font-bold text-white mb-2"><BrandLink name="nigoal" /> ปลอดภัย 100%</h3>
              <p className="text-gray-300">ระบบแยกจากระบบจริง</p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};