import { motion } from 'framer-motion';
import { Gamepad2, Dices, Trophy, Ticket } from 'lucide-react';

// Registration URL
const REGISTER_URL = "https://app.nigoal555s.com/Register/reg/d0tWemxYRFBtRjZxaUxhVnR0bVd5Zz09";

const games = [
  {
    title: "NIGOAL555 สล็อตออนไลน์",
    icon: Gamepad2,
    description: "รวมเกมสล็อตจากค่ายดังระดับโลก โบนัสแตกง่าย",
    image: "/images/promotions/slots.webp",
    altText: "NIGOAL555 สล็อตออนไลน์ - รวมเกมสล็อตยอดนิยมจากค่ายดังระดับโลก แจ็คพอตแตกง่าย โบนัสสูงสุด 100%",
    cta: "เล่นสล็อต"
  },
  {
    title: "NIGOAL555 บาคาร่าค่ายดัง",
    icon: Dices,
    description: "บาคาร่า รูเล็ต ไฮโล และเกมไพ่อื่นๆ อีกมากมาย",
    image: "/images/promotions/casino.webp",
    altText: "NIGOAL555 บาคาร่าออนไลน์ - เล่นบาคาร่า รูเล็ต ไฮโล และเกมคาสิโนออนไลน์จากค่ายดังทั่วโลก ให้บริการตลอด 24 ชั่วโมง",
    cta: "เล่นคาสิโน"
  },
  {
    title: "NIGOAL555 แทงบอล",
    icon: Trophy,
    description: "แทงบอลออนไลน์ ราคาน้ำดีที่สุด ครบทุกลีกดัง",
    image: "/images/promotions/sports.webp",
    altText: "NIGOAL555 แทงบอลออนไลน์ - แทงบอลออนไลน์ราคาน้ำดีที่สุด ครบทุกลีกดังทั่วโลก พร้อมถ่ายทอดสดทุกแมทช์การแข่งขัน",
    cta: "แทงบอล"
  },
  {
    title: "NIGOAL555 หวยออนไลน์",
    icon: Ticket,
    description: "แทงหวยออนไลน์ ครบทุกหวย จ่ายสูงสุด 1000 บาท",
    image: "/images/promotions/lottery.webp",
    altText: "NIGOAL555 หวยออนไลน์ - แทงหวยออนไลน์ครบทุกประเภท หวยรัฐบาล หวยฮานอย หวยยี่กี จ่ายสูงสุด 1000 บาท",
    cta: "แทงหวย"
  }
];

export const HomeGames = () => {
  // Function to handle registration button clicks
  const handleRegisterClick = () => {
    window.open(REGISTER_URL, '_blank');
  };

  return (
    <div className="py-16 bg-gradient-to-b from-red-900/20 to-black">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold text-white mb-4">
            NIGOAL555 เกมพนันออนไลน์ครบวงจร
          </h2>
          <p className="text-gray-300 max-w-2xl mx-auto">
            รวมเกมพนันออนไลน์ยอดนิยม พร้อมระบบทดลองเล่นฟรี
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
          {games.map((game, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="group relative overflow-hidden rounded-xl"
            >
              <img
                src={game.image}
                alt={game.altText || game.title}
                className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                onError={(e) => {
                  console.error(`Error loading image: ${game.image}`);
                  e.currentTarget.src = '/images/promotions/fallback.webp';
                }}
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black via-black/60 to-transparent">
                <div className="absolute bottom-0 p-6 w-full">
                  <game.icon className="text-red-500 mb-3" size={32} />
                  <h3 className="text-xl font-bold text-white mb-2">{game.title}</h3>
                  <p className="text-gray-300 text-sm mb-4">{game.description}</p>
                  <button 
                    className="w-full bg-red-600 hover:bg-red-700 text-white py-2 rounded-lg transition-colors"
                    onClick={handleRegisterClick}
                  >
                    {game.cta}
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};