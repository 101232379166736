export const DepositWithdrawPage = () => {
  return (
    <div className="container mx-auto px-4 pt-4 pb-8">
      <h1 className="text-3xl font-bold text-white mb-8">วิธีฝาก-ถอนเงิน</h1>
      
      <div className="grid md:grid-cols-2 gap-8 mb-12">
        <div className="bg-gray-900 rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-white mb-4">วิธีการฝากเงิน</h2>
          
          <div className="space-y-4">
            <div className="bg-gray-800 p-4 rounded-lg">
              <h3 className="text-xl font-medium text-green-500 mb-2">ขั้นตอนที่ 1: เข้าสู่ระบบ</h3>
              <p className="text-gray-300">ล็อกอินเข้าสู่บัญชีของคุณและคลิกที่ปุ่ม "ฝากเงิน"</p>
            </div>
            
            <div className="bg-gray-800 p-4 rounded-lg">
              <h3 className="text-xl font-medium text-green-500 mb-2">ขั้นตอนที่ 2: เลือกช่องทางการฝาก</h3>
              <p className="text-gray-300">เลือกช่องทางการฝากเงินที่คุณต้องการ เช่น โอนผ่านธนาคาร, ทรูมันนี่วอลเล็ท หรือ QR Code</p>
            </div>
            
            <div className="bg-gray-800 p-4 rounded-lg">
              <h3 className="text-xl font-medium text-green-500 mb-2">ขั้นตอนที่ 3: โอนเงิน</h3>
              <p className="text-gray-300">ทำการโอนเงินตามจำนวนที่ต้องการและอัพโหลดสลิปการโอนเงิน (ถ้ามี)</p>
            </div>
            
            <div className="bg-gray-800 p-4 rounded-lg">
              <h3 className="text-xl font-medium text-green-500 mb-2">ขั้นตอนที่ 4: รอยืนยัน</h3>
              <p className="text-gray-300">รอระบบยืนยันการฝากเงิน ซึ่งจะใช้เวลาไม่เกิน 1-3 นาที</p>
            </div>
          </div>
        </div>
        
        <div className="bg-gray-900 rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-white mb-4">วิธีการถอนเงิน</h2>
          
          <div className="space-y-4">
            <div className="bg-gray-800 p-4 rounded-lg">
              <h3 className="text-xl font-medium text-yellow-500 mb-2">ขั้นตอนที่ 1: เข้าสู่ระบบ</h3>
              <p className="text-gray-300">ล็อกอินเข้าสู่บัญชีของคุณและคลิกที่ปุ่ม "ถอนเงิน"</p>
            </div>
            
            <div className="bg-gray-800 p-4 rounded-lg">
              <h3 className="text-xl font-medium text-yellow-500 mb-2">ขั้นตอนที่ 2: ระบุจำนวนเงิน</h3>
              <p className="text-gray-300">ระบุจำนวนเงินที่ต้องการถอนและตรวจสอบข้อมูลบัญชีธนาคารที่ใช้รับเงิน</p>
            </div>
            
            <div className="bg-gray-800 p-4 rounded-lg">
              <h3 className="text-xl font-medium text-yellow-500 mb-2">ขั้นตอนที่ 3: ยืนยันการถอน</h3>
              <p className="text-gray-300">กดปุ่ม "ยืนยันการถอน" เพื่อส่งคำขอถอนเงิน</p>
            </div>
            
            <div className="bg-gray-800 p-4 rounded-lg">
              <h3 className="text-xl font-medium text-yellow-500 mb-2">ขั้นตอนที่ 4: รอรับเงิน</h3>
              <p className="text-gray-300">รอรับเงินเข้าบัญชีธนาคารของคุณ ซึ่งจะใช้เวลาประมาณ 5-15 นาที</p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="bg-gray-900 rounded-lg p-6">
        <h2 className="text-2xl font-semibold text-white mb-4">ข้อควรรู้</h2>
        <ul className="list-disc list-inside text-gray-300 space-y-2">
          <li>ฝากเงินขั้นต่ำ 100 บาท</li>
          <li>ถอนเงินขั้นต่ำ 300 บาท</li>
          <li>ชื่อบัญชีผู้ถอนเงินต้องตรงกับชื่อที่ลงทะเบียนไว้</li>
          <li>ติดต่อฝ่ายบริการลูกค้าได้ตลอด 24 ชั่วโมงหากมีปัญหา</li>
        </ul>
      </div>
    </div>
  );
}; 