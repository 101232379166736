
import { motion } from 'framer-motion';
import { Shield, Gift, Clock, Gamepad2, Target, Star } from 'lucide-react';

const features = [
  {
    icon: Shield,
    title: "nigoal ระบบปลอดภัย 100%",
    description: "ระบบทดลองเล่นแยกจากระบบเล่นจริง ปลอดภัยแน่นอน"
  },
  {
    icon: Gift,
    title: "nigoal เครดิตฟรีไม่อั้น",
    description: "รับเครดิตฟรี 100,000 บาท เล่นได้ไม่จำกัด"
  },
  {
    icon: Clock,
    title: "nigoal เล่นได้ 24 ชั่วโมง",
    description: "ทดลองเล่นได้ตลอด ไม่มีเวลาจำกัด"
  },
  {
    icon: Gamepad2,
    title: "nigoal เกมส์ครบทุกค่าย",
    description: "รวมเกมส์จากทุกค่ายดัง ให้เลือกเล่นมากมาย"
  },
  {
    icon: Target,
    title: "nigoal ไม่ต้องสมัครสมาชิก",
    description: "เล่นได้ทันที ไม่ต้องสมัคร ไม่ต้องฝาก"
  },
  {
    icon: Star,
    title: "nigoal มีสูตรแนะนำ",
    description: "พร้อมสูตรและเทคนิคการเล่นแบบมืออาชีพ"
  }
];

export const DemoFeatures = () => {
  return (
    <div className="py-16 bg-gradient-to-b from-black to-red-900/20">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold text-white mb-4">
            จุดเด่นของระบบทดลองเล่น nigoal
          </h2>
          <p className="text-gray-300 max-w-2xl mx-auto">
            ระบบทดลองเล่นที่ดีที่สุด พร้อมฟีเจอร์ครบครันเพื่อประสบการณ์การเล่นที่สมจริงที่สุดจาก niogal
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gradient-to-br from-red-900/30 to-black p-6 rounded-xl"
            >
              <feature.icon className="text-red-500 w-12 h-12 mb-4" />
              <h3 className="text-xl font-bold text-white mb-2">{feature.title}</h3>
              <p className="text-gray-300">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};