
import { motion } from 'framer-motion';
import { Gift, Star } from 'lucide-react';

interface Slot {
  title: string;
  image: string;
  rtp: string;
  provider: string;
  features: string[];
  minBet: string;
  maxBet: string;
}

const popularSlots: Slot[] = [
  // {
  //   title: "Fortune Tiger",
  //   image: "https://images.unsplash.com/photo-1577224682124-f87b20de2bf5?w=800",
  //   rtp: "98.5%",
  //   provider: "Pragmatic Play",
  //   features: ["ฟรีสปิน", "โบนัสเกม", "แจ็คพอตใหญ่"],
  //   minBet: "0.50",
  //   maxBet: "1,000"
  // },
  // {
  //   title: "Sweet Bonanza",
  //   image: "https://images.unsplash.com/photo-1606167668584-78701c57f13d?w=800",
  //   rtp: "97.8%",
  //   provider: "PG Soft",
  //   features: ["คูณเงินรางวัล", "สัญลักษณ์พิเศษ"],
  //   minBet: "1",
  //   maxBet: "2,000"
  // },
  // {
  //   title: "Gates of Olympus",
  //   image: "https://images.unsplash.com/photo-1533928298208-27ff66555d8d?w=800",
  //   rtp: "96.9%",
  //   provider: "Joker Gaming",
  //   features: ["รางวัลก้อนโต", "โบนัสบ่อย"],
  //   minBet: "1",
  //   maxBet: "5,000"
  // }
];

export const PopularSlots = () => {
  return (
    <div className="py-8 md:py-16 bg-gradient-to-b from-red-900/20 to-black">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-8 md:mb-12"
        >
          {/* <h2 className="mobile-heading text-white mb-3 md:mb-4">
            เกมสล็อตยอดนิยม
          </h2>
          <p className="mobile-text text-gray-300 max-w-2xl mx-auto">
            รวมเกมสล็อตทดลองเล่นฟรียอดนิยม อัตราการจ่ายสูง โบนัสแตกง่าย
          </p> */}
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
          {popularSlots.map((slot, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gradient-to-br from-red-900/30 to-black rounded-xl overflow-hidden group"
            >
              <div className="relative">
                <img 
                  src={slot.image} 
                  alt={slot.title} 
                  className="w-full h-40 md:h-48 object-cover transform group-hover:scale-110 transition-transform duration-500"
                />
                <div className="absolute top-2 right-2 bg-yellow-500 text-black px-2 py-1 rounded text-xs md:text-sm font-bold">
                  RTP {slot.rtp}
                </div>
              </div>
              
              <div className="p-4">
                <h3 className="text-lg md:text-xl font-bold text-white mb-2">{slot.title}</h3>
                <p className="text-sm text-gray-400 mb-2">ค่าย: {slot.provider}</p>
                
                <div className="flex flex-wrap gap-2 mb-3">
                  {slot.features.map((feature, idx) => (
                    <span key={idx} className="text-xs bg-red-900/30 text-red-400 px-2 py-1 rounded">
                      {feature}
                    </span>
                  ))}
                </div>
                
                <div className="flex justify-between text-xs md:text-sm text-gray-400 mb-4">
                  <span>เดิมพันต่ำสุด: {slot.minBet}</span>
                  <span>สูงสุด: {slot.maxBet}</span>
                </div>

                <div className="grid grid-cols-2 gap-2">
                  <button className="mobile-button bg-red-600 hover:bg-red-700 text-white transition-colors flex items-center justify-center gap-1">
                    <Star size={14} />
                    เล่นเกม
                  </button>
                  <button className="mobile-button border border-red-600 text-red-500 hover:bg-red-600 hover:text-white transition-colors flex items-center justify-center gap-1">
                    <Gift size={14} />
                    ทดลองเล่น
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};