import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { LoadingSpinner } from '../common/LoadingSpinner';

interface DemoGameContainerProps {
  title: string;
}

export const DemoGameContainer: React.FC<DemoGameContainerProps> = ({ title }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showIframe, setShowIframe] = useState(false);

  const fortuneTigerUrl = "https://m.pgsoft-games.com/126/index.html?l=id/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com";

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIframe(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (isFullscreen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isFullscreen]);

  return (
    <div className={`bg-gradient-to-br from-red-900/30 to-black rounded-xl overflow-hidden ${
      isFullscreen ? 'fixed inset-0 z-50 rounded-none w-screen h-screen' : ''
    }`}>
      <div className={`${
        isFullscreen ? 'absolute inset-0 w-full h-full' : 'p-4 md:p-6'
      }`}>
        <motion.h3 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className={`text-xl md:text-2xl font-bold text-white mb-4 ${
            isFullscreen ? 'hidden' : ''
          }`}
        >
          {title}
        </motion.h3>
        
        <div className={`relative ${
          isFullscreen ? 'absolute inset-0 w-full h-full' : 'aspect-[16/9]'
        }`}>
          {(isLoading || !showIframe) && (
            <div className="absolute inset-0 flex items-center justify-center bg-black">
              <LoadingSpinner />
            </div>
          )}
          {showIframe && (
            <iframe
              src={fortuneTigerUrl}
              className={`${
                isFullscreen 
                  ? 'fixed inset-0 w-screen h-screen' 
                  : 'w-full h-full'
              } border-0`}
              allowFullScreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen; payment; geolocation; microphone; camera"
              onLoad={handleIframeLoad}
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-modals allow-presentation allow-orientation-lock allow-downloads allow-pointer-lock allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
              referrerPolicy="origin"
            />
          )}
        </div>

        <button
          onClick={() => setIsFullscreen(!isFullscreen)}
          className={`${
            isFullscreen 
              ? 'fixed top-4 right-4 z-[60] bg-black/50 hover:bg-black/70' 
              : 'mt-4 w-full md:w-auto bg-red-600 hover:bg-red-700'
          } px-6 py-2 text-white rounded-lg transition-colors`}
        >
          {isFullscreen ? 'ย่อหน้าจอ' : 'ขยายเต็มจอ'}
        </button>
      </div>
    </div>
  );
};