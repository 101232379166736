
import { motion } from 'framer-motion';
import { UserPlus, CreditCard, Target, Trophy } from 'lucide-react';

const steps = [
  {
    icon: UserPlus,
    title: "สมัครสมาชิก",
    description: "สมัครสมาชิกง่ายๆ ใช้เวลาไม่เกิน 1 นาที"
  },
  {
    icon: CreditCard,
    title: "เติมเงิน",
    description: "ฝากเงินผ่านระบบอัตโนมัติ รวดเร็วทันใจ"
  },
  {
    icon: Target,
    title: "เลือกคู่ที่ต้องการ",
    description: "เลือกคู่ที่ต้องการแทง ดูราคาและวิเคราะห์บอล"
  },
  {
    icon: Trophy,
    title: "แทงบอล",
    description: "วางเดิมพันได้ทันที ลุ้นรับเงินรางวัล"
  }
];

export const SportsGuide = () => {
  return (
    <div className="py-16 bg-gradient-to-b from-red-900/20 to-black">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold text-white mb-4">
            วิธีแทงบอลออนไลน์
          </h2>
          <p className="text-gray-300 max-w-2xl mx-auto">
            เริ่มต้นแทงบอลออนไลน์ได้ง่ายๆ เพียงไม่กี่ขั้นตอน
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
          {steps.map((step, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gradient-to-br from-red-900/30 to-black p-6 rounded-xl text-center relative"
            >
              {index < steps.length - 1 && (
                <div className="hidden lg:block absolute top-1/2 -right-3 transform -translate-y-1/2 w-6 h-0.5 bg-red-600" />
              )}
              <div className="bg-red-900/30 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                <step.icon className="text-red-500 w-8 h-8" />
              </div>
              <h3 className="text-xl font-bold text-white mb-2">{step.title}</h3>
              <p className="text-gray-300">{step.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};