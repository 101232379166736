
import { Gamepad2 } from 'lucide-react';
import { FooterLinks } from './FooterLinks';
import { FooterSocial } from './FooterSocial';
import { FooterPayments } from './FooterPayments';

export const Footer = () => {
  return (
    <footer className="bg-gradient-to-b from-black to-red-950">
      <div className="container mx-auto px-4 py-12">
        <div className="grid lg:grid-cols-2 gap-12 mb-12">
          <div>
            <div className="flex items-center gap-2 mb-6">
              <Gamepad2 className="text-red-500 animate-pulse" size={32} />
              <span className="text-2xl font-bold text-white footer-logo">NIGOAL555</span>
            </div>
            <p className="text-gray-400 mb-6 max-w-lg">
              ทดลองเล่น ทดลองเล่นออนไลน์อันดับ 1 ให้บริการเกมพนันครบวงจร ทั้งสล็อต คาสิโน กีฬา และหวย 
              ด้วยระบบที่ทันสมัย ปลอดภัย และน่าเชื่อถือ พร้อมทีมงานมืออาชีพคอยให้บริการตลอด 24 ชั่วโมง จาก nigoal
            </p>
            <FooterSocial />
          </div>
          <FooterLinks />
        </div>

        <FooterPayments />
        
        <hr className="border-gray-800 my-8" />
        
        <div className="text-center text-gray-500 text-sm">
          <p className="mb-2">© 2025 NIGOAL555. All rights reserved.</p>
          <p>เว็บไซต์นี้ให้บริการเฉพาะผู้ที่มีอายุ 18 ปีขึ้นไปเท่านั้น</p>
        </div>
      </div>
    </footer>
  );
};