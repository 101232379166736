import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';

const LinePage: React.FC = () => {
  return (
    <div className="min-h-screen pt-20 pb-12 bg-gradient-to-b from-black to-gray-900">
      <Helmet>
        <title>LINE - NIGOAL555 | ติดต่อเราทางไลน์</title>
        <meta name="description" content="ติดต่อทีมงาน NIGOAL555 ผ่านทางไลน์ สแกน QR Code เพื่อเพิ่มเพื่อนและสอบถามข้อมูลเพิ่มเติม" />
      </Helmet>

      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="max-w-3xl mx-auto bg-gray-800/50 backdrop-blur-sm rounded-xl overflow-hidden shadow-2xl"
        >
          <div className="p-6 md:p-8">
            <motion.h1 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-2xl md:text-3xl font-bold text-white mb-2 text-center"
            >
              ติดต่อเราทาง LINE
            </motion.h1>
            
            <motion.p 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
              className="text-gray-300 text-center mb-8"
            >
              สแกน QR Code ด้านล่างเพื่อเพิ่มเพื่อนกับเรา สำหรับข้อมูลโปรโมชั่น การฝากถอน และความช่วยเหลือต่างๆ
            </motion.p>
            
            <motion.div 
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 0.4, type: "spring" }}
              className="flex justify-center mb-8"
            >
              <div className="bg-white p-3 rounded-xl shadow-lg">
                <img 
                  src="/images/promotions/line.jpg" 
                  alt="LINE QR Code for NIGOAL555" 
                  className="w-60 h-60 sm:w-72 sm:h-72 object-cover rounded-lg"
                />
              </div>
            </motion.div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <motion.div 
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.5 }}
                className="bg-gray-700/50 p-4 rounded-lg"
              >
                <h3 className="text-lg font-semibold text-white mb-2">ทำไมต้องติดต่อเราผ่าน LINE?</h3>
                <ul className="text-gray-300 text-sm space-y-2">
                  <li>✓ การติดต่อที่รวดเร็วตลอด 24 ชั่วโมง</li>
                  <li>✓ รับโปรโมชั่นพิเศษเฉพาะสมาชิกไลน์</li>
                  <li>✓ แจ้งปัญหาและรับการช่วยเหลือทันที</li>
                  <li>✓ อัพเดทข่าวสารและกิจกรรมล่าสุด</li>
                </ul>
              </motion.div>
              
              <motion.div 
                initial={{ x: 20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.6 }}
                className="bg-gray-700/50 p-4 rounded-lg"
              >
                <h3 className="text-lg font-semibold text-white mb-2">บริการของเรา</h3>
                <ul className="text-gray-300 text-sm space-y-2">
                  <li>✓ สมัครสมาชิกและเปิดบัญชี</li>
                  <li>✓ แจ้งฝาก-ถอนเงิน</li>
                  <li>✓ ขอรับโปรโมชั่นและโบนัส</li>
                  <li>✓ สอบถามข้อมูลเกมและการเดิมพัน</li>
                </ul>
              </motion.div>
            </div>
            
            <motion.div 
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.7 }}
              className="mt-8 text-center"
            >
              <a 
                href="https://line.me/ti/p/~@nigoal555"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-green-600 hover:bg-green-700 text-white px-6 py-3 rounded-full inline-flex items-center gap-2 transition-colors"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                </svg>
                เพิ่มเพื่อนใน LINE
              </a>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default LinePage; 