export const ContactPage = () => {
  return (
    <div className="container mx-auto px-4 pt-4 pb-8">
      <h1 className="text-3xl font-bold text-white mb-8">ติดต่อเรา</h1>
      
      <div className="grid md:grid-cols-2 gap-8 mb-12">
        <div className="bg-gray-900 rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-white mb-4">ช่องทางการติดต่อ</h2>
          
          <div className="space-y-4">
            <div className="bg-gray-800 p-4 rounded-lg flex items-start">
              <div className="bg-red-600 p-2 rounded-full mr-4 mt-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-white">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z" />
                </svg>
              </div>
              <div>
                <h3 className="text-xl font-medium text-white mb-1">Live Chat</h3>
                <p className="text-gray-300">บริการตลอด 24 ชั่วโมง คลิกที่ไอคอนแชทมุมขวาล่างของหน้าเว็บไซต์</p>
              </div>
            </div>
            
            <div className="bg-gray-800 p-4 rounded-lg flex items-start">
              <div className="bg-green-600 p-2 rounded-full mr-4 mt-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-white">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                </svg>
              </div>
              <div>
                <h3 className="text-xl font-medium text-white mb-1">LINE</h3>
                <p className="text-gray-300">LINE ID: @NIGOAL555</p>
              </div>
            </div>
            
            <div className="bg-gray-800 p-4 rounded-lg flex items-start">
              <div className="bg-blue-600 p-2 rounded-full mr-4 mt-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-white">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                </svg>
              </div>
              <div>
                <h3 className="text-xl font-medium text-white mb-1">อีเมล</h3>
                <p className="text-gray-300">support@nigoal555.com</p>
              </div>
            </div>
            
            <div className="bg-gray-800 p-4 rounded-lg flex items-start">
              <div className="bg-yellow-600 p-2 rounded-full mr-4 mt-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-white">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                </svg>
              </div>
              <div>
                <h3 className="text-xl font-medium text-white mb-1">โทรศัพท์</h3>
                <p className="text-gray-300">080-123-4567 (บริการ 24 ชั่วโมง)</p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="bg-gray-900 rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-white mb-4">ส่งข้อความถึงเรา</h2>
          
          <form className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-gray-300 mb-1">ชื่อ-นามสกุล</label>
              <input 
                type="text" 
                id="name" 
                className="w-full bg-gray-800 border border-gray-700 rounded-lg p-3 text-white focus:outline-none focus:ring-2 focus:ring-red-500"
                placeholder="กรอกชื่อ-นามสกุล"
              />
            </div>
            
            <div>
              <label htmlFor="email" className="block text-gray-300 mb-1">อีเมล</label>
              <input 
                type="email" 
                id="email" 
                className="w-full bg-gray-800 border border-gray-700 rounded-lg p-3 text-white focus:outline-none focus:ring-2 focus:ring-red-500"
                placeholder="กรอกอีเมล"
              />
            </div>
            
            <div>
              <label htmlFor="subject" className="block text-gray-300 mb-1">หัวข้อ</label>
              <select 
                id="subject" 
                className="w-full bg-gray-800 border border-gray-700 rounded-lg p-3 text-white focus:outline-none focus:ring-2 focus:ring-red-500"
              >
                <option value="">เลือกหัวข้อ</option>
                <option value="account">บัญชีผู้ใช้</option>
                <option value="deposit">การฝากเงิน</option>
                <option value="withdraw">การถอนเงิน</option>
                <option value="promotion">โปรโมชั่น</option>
                <option value="technical">ปัญหาทางเทคนิค</option>
                <option value="other">อื่นๆ</option>
              </select>
            </div>
            
            <div>
              <label htmlFor="message" className="block text-gray-300 mb-1">ข้อความ</label>
              <textarea 
                id="message" 
                rows={4} 
                className="w-full bg-gray-800 border border-gray-700 rounded-lg p-3 text-white focus:outline-none focus:ring-2 focus:ring-red-500"
                placeholder="กรอกรายละเอียด"
              ></textarea>
            </div>
            
            <button 
              type="submit" 
              className="bg-gradient-to-r from-red-600 to-red-700 hover:from-red-700 hover:to-red-800 text-white font-medium py-3 px-6 rounded-lg transition-colors"
            >
              ส่งข้อความ
            </button>
          </form>
        </div>
      </div>
      
      <div className="bg-gray-900 rounded-lg p-6">
        <h2 className="text-2xl font-semibold text-white mb-4">คำถามที่พบบ่อย</h2>
        
        <div className="space-y-4">
          <div className="bg-gray-800 p-4 rounded-lg">
            <h3 className="text-xl font-medium text-white mb-2">ฉันจะเริ่มเล่นเกมได้อย่างไร?</h3>
            <p className="text-gray-300">คุณสามารถเริ่มเล่นเกมได้หลังจากสมัครสมาชิกและทำการฝากเงินเข้าบัญชีของคุณ</p>
          </div>
          
          <div className="bg-gray-800 p-4 rounded-lg">
            <h3 className="text-xl font-medium text-white mb-2">ทำไมฉันถอนเงินไม่ได้?</h3>
            <p className="text-gray-300">อาจเกิดจากหลายสาเหตุ เช่น ยอดเงินไม่ถึงขั้นต่ำในการถอน, ยังทำเทิร์นโอเวอร์ไม่ครบตามเงื่อนไขโปรโมชั่น หรือข้อมูลบัญชีธนาคารไม่ถูกต้อง</p>
          </div>
          
          <div className="bg-gray-800 p-4 rounded-lg">
            <h3 className="text-xl font-medium text-white mb-2">เว็บไซต์ใช้งานได้บนอุปกรณ์ใดบ้าง?</h3>
            <p className="text-gray-300">เว็บไซต์ของเราสามารถใช้งานได้บนทุกอุปกรณ์ ทั้งคอมพิวเตอร์, แท็บเล็ต และโทรศัพท์มือถือทุกระบบปฏิบัติการ</p>
          </div>
          
          <div className="bg-gray-800 p-4 rounded-lg">
            <h3 className="text-xl font-medium text-white mb-2">ฉันลืมรหัสผ่าน ต้องทำอย่างไร?</h3>
            <p className="text-gray-300">คุณสามารถคลิกที่ "ลืมรหัสผ่าน" ในหน้าล็อกอิน และทำตามขั้นตอนเพื่อรีเซ็ตรหัสผ่านของคุณ</p>
          </div>
        </div>
      </div>
    </div>
  );
}; 