import { motion } from 'framer-motion';
import { FaLock, FaMoneyBillWave, FaRegClock,} from 'react-icons/fa';

export const HomeInfo = () => {
  return (
    <div className="py-16 bg-gradient-to-b from-black to-red-900/20">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="bg-gradient-to-br from-red-900/30 to-black p-8 rounded-xl border border-red-900/30"
        >
          <h1 className="text-3xl md:text-4xl font-bold text-white mb-6 text-center">
            <strong>NIGOAL</strong> - เว็บ<strong>สล็อต</strong>และ<strong>บาคาร่า</strong>ออนไลน์อันดับ 1 ที่ดีที่สุดในไทย 2024
          </h1>
          <div className="text-gray-300 space-y-4">
            <p>
              <strong>NIGOAL</strong> เว็บ<strong>สล็อต</strong>และ<strong>บาคาร่า</strong>ออนไลน์อันดับ 1 ในเมืองไทย เป็น<strong>เว็บตรง</strong>ที่ได้รับความนิยมสูงสุดในปี 2024 มั่นคง ปลอดภัย 100% เปิดให้บริการเกมคาสิโนออนไลน์แบบครบวงจร โดยเฉพาะ<strong>สล็อตออนไลน์</strong>ที่มีเกมให้เลือกมากกว่า 1,000 เกม จากค่ายชั้นนำทั่วโลก เช่น <strong>PG SLOT</strong>, <strong>JOKER GAMING</strong>, <strong>PRAGMATIC PLAY</strong> และ<strong>บาคาร่าออนไลน์</strong>ที่ถ่ายทอดสดตลอด 24 ชั่วโมง พร้อมโปรโมชั่นพิเศษสำหรับสมาชิกใหม่และสมาชิกเก่า <strong>NIGOAL</strong> มีระบบฝาก-ถอนอัตโนมัติที่รวดเร็ว ภายใน 30 วินาที ไม่มีขั้นต่ำ เล่นง่าย จ่ายจริง ไม่มีประวัติการโกง การันตีจากผู้เล่นจริงกว่า 5 แสนคน
            </p>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-8">
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                className="bg-gradient-to-br from-red-900/40 to-black p-6 rounded-lg border border-red-800/30 flex flex-col items-center text-center"
              >
                <FaLock className="text-4xl text-red-500 mb-4" />
                <h3 className="text-xl font-bold text-white mb-2"><strong>เว็บตรง</strong> 100%</h3>
                <p className="text-gray-300">ไม่ผ่านเอเย่นต์ ปลอดภัย มั่นคง เชื่อถือได้ การเงินมั่นคง จ่ายไม่อั้น</p>
              </motion.div>
              
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="bg-gradient-to-br from-red-900/40 to-black p-6 rounded-lg border border-red-800/30 flex flex-col items-center text-center"
              >
                <FaMoneyBillWave className="text-4xl text-red-500 mb-4" />
                <h3 className="text-xl font-bold text-white mb-2">ฝาก-ถอน <strong>30 วินาที</strong></h3>
                <p className="text-gray-300">ระบบออโต้ทันสมัย รวดเร็ว ไม่มีขั้นต่ำ ฝากถอนได้ตลอด 24 ชั่วโมง</p>
              </motion.div>
              
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="bg-gradient-to-br from-red-900/40 to-black p-6 rounded-lg border border-red-800/30 flex flex-col items-center text-center"
              >
                <FaRegClock className="text-4xl text-red-500 mb-4" />
                <h3 className="text-xl font-bold text-white mb-2">บริการ <strong>24 ชั่วโมง</strong></h3>
                <p className="text-gray-300">ทีมงานมืออาชีพคอยให้บริการตลอด 24 ชั่วโมง แก้ไขปัญหาได้ทันที</p>
              </motion.div>
            </div>
            
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="mt-12"
            >
              <h2 className="text-2xl md:text-3xl font-bold text-white mb-6 text-center">
                <span className="text-red-500">โปรโมชั่นพิเศษ</span> จาก <strong>NIGOAL</strong>
              </h2>
              
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="bg-gradient-to-r from-yellow-600/30 to-red-900/30 p-4 rounded-lg border border-yellow-600/30 text-center">
                  <h3 className="text-xl font-bold text-yellow-400 mb-2">สมาชิกใหม่</h3>
                  <p className="text-4xl font-bold text-white my-3">ฝาก <span className="text-yellow-400">50</span> รับ <span className="text-green-400">100</span></p>
                  <a 
                    href="https://app.nigoal555s.com/Register/reg/d0tWemxYRFBtRjZxaUxhVnR0bVd5Zz09"
                    className="inline-block bg-gradient-to-r from-red-700 to-red-500 text-white px-6 py-2 rounded-md font-bold hover:from-red-600 hover:to-red-400 transition duration-300"
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    สมัครเลย
                  </a>
                </div>
                
                <div className="bg-gradient-to-r from-green-600/30 to-red-900/30 p-4 rounded-lg border border-green-600/30 text-center">
                  <h3 className="text-xl font-bold text-green-400 mb-2">โปรยอดนิยม</h3>
                  <p className="text-4xl font-bold text-white my-3">ฝาก <span className="text-yellow-400">300</span> รับ <span className="text-green-400">400</span></p>
                  <a 
                    href="https://app.nigoal555s.com/Register/reg/d0tWemxYRFBtRjZxaUxhVnR0bVd5Zz09"
                    className="inline-block bg-gradient-to-r from-red-700 to-red-500 text-white px-6 py-2 rounded-md font-bold hover:from-red-600 hover:to-red-400 transition duration-300"
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    รับโปรโมชั่น
                  </a>
                </div>
                
                <div className="bg-gradient-to-r from-blue-600/30 to-red-900/30 p-4 rounded-lg border border-blue-600/30 text-center">
                  <h3 className="text-xl font-bold text-blue-400 mb-2">คืนยอดเสีย</h3>
                  <p className="text-4xl font-bold text-white my-3"><span className="text-green-400">5%</span> ทุกวัน</p>
                  <a 
                    href="https://app.nigoal555s.com/Register/reg/d0tWemxYRFBtRjZxaUxhVnR0bVd5Zz09"
                    className="inline-block bg-gradient-to-r from-red-700 to-red-500 text-white px-6 py-2 rounded-md font-bold hover:from-red-600 hover:to-red-400 transition duration-300"
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    รับเครดิตคืน
                  </a>
                </div>
              </div>
            </motion.div>
            
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="mt-12"
            >
              <h2 className="text-2xl md:text-3xl font-bold text-white mb-6 text-center">
                เกม<span className="text-red-500">ยอดนิยม</span>ที่ <strong>NIGOAL</strong>
              </h2>
              
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
                <div className="game-card bg-gradient-to-br from-red-900/40 to-black p-4 rounded-lg border border-red-800/30 text-center">
                  <div className="rounded-lg overflow-hidden mb-3 aspect-square bg-gray-800 flex items-center justify-center">
                    <img src="/images/promotions/th.webp" alt="Fortune Tiger" className="w-full h-full object-cover" />
                  </div>
                  <h4 className="font-bold text-white"><strong>Fortune Tiger</strong></h4>
                  <p className="text-xs text-gray-400">PG SLOT</p>
                </div>
                
                <div className="game-card bg-gradient-to-br from-red-900/40 to-black p-4 rounded-lg border border-red-800/30 text-center">
                  <div className="rounded-lg overflow-hidden mb-3 aspect-square bg-gray-800 flex items-center justify-center">
                    <img src="/images/promotions/gg.webp" alt="Sweet Bonanza" className="w-full h-full object-cover" />
                  </div>
                  <h4 className="font-bold text-white"><strong>Sweet Bonanza</strong></h4>
                  <p className="text-xs text-gray-400">PRAGMATIC PLAY</p>
                </div>
                
                <div className="game-card bg-gradient-to-br from-red-900/40 to-black p-4 rounded-lg border border-red-800/30 text-center">
                  <div className="rounded-lg overflow-hidden mb-3 aspect-square bg-gray-800 flex items-center justify-center">
                    <img src="/images/promotions/tt.webp" alt="Sexy Baccarat" className="w-full h-full object-cover" />
                  </div>
                  <h4 className="font-bold text-white"><strong>Sexy Baccarat</strong></h4>
                  <p className="text-xs text-gray-400">SA GAMING</p>
                </div>
                
                <div className="game-card bg-gradient-to-br from-red-900/40 to-black p-4 rounded-lg border border-red-800/30 text-center">
                  <div className="rounded-lg overflow-hidden mb-3 aspect-square bg-gray-800 flex items-center justify-center">
                    <img src="/images/promotions/ee.webp" alt="Zeus" className="w-full h-full object-cover" />
                  </div>
                  <h4 className="font-bold text-white"><strong>Zeus</strong></h4>
                  <p className="text-xs text-gray-400">JOKER GAMING</p>
                </div>
                
                <div className="game-card bg-gradient-to-br from-red-900/40 to-black p-4 rounded-lg border border-red-800/30 text-center">
                  <div className="rounded-lg overflow-hidden mb-3 aspect-square bg-gray-800 flex items-center justify-center">
                    <img src="/images/promotions/gg.webp" alt="Money Train" className="w-full h-full object-cover" />
                  </div>
                  <h4 className="font-bold text-white"><strong>Money Train</strong></h4>
                  <p className="text-xs text-gray-400">PRAGMATIC PLAY</p>
                </div>
              </div>
            </motion.div>
            
            <h2 className="text-2xl font-bold text-white mt-12">
              <strong>NIGOAL</strong> - <strong>เว็บตรง</strong> ไม่ผ่านเอเย่นต์ เชื่อถือได้ 100%
            </h2>
            <p>
              <strong>NIGOAL</strong> เป็น<strong>เว็บตรง</strong>ที่ไม่ผ่านเอเย่นต์หรือตัวแทนใดๆ ทำให้เรามั่นใจได้ว่าทุกการเดิมพันของคุณจะปลอดภัย และได้รับเงินรางวัลเต็มจำนวน ไม่มีการหักค่าคอมมิชชั่น เรามีใบรับรองการเล่นเกมที่ถูกกฎหมายจากต่างประเทศ พร้อมระบบความปลอดภัยระดับสูงด้วยเทคโนโลยี SSL ที่จะปกป้องข้อมูลส่วนตัวของคุณตลอดเวลา สมัครสมาชิกวันนี้ รับ<strong>โปรโมชั่น</strong>พิเศษมากมาย:
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li><strong>ฝาก 50 รับ 100</strong> - สำหรับสมาชิกใหม่ที่ฝากเงินครั้งแรก</li>
              <li><strong>ฝาก 300 รับ 400</strong> - โบนัสพิเศษสำหรับการฝากครั้งแรก</li>
              <li><strong>ฝาก 500 รับ 700</strong> - รับโบนัสเพิ่มสูงสุดถึง 40%</li>
              <li><strong>โบนัสฝากประจำวัน</strong> - รับโบนัสเพิ่มทุกครั้งที่ฝากเงินครั้งแรกของวัน</li>
              <li><strong>คืนยอดเสีย 5%</strong> - ไม่ว่าจะเล่น<strong>สล็อต</strong>หรือ<strong>บาคาร่า</strong> รับเงินคืน 5% จากยอดเสียทุกสัปดาห์</li>
            </ul>

            <h2 className="text-2xl font-bold text-white mt-12">
              <strong>NIGOALสล็อตออนไลน์</strong> - เกม<strong>สล็อต</strong>แตกง่าย จ่ายจริง เล่นได้ตลอด 24 ชั่วโมง
            </h2>
            <p>
              <strong>NIGOAL</strong> รวมเกม<strong>สล็อต</strong>ออนไลน์ชั้นนำจากทุกค่ายดัง ไม่ว่าจะเป็น <strong>PG SLOT</strong>, <strong>JOKER GAMING</strong>, <strong>PRAGMATIC PLAY</strong> และอีกมากมาย มีให้เลือกเล่นมากกว่า 1,000 เกม ด้วยระบบการเล่นที่ลื่นไหล ไม่มีสะดุด แตกง่าย จ่ายจริง รวมถึงโบนัสและแจ็คพอตก้อนใหญ่ที่ทำให้การเล่น<strong>สล็อต</strong>ของคุณสนุกและมีโอกาสทำกำไรได้มากขึ้น เกม<strong>สล็อต</strong>ยอดนิยมที่เราแนะนำ:
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li><strong>Fortune Tiger</strong> - NIGOAL เกมสล็อตจากค่าย <strong>PG SLOT</strong> ที่มีอัตราการจ่ายเงินสูงถึง 96.8%</li>
              <li><strong>Sweet Bonanza</strong> - NIGOAL เกมสล็อตจากค่าย <strong>PRAGMATIC PLAY</strong> ที่มีฟีเจอร์ FREE SPINS สุดคุ้ม</li>
              <li><strong>Wild Bandito</strong> - NIGOAL เกมสล็อตธีมคาวบอยที่มี MULTIPLIER สูงถึง x100</li>
              <li><strong>Golden Lion</strong> - NIGOAL เกมสล็อตเสือทองที่มีอัตราการแตกง่ายที่สุด</li>
              <li><strong>Mahjong Ways</strong> - NIGOAL เกมสล็อตธีมไพ่นกกระจอกที่ได้รับความนิยมอย่างมาก</li>
            </ul>

            <h2 className="text-2xl font-bold text-white mt-12">
              <strong>NIGOAL บาคาร่าออนไลน์</strong> ถ่ายทอดสดตลอด 24 ชั่วโมง
            </h2>
            <p>
              <strong>NIGOAL</strong> ให้บริการ<strong>บาคาร่าออนไลน์</strong>คุณภาพสูง ถ่ายทอดสดตรงจากคาสิโนชั้นนำทั่วโลก ด้วยภาพคมชัดระดับ Full HD ทำให้คุณเพลิดเพลินกับการเล่น<strong>บาคาร่า</strong>ได้อย่างเต็มอรรถรส เรามี<strong>บาคาร่า</strong>หลากหลายรูปแบบให้เลือกเล่น ทั้ง<strong>บาคาร่า</strong>คลาสสิค, <strong>บาคาร่า</strong>การ์ดคู่, และ<strong>บาคาร่า</strong>ประกันภัย นอกจากนี้ยังมี<strong>บาคาร่า</strong>สไตล์เซ็กซี่ที่มีดีลเลอร์สาวสวยคอยแจกไพ่ให้คุณตลอดทั้งวัน
            </p>
            <p>
              ที่ <strong>NIGOAL</strong> เราอัดแน่นด้วยห้อง<strong>บาคาร่า</strong>จากค่ายดังมากมาย เช่น <strong>SA Gaming</strong>, <strong>Evolution Gaming</strong>, <strong>WM Casino</strong> และ <strong>Sexy Baccarat</strong> ด้วยอัตราการจ่ายเงินที่สูงถึง 1:1 และโบนัสพิเศษสำหรับไพ่คู่ เราการันตีประสบการณ์การเล่น<strong>บาคาร่า</strong>ที่ดีที่สุดในเมืองไทย
            </p>

            <h2 className="text-2xl font-bold text-white mt-12">
              เทคนิคเล่น<strong>สล็อต</strong>และ<strong>บาคาร่า</strong>ให้ได้กำไร
            </h2>
            <p>
              สำหรับท่านที่เป็นมือใหม่หรือต้องการเพิ่มโอกาสในการชนะ <strong>NIGOAL</strong> มีเทคนิคการเล่น<strong>สล็อต</strong>และ<strong>บาคาร่า</strong>ที่จะช่วยให้คุณทำกำไรได้มากขึ้น:
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li><strong>NIGOALเทคนิคเล่นสล็อต</strong> - เลือกเกมที่มี RTP สูงกว่า 95%, หาช่วงเวลาแจกโบนัส, ควบคุมทุนและเลือกเดิมพันที่เหมาะสม</li>
              <li><strong>NIGOALเทคนิคเล่นบาคาร่า</strong> - ใช้สูตรไล่ไพ่, เลือกเดิมพันฝั่งเจ้ามือเนื่องจากมีโอกาสชนะมากกว่า, เล่นอย่างมีวินัยและรู้จักหยุดเมื่อกำไรถึงเป้า</li>
            </ul>

            <h2 className="text-2xl font-bold text-white mt-12">
              NIGOAL บริการฝาก-ถอนเงินอัตโนมัติ รวดเร็วภายใน 30 วินาที
            </h2>
            <p>
              <strong>NIGOAL</strong> มีระบบฝาก-ถอนเงินอัตโนมัติที่ทันสมัย รวดเร็วภายใน 30 วินาที ไม่มีขั้นต่ำ รองรับทุกธนาคารชั้นนำในประเทศไทย รวมถึง Mobile Banking, PromptPay และ True Money Wallet ทำให้คุณสามารถเติมเงินและถอนเงินได้สะดวกตลอด 24 ชั่วโมง ไม่มีวันหยุด
            </p>
            <p>
              พร้อมทั้งระบบการฝากเงินที่ปลอดภัย มีการเข้ารหัสทุกขั้นตอน และทีมงานดูแลตลอด 24 ชั่วโมง คุณจึงมั่นใจได้ว่าทุกธุรกรรมการเงินของคุณจะปลอดภัยและรวดเร็ว
            </p>

            <h2 className="text-2xl font-bold text-white mt-12">
              ทำไมต้องเลือก <strong>NIGOAL</strong>
            </h2>
            <p>
              มีหลายเหตุผลที่ทำให้ <strong>NIGOAL</strong> เป็นเว็บ<strong>สล็อต</strong>และ<strong>บาคาร่า</strong>ออนไลน์อันดับ 1 ในเมืองไทย:
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li><strong>เว็บตรง</strong> ไม่ผ่านเอเย่นต์ มั่นคง ปลอดภัย 100%</li>
              <li>เกม<strong>สล็อต</strong>และ<strong>บาคาร่า</strong>ให้เลือกเล่นมากกว่า 1,000 เกม</li>
              <li>ระบบฝาก-ถอนอัตโนมัติ รวดเร็วภายใน 30 วินาที</li>
              <li>โปรโมชั่นหลากหลาย ทั้งสำหรับสมาชิกใหม่และสมาชิกเก่า</li>
              <li>บริการลูกค้า 24 ชั่วโมง พร้อมดูแลทุกปัญหาที่คุณพบ</li>
              <li>รองรับการเล่นผ่านมือถือทุกระบบ ทั้ง iOS และ Android</li>
              <li><strong>ลิงค์รับทรัพย์</strong> ที่ช่วยให้คุณสร้างรายได้จากการแนะนำเพื่อน</li>
            </ul>

            <p className="mt-6">
              สมัครสมาชิกกับ <strong>NIGOAL</strong> วันนี้ เพื่อรับประสบการณ์การเล่น<strong>สล็อต</strong>และ<strong>บาคาร่า</strong>ออนไลน์ที่ดีที่สุด พร้อมโปรโมชั่นพิเศษมากมายที่รอคุณอยู่ สมัครง่าย ไม่กี่ขั้นตอน เริ่มต้นทำกำไรได้ทันที!
            </p>
            
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
              className="mt-12 text-center"
            >
              <div className="bg-gradient-to-r from-red-800/50 to-yellow-800/50 p-8 rounded-xl border border-red-500/30">
                <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">
                  เริ่มต้นความสนุกกับ <strong>NIGOAL</strong> วันนี้
                </h2>
                <p className="text-gray-300 mb-6">สมัครสมาชิกวันนี้ รับโบนัสทันที ฟรีเครดิต และโปรโมชั่นพิเศษมากมาย</p>
                <a 
                  href="https://app.nigoal555s.com/Register/reg/d0tWemxYRFBtRjZxaUxhVnR0bVd5Zz09" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="bg-gradient-to-r from-yellow-600 to-yellow-400 text-black px-8 py-3 rounded-md font-bold text-lg hover:from-yellow-500 hover:to-yellow-300 transition duration-300 transform hover:scale-105 inline-block"
                >
                  สมัครสมาชิกเลย
                </a>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}; 