export const FreeCreditPage = () => {
  return (
    <div className="container mx-auto px-4 pt-24 pb-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-white mb-2">เครดิตฟรี</h1>
        <p className="text-gray-400">รับเครดิตฟรีจากกิจกรรมพิเศษและโปรโมชั่นพิเศษ</p>
      </div>
      
      <div className="bg-gradient-to-r from-red-900 to-red-800 rounded-lg overflow-hidden mb-12">
        <div className="grid md:grid-cols-2">
          <div className="p-8">
            <h2 className="text-2xl font-bold text-white mb-4">รับเครดิตฟรีสูงสุด 500 บาท</h2>
            <ul className="space-y-3 text-gray-200 mb-6">
              <li className="flex items-start">
                <svg className="w-5 h-5 text-red-400 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
                <span>รับเครดิตฟรีจากกิจกรรมในเว็บไซต์</span>
              </li>
              <li className="flex items-start">
                <svg className="w-5 h-5 text-red-400 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
                <span>โปรโมชั่นประจำเดือนและเทศกาล</span>
              </li>
              <li className="flex items-start">
                <svg className="w-5 h-5 text-red-400 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
                <span>เทิร์นโอเวอร์ 10-20 เท่า ขึ้นอยู่กับโปรโมชั่น</span>
              </li>
              <li className="flex items-start">
                <svg className="w-5 h-5 text-red-400 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
                <span>ติดตามโปรโมชั่นใหม่ๆ ได้ทุกวัน</span>
              </li>
            </ul>
            
            <a 
              href="https://app.nigoal555s.com/Register/reg/d0tWemxYRFBtRjZxaUxhVnR0bVd5Zz09"
              className="inline-block bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-8 rounded-lg transition-colors"
              target="_blank" 
              rel="noopener noreferrer"
            >
              รับเครดิตฟรี
            </a>
          </div>
          <div className="bg-red-800 flex items-center justify-center p-8">
            <div className="text-center">
              <div className="text-7xl font-extrabold text-white mb-4">เครดิตฟรี</div>
              <div className="text-xl font-medium text-red-300">สูงสุด 500 บาท</div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="bg-gray-900 rounded-lg p-6 mb-12">
        <h2 className="text-2xl font-semibold text-white mb-4">กิจกรรมพิเศษประจำเดือน</h2>
        
        <div className="grid md:grid-cols-3 gap-6">
          <div className="bg-gray-800 p-5 rounded-lg">
            <div className="bg-red-600 rounded-t-lg -mt-5 -mx-5 mb-4 p-4">
              <h3 className="text-xl font-bold text-white text-center">วันเกิด</h3>
            </div>
            <p className="text-gray-400 mb-4">รับเครดิตฟรี 100 บาทในเดือนเกิดของคุณ เพียงแค่แจ้งทีมงานผ่านแชทออนไลน์พร้อมยืนยันตัวตน</p>
            <div className="text-center">
              <span className="inline-block bg-red-500 text-white px-3 py-1 rounded-full text-sm">เทิร์นโอเวอร์ 10 เท่า</span>
            </div>
          </div>
          
          <div className="bg-gray-800 p-5 rounded-lg">
            <div className="bg-red-600 rounded-t-lg -mt-5 -mx-5 mb-4 p-4">
              <h3 className="text-xl font-bold text-white text-center">กิจกรรมสปิน</h3>
            </div>
            <p className="text-gray-400 mb-4">สปินวงล้อนำโชคทุกวันและลุ้นรับเครดิตฟรีสูงสุด 500 บาท เล่นได้วันละ 1 ครั้งเมื่อมีการฝากเงิน</p>
            <div className="text-center">
              <span className="inline-block bg-red-500 text-white px-3 py-1 rounded-full text-sm">เทิร์นโอเวอร์ 15 เท่า</span>
            </div>
          </div>
          
          <div className="bg-gray-800 p-5 rounded-lg">
            <div className="bg-red-600 rounded-t-lg -mt-5 -mx-5 mb-4 p-4">
              <h3 className="text-xl font-bold text-white text-center">แชร์โซเชียล</h3>
            </div>
            <p className="text-gray-400 mb-4">แชร์โพสต์ของเราบนโซเชียลมีเดียและแท็กเพื่อน 5 คน รับเครดิตฟรี 50 บาท ทำได้เดือนละ 1 ครั้ง</p>
            <div className="text-center">
              <span className="inline-block bg-red-500 text-white px-3 py-1 rounded-full text-sm">เทิร์นโอเวอร์ 10 เท่า</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}; 