
import { motion } from 'framer-motion';
import { Zap, Shield, Clock, CreditCard, Gift, Users } from 'lucide-react';

const features = [
  {
    icon: Zap,
    title: "ฝาก-ถอนออโต้",
    description: "ระบบอัตโนมัติ รวดเร็วภายใน 30 วินาที ทำรายการได้ตลอด 24 ชั่วโมง",
    gradient: "from-yellow-600 to-yellow-800"
  },
  {
    icon: Shield,
    title: "ความปลอดภัย 100%",
    description: "มาตรฐานความปลอดภัยระดับสากล การันตีโดยผู้ให้บริการชั้นนำ",
    gradient: "from-blue-600 to-blue-800"
  },
  {
    icon: Clock,
    title: "บริการ 24 ชม.",
    description: "ทีมงานมืออาชีพพร้อมให้บริการตลอด 24 ชั่วโมง ไม่มีวันหยุด",
    gradient: "from-green-600 to-green-800"
  },
  {
    icon: CreditCard,
    title: "เริ่มต้น 1 บาท",
    description: "เดิมพันขั้นต่ำเพียง 1 บาท ฝาก-ถอนไม่มีขั้นต่ำ ไม่ต้องทำเทิร์น",
    gradient: "from-purple-600 to-purple-800"
  },
  {
    icon: Gift,
    title: "โบนัสสูงสุด 100%",
    description: "รับโบนัสต้อนรับสมาชิกใหม่สูงสุด 100% พร้อมโปรโมชั่นพิเศษทุกวัน",
    gradient: "from-red-600 to-red-800"
  },
  {
    icon: Users,
    title: "เว็บตรง ไม่ผ่านเอเย่นต์",
    description: "เว็บพนันที่ดีที่สุด จ่ายจริง มั่นคง ปลอดภัย 100%",
    gradient: "from-indigo-600 to-indigo-800"
  }
];

export const HomeFeatures = () => {
  return (
    <div className="py-16 bg-gradient-to-b from-red-900/20 via-black to-red-900/20">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold text-white mb-4">
            ทำไมต้องเลือก NIGOAL555
          </h2>
          <p className="text-gray-300 max-w-2xl mx-auto">
            ทดลองเล่นไลน์อันดับ 1 มั่นคง ปลอดภัย บริการ 24 ชั่วโมง
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              whileHover={{ scale: 1.02 }}
              className="bg-gradient-to-br from-red-900/30 to-black p-6 rounded-xl border border-red-900/30"
            >
              <div className={`w-12 h-12 rounded-full bg-gradient-to-r ${feature.gradient} flex items-center justify-center mb-4`}>
                <feature.icon className="text-white" size={24} />
              </div>
              <h3 className="text-xl font-bold text-white mb-2">{feature.title}</h3>
              <p className="text-gray-300">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};