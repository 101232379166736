import React, { createContext, useContext, useState } from 'react';
import { CoinRain } from '../components/effects/CoinRain';

interface CoinRainContextType {
  showCoinRain: () => void;
}

const CoinRainContext = createContext<CoinRainContextType | undefined>(undefined);

export const CoinRainProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const showCoinRain = () => {
    setIsVisible(true);
    setTimeout(() => setIsVisible(false), 3000);
  };

  return (
    <CoinRainContext.Provider value={{ showCoinRain }}>
      {children}
      {isVisible && <CoinRain />}
    </CoinRainContext.Provider>
  );
};

export const useCoinRain = () => {
  const context = useContext(CoinRainContext);
  if (!context) {
    throw new Error('useCoinRain must be used within a CoinRainProvider');
  }
  return context;
}; 