import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Dices, User, Menu, Gamepad2, Trophy, BookOpen, MessageCircle, Play } from 'lucide-react';

// Line messenger URL
// const LINE_URL = "https://https://qr-official.line.me/gs/M_085bvfzn_GW.png?oat_content=qr.line.me/oauth2/v2.1/login?returnUri=%2Foauth2%2Fv2.1%2Fauthorize%2Fconsent%3Fresponse_type%3Dcode%26client_id%3D1656488536%26redirect_uri%3Dhttps%253A%252F%252Fapp.nigoal555.com%252FLine%252FcallBack%26scope%3Dprofile%2520openid%2520email%26state%3D10d1d0133a36670e966b6b56116158d2f8984b8b827dda8c33c5c7331453092c&loginChannelId=1656488536&loginState=voFCk8bhCogUROks2uxQiz#/";
// Registration URL 
const REGISTER_URL = "https://app.nigoal555s.com/Register/reg/d0tWemxYRFBtRjZxaUxhVnR0bVd5Zz09";
const LOGIN_URL = "https://app.nigoal555s.com/";

const navItems = [
  { title: "สล็อต", icon: Gamepad2, path: "/slots" },
  { title: "คาสิโน", icon: Dices, path: "/casino" },
  { title: "กีฬา", icon: Trophy, path: "/sports" },
  //{ title: "หวย", icon: Ticket, path: "/lottery" },//
  // { title: "ไก่ชน", icon: Swords, path: "/cockfight" },
  { title: "ทดลองเล่น", icon: Play, path: "/demo" },
  { title: "บทความ", icon: BookOpen, path: "/articles" },
];

export const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLineClick = () => {
    navigate('/line');
  };

  const handleRegisterClick = () => {
    window.open(REGISTER_URL, '_blank');
  };

  const handleLoginClick = () => {
    window.open(LOGIN_URL, '_blank');
  };

  return (
    <nav className="bg-black/90 backdrop-blur-sm fixed w-full z-50">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <Link to="/" className="flex items-center gap-2 flex-shrink-0">
            <Gamepad2 className="text-red-600 animate-pulse" size={24} />
            <span className="text-white font-bold text-xl logo-glow"></span>
            <img 
              src="/images/promotions/logo.webp" 
              alt="NIGOAL555 เว็บพนันออนไลน์อันดับ 1 ให้บริการเกมพนันครบวงจร สล็อต คาสิโน กีฬา" 
              className="h-8 w-auto"
              onError={(e) => {
                console.error('Error loading logo');
                // Fallback to text if image fails to load
                const parent = e.currentTarget.parentElement;
                if (parent) {
                  parent.innerHTML = `
                    <div class="flex items-center gap-2">
                      <span class="text-red-600"><svg>...</svg></span>
                      <span class="text-white font-bold text-xl">NIGOAL555</span>
                    </div>
                  `;
                }
              }}
            />
          </Link>
          
          {/* Desktop Navigation */}
          <div className="hidden lg:flex items-center gap-4 xl:gap-6">
            {navItems.map((item, index) => (
              <Link
                key={index}
                to={item.path}
                className={`text-gray-300 hover:text-white transition-colors flex items-center gap-1.5 text-sm whitespace-nowrap ${
                  location.pathname === item.path ? 'text-red-500' : ''
                }`}
              >
                <item.icon size={16} />
                <span>{item.title}</span>
              </Link>
            ))}
            
            {/* Action Buttons */}
            <div className="flex items-center gap-2 ml-2">
              <button
                onClick={handleLineClick}
                className="bg-green-600 hover:bg-green-700 px-3 py-1.5 rounded-full flex items-center gap-1.5 text-sm button-glow-green whitespace-nowrap"
              >
                <MessageCircle size={16} />
                <span>LINE</span>
              </button>
              
              <button
                onClick={handleRegisterClick}
                className="bg-red-600 hover:bg-red-700 px-3 py-1.5 rounded-full flex items-center gap-1.5 text-sm button-glow-red whitespace-nowrap"
              >
                <User size={16} />
                <span>สมัครสมาชิก</span>
              </button>

              <button
                onClick={handleLoginClick}
                className="bg-blue-600 hover:bg-blue-700 px-3 py-1.5 rounded-full flex items-center gap-1.5 text-sm button-glow-red whitespace-nowrap"
              >
                <User size={16} />
                <span>เข้าสู่ระบบ</span>
              </button>
            </div>
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="lg:hidden text-white"
          >
            <Menu size={24} />
          </button>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="lg:hidden py-4 border-t border-gray-800">
            <div className="flex flex-col gap-4">
              {navItems.map((item, index) => (
                <Link
                  key={index}
                  to={item.path}
                  className={`text-gray-300 hover:text-white transition-colors flex items-center gap-2 ${
                    location.pathname === item.path ? 'text-red-500' : ''
                  }`}
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <item.icon size={16} />
                  <span>{item.title}</span>
                </Link>
              ))}
              
              <div className="flex flex-col gap-2 pt-2">
                <button
                  onClick={handleLineClick}
                  className="bg-green-600 hover:bg-green-700 px-4 py-2 rounded-full flex items-center gap-2 justify-center button-glow-green"
                >
                  <MessageCircle size={16} />
                  <span>LINE</span>
                </button>
                
                <button
                  onClick={handleRegisterClick}
                  className="bg-red-600 hover:bg-red-700 px-4 py-2 rounded-full flex items-center gap-2 justify-center button-glow-red"
                >
                  <User size={16} />
                  <span>สมัครสมาชิก</span>
                </button>

                <button
                  onClick={handleLoginClick}
                  className="bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-full flex items-center gap-2 justify-center button-glow-red"
                >
                  <User size={16} />
                  <span>เข้าสู่ระบบ</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};