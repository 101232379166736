import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import type { Article } from '../../types/article';
import { randomImage } from '../../utils/random';

export const ArticlesList = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      const response = await fetch('/static/articles/index.json');
      const data = await response.json();
      try {
        setArticles(data);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  if (loading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {[...Array(6)].map((_, i) => (
          <div key={i} className="animate-pulse">
            <div className="bg-red-900/30 h-48 rounded-lg mb-4"></div>
            <div className="h-4 bg-red-900/30 rounded w-3/4 mb-2"></div>
            <div className="h-4 bg-red-900/30 rounded w-1/2"></div>
          </div>
        ))}
      </div>
    );
  }
  
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-white mb-8">บทความทั้งหมด</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {articles.map((article) => (
          <Link 
            key={article.id}
            to={`/articles/${article.id}`}
            className="group"
          >
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="bg-gradient-to-br from-red-900/30 to-black rounded-xl overflow-hidden hover:from-red-900/40 transition-all duration-300"
            >
              <div className="aspect-video">
                <img
                  src={article.og_image}
                  alt={article.title}
                  className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.src = randomImage();
                  }}
                />
              </div>
              <div className="p-4">
                <h2 className="text-xl font-bold text-white mb-2 line-clamp-2 group-hover:text-red-500 transition-colors">
                  {article.title}
                </h2>
                <p className="text-gray-400 text-sm line-clamp-3">
                  {article.meta_description}
                </p>
                <div className="mt-4 text-sm text-gray-500">
                  {new Date(article.created_at).toLocaleDateString('th-TH')}
                </div>
              </div>
            </motion.div>
          </Link>
        ))}
      </div>
    </div>
  );
};
