
import { motion } from 'framer-motion';


const sportsEvents = [
  {
    title: "พรีเมียร์ลีก อังกฤษ",
    image: "https://images.unsplash.com/photo-1508098682722-e99c43a406b2?w=800",
    match: "แมนเชสเตอร์ ยูไนเต็ด vs ลิเวอร์พูล",
    time: "22:00",
    odds: { home: "2.15", draw: "3.40", away: "2.85" },
    features: ["ราคาน้ำดี", "สถิติครบ", "ดูบอลสด"]
  },
  {
    title: "ลาลีกา สเปน",
    image: "https://images.unsplash.com/photo-1522778119026-d647f0596c20?w=800",
    match: "เรอัล มาดริด vs บาร์เซโลน่า",
    time: "01:00",
    odds: { home: "2.30", draw: "3.25", away: "2.70" },
    features: ["วิเคราะห์บอล", "สถิติย้อนหลัง", "ดูบอลสด"]
  },
  {
    title: "บุนเดสลีกา",
    image: "https://images.unsplash.com/photo-1577223625816-7546b71dde8e?w=800",
    match: "บาเยิร์น มิวนิค vs ดอร์ทมุนด์",
    time: "20:30",
    odds: { home: "1.95", draw: "3.60", away: "3.20" },
    features: ["ทีเด็ดบอล", "ราคาบอลไหล", "ดูบอลสด"]
  }
];

export const LiveSports = () => {
  return (
    <div className="py-16 bg-gradient-to-b from-red-900/20 to-black">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          {/* <h2 className="text-3xl font-bold text-white mb-4">
            กีฬาออนไลน์
          </h2>
          <p className="text-gray-300 max-w-2xl mx-auto">
            แทงบอลสดได้ตลอด 24 ชั่วโมง 
          </p> */}
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {sportsEvents.map((index: any) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gradient-to-br from-red-900/30 to-black rounded-xl overflow-hidden group"
            >
              {/* <div className="relative">
                <img 
                  src={event.image} 
                  alt={event.title} 
                  className="w-full h-48 object-cover transform group-hover:scale-110 transition-transform duration-500"
                />
                <div className="absolute top-2 right-2 bg-red-600 text-white px-2 py-1 rounded text-sm font-bold">
                  Live
                </div>
              </div>
              
              <div className="p-4">
                <div className="flex items-center gap-2 mb-2">
                  <Trophy className="text-red-500" size={16} />
                  <h3 className="text-xl font-bold text-white">{event.title}</h3>
                </div>
                <p className="text-gray-400 text-sm mb-2">{event.match}</p>
                <p className="text-yellow-500 text-sm mb-4">เวลาแข่ง: {event.time}</p>
                
                <div className="grid grid-cols-3 gap-2 mb-4 bg-black/30 p-3 rounded-lg">
                  <div className="text-center">
                    <p className="text-gray-400 text-sm">เจ้าบ้าน</p>
                    <p className="text-white font-bold">{event.odds.home}</p>
                  </div>
                  <div className="text-center border-x border-gray-700">
                    <p className="text-gray-400 text-sm">เสมอ</p>
                    <p className="text-white font-bold">{event.odds.draw}</p>
                  </div>
                  <div className="text-center">
                    <p className="text-gray-400 text-sm">ทีมเยือน</p>
                    <p className="text-white font-bold">{event.odds.away}</p>
                  </div>
                </div>

                <div className="flex flex-wrap gap-2 mb-4">
                  {event.features.map((feature, idx) => (
                    <span key={idx} className="text-xs bg-red-900/30 text-red-400 px-2 py-1 rounded">
                      {feature}
                    </span>
                  ))}
                </div>

                <div className="grid grid-cols-2 gap-2">
                  <button className="bg-red-600 hover:bg-red-700 text-white py-2 rounded-lg transition-colors flex items-center justify-center gap-1">
                    <Play size={14} />
                    แทงบอล
                  </button>
                  <button className="border border-red-600 text-red-500 hover:bg-red-600 hover:text-white py-2 rounded-lg transition-colors flex items-center justify-center gap-1">
                    <Star size={14} />
                    วิเคราะห์บอล
                  </button>
                </div>
              </div> */}
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};