import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface CoinProps {
  x: number;
  delay: number;
  size: number;
}

const Coin: React.FC<CoinProps> = ({ x, delay, size }) => (
  <motion.div
    initial={{ y: -20, x, opacity: 0, scale: 0 }}
    animate={{
      y: ['0vh', '100vh'],
      opacity: [0, 1, 1, 0],
      rotate: [0, 720],
      scale: [0, 1, 1, 0],
    }}
    transition={{
      duration: 8,
      delay,
      ease: "linear",
      times: [0, 0.2, 0.8, 1]
    }}
    className="absolute"
  >
    <motion.div 
      animate={{
        boxShadow: [
          '0 0 10px rgba(239, 68, 68, 0.5)',
          '0 0 20px rgba(239, 68, 68, 0.8)',
          '0 0 10px rgba(239, 68, 68, 0.5)'
        ]
      }}
      transition={{
        duration: 1.5,
        repeat: Infinity,
        ease: "easeInOut"
      }}
      className="rounded-full bg-gradient-to-br from-yellow-400 to-yellow-600 flex items-center justify-center text-yellow-900 font-bold border-2 border-red-500"
      style={{ 
        width: `${size}px`, 
        height: `${size}px`,
        fontSize: `${size * 0.4}px`,
        animation: 'coinGlow 1.5s ease-in-out infinite'
      }}
    >
      ฿
    </motion.div>
  </motion.div>
);

interface CoinRainProps {
  isVisible?: boolean;
}

export const CoinRain: React.FC<CoinRainProps> = ({ isVisible = true }) => {
  const [coins, setCoins] = useState<{ id: string; x: number; delay: number; size: number }[]>([]);

  useEffect(() => {
    if (!isVisible) return;

    const interval = setInterval(() => {
      setCoins(prev => {
        const currentTime = Date.now();
        const filtered = prev.filter(coin => {
          const [timestamp] = coin.id.split('-');
          return currentTime - parseInt(timestamp) < 8000;
        });
        
        const newCoins = Array.from({ length: 2 }, (_, index) => ({
          id: `${Date.now()}-${Math.random()}-${index}`,
          x: Math.random() * window.innerWidth,
          delay: Math.random() * 2,
          size: Math.random() * 10 + 20
        }));

        return [...filtered, ...newCoins];
      });
    }, 2000);

    return () => clearInterval(interval);
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 pointer-events-none z-50">
      <style>
        {`
          @keyframes coinGlow {
            0% { box-shadow: 0 0 10px rgba(239, 68, 68, 0.5); }
            50% { box-shadow: 0 0 20px rgba(239, 68, 68, 0.8), 0 0 30px rgba(239, 68, 68, 0.4); }
            100% { box-shadow: 0 0 10px rgba(239, 68, 68, 0.5); }
          }
        `}
      </style>
      <AnimatePresence>
        {coins.map(coin => (
          <Coin 
            key={coin.id} 
            x={coin.x} 
            delay={coin.delay}
            size={coin.size}
          />
        ))}
      </AnimatePresence>
    </div>
  );
};