export const TermsPage = () => {
  return (
    <div className="container mx-auto px-4 pt-4 pb-8">
      <h1 className="text-3xl font-bold text-white mb-8">กติกาและเงื่อนไข</h1>
      
      <div className="bg-gray-900 rounded-lg p-6 mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">ข้อกำหนดทั่วไป</h2>
        
        <div className="space-y-4 text-gray-300">
          <p>การใช้บริการของ NIGOAL555 หมายความว่าคุณได้อ่านและยอมรับข้อกำหนดและเงื่อนไขทั้งหมดแล้ว</p>
          
          <div className="bg-gray-800 p-4 rounded-lg">
            <h3 className="text-xl font-medium text-white mb-2">1. คุณสมบัติผู้ใช้บริการ</h3>
            <ul className="list-disc list-inside space-y-1">
              <li>ผู้ใช้บริการต้องมีอายุไม่ต่ำกว่า 18 ปีบริบูรณ์</li>
              <li>ผู้ใช้บริการต้องมีบัญชีธนาคารในนามของตนเอง</li>
              <li>ผู้ใช้บริการสามารถมีบัญชีผู้เล่นได้เพียงบัญชีเดียวเท่านั้น</li>
            </ul>
          </div>
          
          <div className="bg-gray-800 p-4 rounded-lg">
            <h3 className="text-xl font-medium text-white mb-2">2. การฝากและถอนเงิน</h3>
            <ul className="list-disc list-inside space-y-1">
              <li>การฝากเงินขั้นต่ำคือ 100 บาท</li>
              <li>การถอนเงินขั้นต่ำคือ 300 บาท</li>
              <li>ชื่อบัญชีธนาคารที่ใช้ในการถอนเงินต้องตรงกับชื่อที่ใช้ในการสมัครสมาชิก</li>
              <li>บริษัทขอสงวนสิทธิ์ในการตรวจสอบข้อมูลก่อนการอนุมัติการถอนเงิน</li>
            </ul>
          </div>
          
          <div className="bg-gray-800 p-4 rounded-lg">
            <h3 className="text-xl font-medium text-white mb-2">3. โปรโมชั่นและโบนัส</h3>
            <ul className="list-disc list-inside space-y-1">
              <li>ผู้เล่นต้องทำตามเงื่อนไขการทำเทิร์นโอเวอร์ของแต่ละโปรโมชั่นให้ครบถ้วนก่อนการถอนเงิน</li>
              <li>บริษัทขอสงวนสิทธิ์ในการยกเลิกโบนัสและเงินรางวัลที่ได้จากโบนัสหากพบการละเมิดเงื่อนไข</li>
              <li>บริษัทขอสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไขของโปรโมชั่นโดยไม่ต้องแจ้งให้ทราบล่วงหน้า</li>
            </ul>
          </div>
          
          <div className="bg-gray-800 p-4 rounded-lg">
            <h3 className="text-xl font-medium text-white mb-2">4. ข้อกำหนดการเล่นเกม</h3>
            <ul className="list-disc list-inside space-y-1">
              <li>บริษัทขอสงวนสิทธิ์ในการระงับบัญชีผู้เล่นหากพบการใช้ซอฟต์แวร์ช่วยเล่นหรือการโกง</li>
              <li>ในกรณีที่เกิดข้อผิดพลาดของระบบ บริษัทจะพิจารณาคืนเงินเฉพาะการเดิมพันที่ได้รับผลกระทบโดยตรงเท่านั้น</li>
              <li>การตัดสินของบริษัทถือเป็นที่สิ้นสุด</li>
            </ul>
          </div>
        </div>
      </div>
      
      <div className="bg-gray-900 rounded-lg p-6">
        <h2 className="text-2xl font-semibold text-white mb-4">การเปลี่ยนแปลงข้อกำหนด</h2>
        <p className="text-gray-300">NIGOAL555 ขอสงวนสิทธิ์ในการเปลี่ยนแปลงข้อกำหนดและเงื่อนไขเหล่านี้ได้ตลอดเวลาโดยไม่ต้องแจ้งให้ทราบล่วงหน้า การใช้บริการต่อหลังจากมีการเปลี่ยนแปลงถือว่าคุณได้ยอมรับข้อกำหนดและเงื่อนไขที่เปลี่ยนแปลงแล้ว</p>
      </div>
    </div>
  );
}; 