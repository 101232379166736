import { motion } from 'framer-motion';
import { ChevronUp, ChevronDown } from 'lucide-react';

export const ScrollButtons = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  };

  return (
    <div className="fixed right-4 bottom-4 z-50 flex flex-col gap-2">
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={scrollToTop}
        className="bg-red-600 hover:bg-red-700 text-white p-3 rounded-full shadow-lg"
      >
        <ChevronUp size={24} />
      </motion.button>
      
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={scrollToBottom}
        className="bg-red-600 hover:bg-red-700 text-white p-3 rounded-full shadow-lg"
      >
        <ChevronDown size={24} />
      </motion.button>
    </div>
  );
}; 