export const RegistrationPage = () => {
  return (
    <div className="container mx-auto px-4 pt-4 pb-8">
      <h1 className="text-3xl font-bold text-white mb-8">วิธีสมัครสมาชิก</h1>
      
      <div className="bg-gray-900 rounded-lg p-6 mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">ขั้นตอนการสมัครสมาชิก</h2>
        
        <div className="space-y-6">
          <div className="bg-gray-800 p-4 rounded-lg">
            <h3 className="text-xl font-medium text-red-500 mb-2">ขั้นตอนที่ 1: เข้าสู่หน้าเว็บไซต์</h3>
            <p className="text-gray-300">เข้าสู่เว็บไซต์ NIGOAL555 และคลิกที่ปุ่ม "สมัครสมาชิก" ที่มุมขวาบนของหน้าจอ</p>
          </div>
          
          <div className="bg-gray-800 p-4 rounded-lg">
            <h3 className="text-xl font-medium text-red-500 mb-2">ขั้นตอนที่ 2: กรอกข้อมูลส่วนตัว</h3>
            <p className="text-gray-300">กรอกข้อมูลส่วนตัวให้ครบถ้วน เช่น ชื่อ-นามสกุล, เบอร์โทรศัพท์, อีเมล และบัญชีธนาคาร</p>
          </div>
          
          <div className="bg-gray-800 p-4 rounded-lg">
            <h3 className="text-xl font-medium text-red-500 mb-2">ขั้นตอนที่ 3: ยืนยันการสมัคร</h3>
            <p className="text-gray-300">ตรวจสอบความถูกต้องของข้อมูลและกดปุ่ม "ยืนยันการสมัคร"</p>
          </div>
          
          <div className="bg-gray-800 p-4 rounded-lg">
            <h3 className="text-xl font-medium text-red-500 mb-2">ขั้นตอนที่ 4: เริ่มเล่นเกมส์</h3>
            <p className="text-gray-300">หลังจากสมัครสำเร็จ คุณสามารถล็อกอินและเริ่มเล่นเกมส์ได้ทันที</p>
          </div>
        </div>
      </div>
      
      <div className="bg-gray-900 rounded-lg p-6">
        <h2 className="text-2xl font-semibold text-white mb-4">ข้อควรระวัง</h2>
        <ul className="list-disc list-inside text-gray-300 space-y-2">
          <li>ต้องมีอายุ 18 ปีขึ้นไปเท่านั้น</li>
          <li>ข้อมูลส่วนตัวและบัญชีธนาคารต้องเป็นของผู้สมัครเท่านั้น</li>
          <li>กรอกข้อมูลให้ถูกต้อง เพื่อป้องกันปัญหาในการถอนเงิน</li>
        </ul>
      </div>
    </div>
  );
}; 