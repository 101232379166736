import { motion } from 'framer-motion';
import {Search } from 'lucide-react';

export const ArticlesHero = () => {
  return (
    <div className="bg-gradient-to-b from-black to-red-900/20 py-16">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center"
        >
          <h1 className="text-4xl font-bold text-white mb-4">บทความทั้งหมด nigoal</h1>
          <p className="text-gray-300 max-w-2xl mx-auto">
            รวมบทความเกี่ยวกับเกมพนันออนไลน์ อัพเดทข่าวสาร และสาระความรู้ต่างๆ
          </p>
          <div className="max-w-xl mx-auto">
            <div className="relative">
              <input
                type="text"
                placeholder="ค้นหาบทความ..."
                className="w-full bg-white/10 border border-red-500/30 rounded-full py-3 px-6 pl-12 text-white focus:outline-none focus:border-red-500"
              />
              <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};