import { Link } from 'react-router-dom';

const links = [
  {
    title: "เกมส์ทั้งหมด",
    items: [
      { label: "สล็อตออนไลน์", path: "/slots" },
      { label: "คาสิโนสด", path: "/casino" },
      { label: "กีฬา", path: "/sports" },
      { label: "หวย", path: "/lottery" },
      { label: "ไก่ชน", path: "/cockfight" }
    ]
  },
  {
    title: "โปรโมชั่น",
    items: [
      { label: "โบนัสต้อนรับ", path: "/promotions/welcome-bonus" },
      { label: "คืนยอดเสีย", path: "/promotions/cashback" },
      { label: "แนะนำเพื่อน", path: "/promotions/refer-friend" },
      { label: "เครดิตฟรี", path: "/promotions/free-credit" }
    ]
  },
  {
    title: "ช่วยเหลือ",
    items: [
      { label: "วิธีสมัครสมาชิก", path: "/help/registration" },
      { label: "วิธีฝาก-ถอน", path: "/help/deposit-withdraw" },
      { label: "กติกา", path: "/help/terms" },
      { label: "ติดต่อเรา", path: "/help/contact" }
    ]
  }
];

export const FooterLinks = () => {
  return (
    <div className="grid md:grid-cols-3 gap-8">
      {links.map((section, index) => (
        <div key={index}>
          <h3 className="text-lg font-bold text-white mb-4">{section.title}</h3>
          <ul className="space-y-2">
            {section.items.map((item, idx) => (
              <li key={idx}>
                <Link 
                  to={item.path}
                  className="text-gray-400 hover:text-red-500 transition-colors"
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};