
import { motion } from 'framer-motion';
import { Gamepad2, Gift, BarChart } from 'lucide-react';

export const SlotHero = () => {
  return (
    <div className="relative bg-gradient-to-b from-black to-red-900/20 py-8 md:py-16">
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center max-w-4xl mx-auto"
        >
          <h1 className="mobile-heading  text-gray-300 mb-8 md:mb-8">
            สล็อต 
            <span className="text-red-500">อันดับ 1</span>
          </h1>
          <p className="mobile-text text-gray-300 mb-6 md:mb-8">
            ทดลองเล่นสล็อตฟรีได้ทุกค่าย พร้อมเครดิตฟรี 100,000 บาท ไม่ต้องฝาก ไม่ต้องสมัคร เล่นได้ไม่จำกัด
          </p>
          
          <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-6">
            <div className="mobile-card bg-gradient-to-br from-red-900/30 to-black">
              <Gamepad2 className="text-red-500 w-8 h-8 md:w-12 md:h-12 mx-auto mb-3 md:mb-4" />
              <h3 className="text-lg md:text-xl font-bold text-white mb-1 md:mb-2">เกมสล็อตครบครัน</h3>
              <p className="mobile-text text-gray-300">รวมเกมสล็อตจากค่ายดังระดับโลกกว่า 1,000 เกม</p>
            </div>
            
            <div className="mobile-card bg-gradient-to-br from-red-900/30 to-black">
              <Gift className="text-red-500 w-8 h-8 md:w-12 md:h-12 mx-auto mb-3 md:mb-4" />
              <h3 className="text-lg md:text-xl font-bold text-white mb-1 md:mb-2">เครดิตฟรี 100,000</h3>
              <p className="mobile-text text-gray-300">ทดลองเล่นฟรีด้วยเครดิตสูงสุด 100,000 บาท</p>
            </div>
            
            <div className="mobile-card bg-gradient-to-br from-red-900/30 to-black">
              <BarChart className="text-red-500 w-8 h-8 md:w-12 md:h-12 mx-auto mb-3 md:mb-4" />
              <h3 className="text-lg md:text-xl font-bold text-white mb-1 md:mb-2">อัตราการจ่ายสูง</h3>
              <p className="mobile-text text-gray-300">RTP สูงสุดถึง 98.5% พร้อมโบนัสแจ็คพอต</p>
            </div>

            <div className="mobile-card bg-gradient-to-br from-red-900/30 to-black">
              <Gift className="text-red-500 w-8 h-8 md:w-12 md:h-12 mx-auto mb-3 md:mb-4" />
              <h3 className="text-lg md:text-xl font-bold text-white mb-1 md:mb-2">ไม่ต้องสมัคร</h3>
              <p className="mobile-text text-gray-300">เล่นได้ทันที ไม่ต้องสมัครสมาชิก</p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};