import { motion } from 'framer-motion';
import { Dices, Users, Shield, Trophy } from 'lucide-react';
import { BrandLink } from '../common/BrandLink';

export const CasinoHero = () => {
  return (
    <div className="relative bg-gradient-to-b from-black to-red-900/20 py-8 md:py-16">
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center max-w-4xl mx-auto"
        >
          <h1 className="mobile-heading  text-gray-300 mb-8 md:mb-8">
            <BrandLink name="NIGOAL" /> คาสิโนออนไลน์ 
            <span className="text-red-500">เว็บตรง อันดับ 1</span>
          </h1>
          <p className="mobile-text text-gray-300 mb-6 md:mb-8">
            บาคาร่าออนไลน์ รูเล็ต ไฮโล และเกมไพ่อื่นๆ อีกมากมาย พร้อมดีลเลอร์สาวสวย ถ่ายทอดสดตลอด 24 ชั่วโมง
          </p>
          
          <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-6">
            <div className="mobile-card bg-gradient-to-br from-red-900/30 to-black">
              <Dices className="text-red-500 w-8 h-8 md:w-12 md:h-12 mx-auto mb-3 md:mb-4" />
              <h3 className="text-lg md:text-xl font-bold text-white mb-1 md:mb-2"><BrandLink name="nigoal" /> เกมคาสิโนครบครัน</h3>
              <p className="mobile-text text-gray-300">รวมเกมคาสิโนยอดนิยมกว่า 100 เกม</p>
            </div>
            
            <div className="mobile-card bg-gradient-to-br from-red-900/30 to-black">
              <Users className="text-red-500 w-8 h-8 md:w-12 md:h-12 mx-auto mb-3 md:mb-4" />
              <h3 className="text-lg md:text-xl font-bold text-white mb-1 md:mb-2"><BrandLink name="nigoal" /> ดีลเลอร์สาวสวย</h3>
              <p className="mobile-text text-gray-300">ดีลเลอร์มืออาชีพ สวยระดับนางแบบ</p>
            </div>
            
            <div className="mobile-card bg-gradient-to-br from-red-900/30 to-black">
              <Shield className="text-red-500 w-8 h-8 md:w-12 md:h-12 mx-auto mb-3 md:mb-4" />
              <h3 className="text-lg md:text-xl font-bold text-white mb-1 md:mb-2"><BrandLink name="nigoal" /> มาตรฐานระดับโลก</h3>
              <p className="mobile-text text-gray-300">ระบบเสถียร ปลอดภัย 100%</p>
            </div>

            <div className="mobile-card bg-gradient-to-br from-red-900/30 to-black">
              <Trophy className="text-red-500 w-8 h-8 md:w-12 md:h-12 mx-auto mb-3 md:mb-4" />
              <h3 className="text-lg md:text-xl font-bold text-white mb-1 md:mb-2"><BrandLink name="nigoal" /> โบนัสสูงสุด</h3>
              <p className="mobile-text text-gray-300">รับโบนัสสูงสุด 100% ทุกยอดฝาก</p>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-4 justify-center mt-8">
            <a 
              href="https://app.nigoal555s.com/Register/reg/d0tWemxYRFBtRjZxaUxhVnR0bVd5Zz09" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="mobile-button bg-red-600 hover:bg-red-700 text-white transition-colors inline-block"
            >
              <BrandLink name="nigoal" /> สมัครสมาชิก รับโบนัส 100%
            </a>
            <button className="mobile-button border-2 border-red-600 text-red-500 hover:bg-red-600 hover:text-white transition-colors">
              <BrandLink name="nigoal" /> ทดลองเล่นคาสิโน
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  );
};