
import { motion } from 'framer-motion';

export const SportsSEOContent = () => {
  return (
    <div className="py-16 bg-gradient-to-b from-black to-red-900/20">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="prose prose-invert max-w-none"
        >
          <h2 className="text-3xl font-bold text-white mb-6">แทงบอลออนไลน์ เว็บตรง ราคาน้ำดีที่สุด</h2>
          
          <div className="text-gray-300 space-y-4">
            <p>
              เว็บแทงบอลออนไลน์ของเรา เป็นเว็บพนันบอลที่ได้รับความนิยมสูงสุดในประเทศไทย 
              ด้วยราคาน้ำดีที่สุด ค่าน้ำ 4 ตังค์ พร้อมระบบที่ทันสมัย และการบริการระดับพรีเมียม 
              ครอบคลุมการแข่งขันฟุตบอลทุกลีกทั่วโลก
            </p>

            <h3 className="text-2xl font-bold text-white mt-8 mb-4">ประเภทการแทงบอล</h3>
            <ul className="list-disc pl-6 space-y-2">
              <li>แทงบอลเต็ง - แทงทีมเดียว อัตราต่อรองดี</li>
              <li>แทงบอลสเต็ป - รวมหลายคู่ ลุ้นรางวัลใหญ่</li>
              <li>แทงบอลสด - แทงระหว่างการแข่งขัน ราคาไหลตลอดเวลา</li>
              <li>แทงสูง-ต่ำ - ทายผลรวมประตูของทั้งสองทีม</li>
              <li>แทงครึ่งแรก-ครึ่งหลัง - เลือกแทงเฉพาะครึ่งที่ต้องการ</li>
            </ul>

            <h3 className="text-2xl font-bold text-white mt-8 mb-4">ทำไมต้องเลือกแทงบอลกับเรา</h3>
            <ul className="list-disc pl-6 space-y-2">
              <li>ราคาน้ำดีที่สุด ค่าน้ำ 4 ตังค์</li>
              <li>ระบบฝาก-ถอนอัตโนมัติ รวดเร็วภายใน 30 วินาที</li>
              <li>มีระบบวิเคราะห์บอลและสถิติแบบเรียลไทม์</li>
              <li>ดูบอลสดฟรีทุกคู่</li>
              <li>โบนัสและโปรโมชั่นมากมาย</li>
            </ul>

            <h3 className="text-2xl font-bold text-white mt-8 mb-4">ลีกฟุตบอลยอดนิยม</h3>
            <ul className="list-disc pl-6 space-y-2">
              <li>พรีเมียร์ลีก อังกฤษ</li>
              <li>ลาลีกา สเปน</li>
              <li>บุนเดสลีกา เยอรมัน</li>
              <li>กัลโช่ เซเรีย อา อิตาลี</li>
              <li>ลีกเอิง ฝรั่งเศส</li>
              <li>ไทยลีก</li>
            </ul>

            <h3 className="text-2xl font-bold text-white mt-8 mb-4">เคล็ดลับการแทงบอลให้ได้เงิน</h3>
            <ol className="list-decimal pl-6 space-y-2">
              <li>วิเคราะห์สถิติและข้อมูลก่อนแทง</li>
              <li>ดูฟอร์มการเล่นของทั้งสองทีม</li>
              <li>ตรวจสอบความพร้อมของนักเตะ</li>
              <li>ศึกษาราคาบอลและการไหลของราคา</li>
              <li>บริหารเงินทุนอย่างมีระบบ</li>
            </ol>

            <h3 className="text-2xl font-bold text-white mt-8 mb-4">โปรโมชั่นสำหรับนักแทงบอล</h3>
            <ul className="list-disc pl-6 space-y-2">
              <li>โบนัสแรกเข้า 100% สำหรับสมาชิกใหม่</li>
              <li>คืนยอดเสีย 0.5% ทุกสัปดาห์</li>
              <li>โบนัสแทงบอลสเต็ป สูงสุด 100%</li>
              <li>แนะนำเพื่อน รับค่าคอมมิชชั่น</li>
              <li>โปรโมชั่นพิเศษช่วงฟุตบอลโลกและยูโร</li>
            </ul>
          </div>
        </motion.div>
      </div>
    </div>
  );
};