import { motion } from 'framer-motion';

const promotions = [
  {
    title: "nigoal โปรโมชั่นสมาชิกใหม่",
    image: "/images/promotions/promotion1.webp",
    altText: "NIGOAL โปรโมชั่นสมาชิกใหม่ - รับโบนัสต้อนรับ 100% สำหรับการฝากเงินครั้งแรก สูงสุด 5,000 บาท"
  },
  {
    title: "nigoal โบนัสเพื่อนชวน",
    image: "/images/promotions/promotion2.webp",
    altText: "NIGOAL โบนัสเพื่อนชวนเพื่อน - รับโบนัส 10% จากยอดฝากของเพื่อนที่คุณชวนมา ไม่จำกัดจำนวน"
  },
  {
    title: "nigoal คืนยอดเสีย 5%",
    image: "/images/promotions/promotion3.webp",
    altText: "NIGOAL คืนยอดเสีย 5% - รับเงินคืน 5% จากยอดเสียสะสมรายสัปดาห์ โอนเข้ากระเป๋าเงินอัตโนมัติทุกวันจันทร์"
  },
  {
    title: "nigoal หวยออนไลน์",
    image: "/images/promotions/promotion4.webp",
    altText: "NIGOAL หวยออนไลน์ - แทงหวยออนไลน์จ่ายสูงสุด 1000 บาท ครบทุกประเภทหวย ทั้งหวยรัฐบาลและหวยต่างประเทศ"
  },
  {
    title: "nigoal คืนยอดเล่นทุกวัน",
    image: "/images/promotions/promotion5.webp",
    altText: "NIGOAL คืนยอดเล่นทุกวัน - รับเครดิตคืน 0.5% จากยอดเล่นรวมทุกวัน ไม่มีเงื่อนไขการทำเทิร์น"
  },
  {
    title: "nigoal สปินลุ้นรางวัล",
    image: "/images/promotions/555.webp",
    altText: "NIGOAL แทงบอลออนไลน์ - ราคาน้ำดีที่สุด อัตราต่อรองครบทุกลีก พร้อมระบบดูบอลสดฟรี"
  }
];

export const Promotions: React.FC = () => {
  return (
    <div className="bg-black py-16 relative overflow-hidden">
      <div className="container mx-auto px-4 relative z-10">
        <h2 className="text-3xl font-bold text-red-600 mb-8 text-center">โปรโมชั่นพิเศษ</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {promotions.map((promo, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="group relative overflow-hidden rounded-xl shadow-lg hover:shadow-red-500/20"
            >
              <img
                src={promo.image}
                alt={promo.altText || promo.title}
                className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                onError={(e) => {
                  console.error(`Error loading image: ${promo.image}`);
                  e.currentTarget.src = '/images/promotions/fallback.webp';
                }}
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black via-black/60 to-transparent">
                <div className="absolute bottom-0 p-6">
                  <h3 className="text-xl font-bold text-white mb-2">{promo.title}</h3>
                  <a 
                    href="https://app.nigoal555s.com/Register/reg/d0tWemxYRFBtRjZxaUxhVnR0bVd5Zz09"
                    className="inline-block bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-full text-sm transition-colors relative z-20"
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    รับโปรโมชั่น
                  </a>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};