export const WelcomeBonusPage = () => {
  return (
    <div className="container mx-auto px-4 pt-24 pb-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-white mb-2">โบนัสต้อนรับสมาชิกใหม่</h1>
        <p className="text-gray-400">รับโบนัสสูงสุด 100% สำหรับการฝากครั้งแรก</p>
      </div>
      
      <div className="bg-gradient-to-r from-red-900 to-red-800 rounded-lg overflow-hidden mb-12">
        <div className="grid md:grid-cols-2">
          <div className="p-8">
            <h2 className="text-2xl font-bold text-white mb-4">รับโบนัส 100% สูงสุด 5,000 บาท</h2>
            <ul className="space-y-3 text-gray-200 mb-6">
              <li className="flex items-start">
                <svg className="w-5 h-5 text-red-400 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
                <span>สำหรับสมาชิกใหม่เท่านั้น</span>
              </li>
              <li className="flex items-start">
                <svg className="w-5 h-5 text-red-400 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
                <span>ฝากขั้นต่ำ 300 บาท</span>
              </li>
              <li className="flex items-start">
                <svg className="w-5 h-5 text-red-400 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
                <span>เทิร์นโอเวอร์ 10 เท่า</span>
              </li>
              <li className="flex items-start">
                <svg className="w-5 h-5 text-red-400 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
                <span>สามารถเล่นได้ทุกเกม</span>
              </li>
            </ul>
            
            <a 
              href="https://app.nigoal555s.com/Register/reg/d0tWemxYRFBtRjZxaUxhVnR0bVd5Zz09"
              className="inline-block bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-8 rounded-lg transition-colors"
              target="_blank" 
              rel="noopener noreferrer"
            >
              สมัครสมาชิกเลย
            </a>
          </div>
          <div className="bg-red-800 flex items-center justify-center p-8">
            <div className="text-center">
              <div className="text-9xl font-extrabold text-white mb-4">100%</div>
              <div className="text-xl font-medium text-red-300">โบนัสสูงสุด 5,000 บาท</div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="bg-gray-900 rounded-lg p-6 mb-12">
        <h2 className="text-2xl font-semibold text-white mb-4">วิธีรับโบนัส</h2>
        
        <div className="grid md:grid-cols-3 gap-6">
          <div className="bg-gray-800 p-5 rounded-lg text-center">
            <div className="bg-red-600 w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-4">
              <span className="text-xl font-bold text-white">1</span>
            </div>
            <h3 className="text-xl font-medium text-white mb-2">สมัครสมาชิก</h3>
            <p className="text-gray-400">สมัครสมาชิกใหม่กับ NIGOAL555</p>
          </div>
          
          <div className="bg-gray-800 p-5 rounded-lg text-center">
            <div className="bg-red-600 w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-4">
              <span className="text-xl font-bold text-white">2</span>
            </div>
            <h3 className="text-xl font-medium text-white mb-2">ฝากเงิน</h3>
            <p className="text-gray-400">ทำการฝากเงินขั้นต่ำ 300 บาท</p>
          </div>
          
          <div className="bg-gray-800 p-5 rounded-lg text-center">
            <div className="bg-red-600 w-12 h-12 rounded-full flex items-center justify-center mx-auto mb-4">
              <span className="text-xl font-bold text-white">3</span>
            </div>
            <h3 className="text-xl font-medium text-white mb-2">รับโบนัส</h3>
            <p className="text-gray-400">รับโบนัสตามเงื่อนไขการฝากเงิน</p>
          </div>
        </div>
      </div>
    </div>
  );
}; 