
import { CreditCard, Wallet, Building2, Smartphone } from 'lucide-react';

const payments = [
  { icon: Building2, label: "ธนาคารชั้นนำ" },
  { icon: CreditCard, label: "บัตรเครดิต/เดบิต" },
  { icon: Wallet, label: "True Money" },
  { icon: Smartphone, label: "Mobile Banking" }
];

export const FooterPayments = () => {
  return (
    <div>
      <h3 className="text-lg font-bold text-white mb-4">วิธีการชำระเงิน</h3>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {payments.map((payment, index) => (
          <div 
            key={index}
            className="flex items-center gap-2 text-gray-400"
          >
            <payment.icon size={20} />
            <span className="text-sm">{payment.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};