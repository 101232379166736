import type { Game } from '../types/game';

// All PG Soft games
export const demoGames: Game[] = [
  {
    id: "1492288",
    name: "Pinata Wins",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-66.png",
    url: "https://m.pgsoft-games.com/1492288/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1508783",
    name: "Wild Ape",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-67.png",
    url: "https://m.pgsoft-games.com/1508783/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "89",
    name: "Lucky Neko",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-3.png",
    url: "https://m.pgsoft-games.com/89/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "87",
    name: "Treasures of Aztec",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-4.png",
    url: "https://m.pgsoft-games.com/87/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "1682240",
    name: "Cash Mania",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-97.png",
    url: "https://m.pgsoft-games.com/1682240/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1695365",
    name: "Fortune Dragon",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-120.png",
    url: "https://m.pgsoft-games.com/1695365/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1671262",
    name: "Gemstones Gold",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-118.png",
    url: "https://m.pgsoft-games.com/1671262/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1568554",
    name: "Wild Heist Cashout",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-116.png",
    url: "https://m.pgsoft-games.com/1568554/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1555350",
    name: "Forge of Wealth",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-117.png",
    url: "https://m.pgsoft-games.com/1555350/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1529867",
    name: "Ninja Raccoon Frenzy",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-114.png",
    url: "https://m.pgsoft-games.com/1529867/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1448762",
    name: "Songkran Splash",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-107.png",
    url: "https://m.pgsoft-games.com/1448762/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1489936",
    name: "Ultimate Striker",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-115.png",
    url: "https://m.pgsoft-games.com/1489936/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1594259",
    name: "Safari Wilds",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-113.png",
    url: "https://m.pgsoft-games.com/1594259/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1543462",
    name: "Fortune Rabbit",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-103.png",
    url: "https://m.pgsoft-games.com/1543462/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1420892",
    name: "Rave Party Fever",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-104.png",
    url: "https://m.pgsoft-games.com/1420892/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1473388",
    name: "Cruise Royale",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-112.png",
    url: "https://m.pgsoft-games.com/1473388/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1513328",
    name: "Super Golf Drive",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-109.png",
    url: "https://m.pgsoft-games.com/1513328/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1381200",
    name: "Hawaiian Tiki",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-105.png",
    url: "https://m.pgsoft-games.com/1381200/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1402846",
    name: "Midas Fortune",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-102.png",
    url: "https://m.pgsoft-games.com/1402846/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1418544",
    name: "Bakery Bonanza",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-106.png",
    url: "https://m.pgsoft-games.com/1418544/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1397455",
    name: "Fruity Candy",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-111.png",
    url: "https://m.pgsoft-games.com/1397455/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1432733",
    name: "Mystical Spirits",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-108.png",
    url: "https://m.pgsoft-games.com/1432733/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1601012",
    name: "Lucky Clover Lady",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-110.png",
    url: "https://m.pgsoft-games.com/1601012/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1",
    name: "Honey Trap of Diao Chan",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-119.png",
    url: "https://m.pgsoft-games.com/1/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1372643",
    name: "Diner Delights",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-101.png",
    url: "https://m.pgsoft-games.com/1372643/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1368367",
    name: "Alchemy Gold",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-100.png",
    url: "https://m.pgsoft-games.com/1368367/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "37",
    name: "Santa's Gift Rush",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-121.png",
    url: "https://m.pgsoft-games.com/37/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1340277",
    name: "Asgardian Rising",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-99.png",
    url: "https://m.pgsoft-games.com/1340277/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "1338274",
    name: "Totem Wonders",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-98.png",
    url: "https://m.pgsoft-games.com/1338274/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "114",
    name: "Emoji Riches",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-96-1.png",
    url: "https://m.pgsoft-games.com/114/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "105",
    name: "Heist Stakes",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-94.png",
    url: "https://m.pgsoft-games.com/105/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "113",
    name: "Raider Jane's Crypt of Fortune",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-95.png",
    url: "https://m.pgsoft-games.com/113/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "18",
    name: "Hood vs Wolf",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-75.png",
    url: "https://m.pgsoft-games.com/18/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "83",
    name: "Wild Fireworks",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-92.png",
    url: "https://m.pgsoft-games.com/83/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "67",
    name: "Shaolin Soccer",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-90.png",
    url: "https://m.pgsoft-games.com/67/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "20",
    name: "Reel Love",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-89.png",
    url: "https://m.pgsoft-games.com/20/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "63",
    name: "Dragon Tiger Luck",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-88.png",
    url: "https://m.pgsoft-games.com/63/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "64",
    name: "Muay Thai Champion",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-87.png",
    url: "https://m.pgsoft-games.com/64/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "38",
    name: "Gem Saviour Sword",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-86.png",
    url: "https://m.pgsoft-games.com/38/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "61",
    name: "Flirting Scholar",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-85.png",
    url: "https://m.pgsoft-games.com/61/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "59",
    name: "Ninja vs Samurai",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-84.png",
    url: "https://m.pgsoft-games.com/59/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "53",
    name: "The Great Icescape",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-83.png",
    url: "https://m.pgsoft-games.com/53/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "50",
    name: "Journey To The Wealth",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-82.png",
    url: "https://m.pgsoft-games.com/50/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "44",
    name: "Emperor's Favour",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-81.png",
    url: "https://m.pgsoft-games.com/44/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "41",
    name: "Symbols of Egypt",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-80.png",
    url: "https://m.pgsoft-games.com/41/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "31",
    name: "Baccarat Deluxe",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-79.png",
    url: "https://m.pgsoft-games.com/31/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "34",
    name: "Legend of Hou Yi",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-78.png",
    url: "https://m.pgsoft-games.com/34/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "35",
    name: "Mr. Hallow-Win",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-77.png",
    url: "https://m.pgsoft-games.com/35/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "28",
    name: "Hotpot",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-76.png",
    url: "https://m.pgsoft-games.com/28/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "65",
    name: "Mahjong Ways 2",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-1.png",
    url: "https://m.pgsoft-games.com/65/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "74",
    name: "Mahjong Ways 2",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-2.png",
    url: "https://m.pgsoft-games.com/74/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "104",
    name: "Wild Bandito",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-5.png",
    url: "https://m.pgsoft-games.com/104/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "135",
    name: "Wild Bounty Showdown",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-6.png",
    url: "https://m.pgsoft-games.com/135/index.html?l=id&ot=82b8b0f88e17ae53611e6dd7f167bc38&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "40",
    name: "Jungle Delight",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-7.png",
    url: "https://m.pgsoft-games.com/40/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "39",
    name: "Piggy Gold",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-8.png",
    url: "https://m.pgsoft-games.com/39/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "48",
    name: "Double Fortune",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-9.png",
    url: "https://m.pgsoft-games.com/48/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "108",
    name: "Buffalo Win",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-10.png",
    url: "https://m.pgsoft-games.com/108/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "127",
    name: "Speed Winner",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-11.png",
    url: "https://m.pgsoft-games.com/127/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "106",
    name: "Ways of the Qilin",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-12.png",
    url: "https://m.pgsoft-games.com/106/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "79",
    name: "Dreams of Macau",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-13.png",
    url: "https://m.pgsoft-games.com/79/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "75",
    name: "Ganesha Fortune",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-14.png",
    url: "https://m.pgsoft-games.com/75/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "57",
    name: "Dragon Hatch",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-15.png",
    url: "https://m.pgsoft-games.com/57/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "126",
    name: "Fortune Tiger",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-16.png",
    url: "https://m.pgsoft-games.com/126/index.html?l=id/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "132",
    name: "Wild Coaster",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-17.png",
    url: "https://m.pgsoft-games.com/132/index.html?l=id&ot=82b8b0f88e17ae53611e6dd7f167bc38&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "1580541",
    name: "Mafia Mayhem",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-18.png",
    url: "https://m.pgsoft-games.com/1580541/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "125",
    name: "Butterfly Blossom",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-19.png",
    url: "https://m.pgsoft-games.com/125/index.html?l=id/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "71",
    name: "Caishen wins",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-20.png",
    url: "https://m.pgsoft-games.com/71/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "101",
    name: "Rise of Apollo",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-21.png",
    url: "https://m.pgsoft-games.com/101/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "94",
    name: "Bali vacation",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-22.png",
    url: "https://m.pgsoft-games.com/94/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "1655268",
    name: "Tsar treasures",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-23.png",
    url: "https://m.pgsoft-games.com/1655268/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "1572362",
    name: "Gladiators glory",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-24.png",
    url: "https://m.pgsoft-games.com/1572362/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "82",
    name: "Phoenix rises",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-25.png",
    url: "https://m.pgsoft-games.com/82/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "102",
    name: "Mermaid riches",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-26.png",
    url: "https://m.pgsoft-games.com/102/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "93",
    name: "Opera dynasty",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-27.png",
    url: "https://m.pgsoft-games.com/93/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "54",
    name: "Captains bounty",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-28.png",
    url: "https://m.pgsoft-games.com/54/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "84",
    name: "Queen of bounty",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-29.png",
    url: "https://m.pgsoft-games.com/84/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "103",
    name: "Crypto gold",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-30.png",
    url: "https://m.pgsoft-games.com/103/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "70",
    name: "Candy burst",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-31.png",
    url: "https://m.pgsoft-games.com/70/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "100",
    name: "Candy bonanza",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-32.png",
    url: "https://m.pgsoft-games.com/100/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "1615454",
    name: "Werewolfs hunt",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-33.png",
    url: "https://m.pgsoft-games.com/1615454/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "121",
    name: "Destiny of sun and moon",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-34.png",
    url: "https://m.pgsoft-games.com/121/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "120",
    name: "The queens banquet",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-35.png",
    url: "https://m.pgsoft-games.com/120/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "86",
    name: "Galactic gems",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-36.png",
    url: "https://m.pgsoft-games.com/86/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "115",
    name: "Supermarket spree",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-37.png",
    url: "https://m.pgsoft-games.com/115/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "112",
    name: "Oriental prosperity",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-38.png",
    url: "https://m.pgsoft-games.com/112/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "122",
    name: "Garuda gems",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-39.png",
    url: "https://m.pgsoft-games.com/122/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "110",
    name: "Jurassic kingdom",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-40.png",
    url: "https://m.pgsoft-games.com/110/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com&utm_medium=social"
  },
  {
    id: "118",
    name: "Mask carnival",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-41.png",
    url: "https://m.pgsoft-games.com/118/index.html?l=id/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "117",
    name: "Cocktail Nights",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-42.png",
    url: "https://m.pgsoft-games.com/117/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "60",
    name: "Leprechaun riches",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-43.png",
    url: "https://m.pgsoft-games.com/60/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "62",
    name: "Gem saviour conquest",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-44.png",
    url: "https://m.pgsoft-games.com/62/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "73",
    name: "Egypt book of mystery",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-45.png",
    url: "https://m.pgsoft-games.com/73/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "92",
    name: "Thai river wonders",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-46.png",
    url: "https://m.pgsoft-games.com/92/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "95",
    name: "Majestic treasures",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-47.png",
    url: "https://m.pgsoft-games.com/95/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "98",
    name: "Fortune ox",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-48.png",
    url: "https://m.pgsoft-games.com/98/index.html?l=id&ot=82b8b0f88e17ae53611e6dd7f167bc38&btt=2&from=&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "97",
    name: "Jack frosts",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-49.png",
    url: "https://m.pgsoft-games.com/97/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "90",
    name: "Secrets of cleopatra",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-50.png",
    url: "https://m.pgsoft-games.com/90/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "88",
    name: "Jewels of prosperity",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-51.png",
    url: "https://m.pgsoft-games.com/88/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "80",
    name: "Circus delight",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-52.png",
    url: "https://m.pgsoft-games.com/80/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "69",
    name: "Bikini paradise",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-53.png",
    url: "https://m.pgsoft-games.com/69/index.html?l=id/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "85",
    name: "Genie 3 wishes",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-54.png",
    url: "https://m.pgsoft-games.com/85/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "58",
    name: "Vampire charm",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-55.png",
    url: "https://m.pgsoft-games.com/58/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "42",
    name: "Ganesha gold",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-56.png",
    url: "https://m.pgsoft-games.com/42/index.html?l=id&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "128",
    name: "Legend of perseus",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-57.png",
    url: "https://m.pgsoft-games.com/128/index.html?l=id&ot=82b8b0f88e17ae53611e6dd7f167bc38&btt=2&from=https://&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "130",
    name: "Lucky piggy",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-58.png",
    url: "https://m.pgsoft-games.com/130/index.html?l=id&ot=82b8b0f88e17ae53611e6dd7f167bc38&btt=2&from=&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "129",
    name: "Fish prawn crab",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-59.png",
    url: "https://m.pgsoft-games.com/129/index.html?l=id&ot=82b8b0f88e17ae53611e6dd7f167bc38&btt=2&from=&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "123",
    name: "Rooster rumbler",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-60.png",
    url: "https://m.pgsoft-games.com/123/index.html?l=id&ot=82b8b0f88e17ae53611e6dd7f167bc38&btt=2&from=&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "124",
    name: "Battleground royale",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-61.png",
    url: "https://m.pgsoft-games.com/124/index.html?l=id&ot=82b8b0f88e17ae53611e6dd7f167bc38&btt=2&from=&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "68",
    name: "Fortune mouse",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-62.png",
    url: "https://m.pgsoft-games.com/68/index.html?l=id&ot=82b8b0f88e17ae53611e6dd7f167bc38&btt=2&from=&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "119",
    name: "Spirited-wonders",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-63.png",
    url: "https://m.pgsoft-games.com/119/index.html?l=id&ot=82b8b0f88e17ae53611e6dd7f167bc38&btt=2&from=&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "107",
    name: "Legendary-monkey-king",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-64.png",
    url: "https://m.pgsoft-games.com/107/index.html?l=id&ot=82b8b0f88e17ae53611e6dd7f167bc38&btt=2&from=&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "91",
    name: "Guardians of Ice and Fire",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-65.png",
    url: "https://m.pgsoft-games.com/91/index.html?l=id&ot=82b8b0f88e17ae53611e6dd7f167bc38&btt=2&from=&language=id-ID&__refer=m.pg-redirect.net&or=static.pgsoft-games.com"
  },
  {
    id: "25",
    name: "Plushie Frenzy",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-74.png",
    url: "https://m.pgsoft-games.com/25/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "2",
    name: "Gem Saviour",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-73.png",
    url: "https://m.pgsoft-games.com/2/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "7",
    name: "Medusa",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-72.png",
    url: "https://m.pgsoft-games.com/7/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "26",
    name: "Tree Of Fortune",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-71.png",
    url: "https://m.pgsoft-games.com/26/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "24",
    name: "Win Win Won",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-70.png",
    url: "https://m.pgsoft-games.com/24/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "6",
    name: "Medusa II",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-69.png",
    url: "https://m.pgsoft-games.com/6/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  },
  {
    id: "3",
    name: "Fortune Gods",
    thumbnail: "https://nineelmslondon.com/wp-content/uploads/2024/05/icon-game-pg-soft-68.png",
    url: "https://m.pgsoft-games.com/3/index.html?ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&language=en-EN&__refer=m.pg-redirect.com&or=static.pgsoft-games.com"
  }
];

// Export popular games (first 8)
export const popularGames = demoGames.slice(0, 8);