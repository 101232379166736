import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Play, Star, X } from 'lucide-react';
import { demoGames } from '../../data/games';

export const DemoGameGrid = () => {
  const [selectedGame, setSelectedGame] = useState<string | null>(null);

  const handlePlayGame = (url: string) => {
    setSelectedGame(url);
  };

  const handleCloseGame = () => {
    setSelectedGame(null);
    document.body.style.overflow = '';
  };

  useEffect(() => {
    if (selectedGame) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [selectedGame]);

  return (
    <div className="py-12 bg-gradient-to-b from-black to-red-900/20">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
          {demoGames.map((game, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gradient-to-br from-red-900/30 to-black rounded-xl overflow-hidden"
            >
              <div className="relative p-4">
                <img 
                  src={game.thumbnail}
                  alt={game.name}
                  className="w-full aspect-square object-contain transform hover:scale-110 transition-transform duration-500"
                />
                {game.rtp && (
                  <div className="absolute top-2 right-2 bg-green-500 text-black px-2 py-1 rounded text-sm font-bold">
                    RTP {game.rtp}
                  </div>
                )}
              </div>

              <div className="p-4">
                <h3 className="text-lg font-bold text-white mb-2 truncate">
                  {game.name}
                </h3>
                <div className="text-sm text-gray-400 mb-4">
                  {game.provider && <div>{game.provider}</div>}
                  {game.type && <div>{game.type}</div>}
                </div>
                <div className="grid grid-cols-2 gap-2">
                  <button 
                    onClick={() => handlePlayGame(game.url)}
                    className="bg-red-600 hover:bg-red-700 text-white py-2 rounded-lg transition-colors flex items-center justify-center gap-1"
                  >
                    <Play size={14} />
                    เล่นเลย
                  </button>
                  <button className="border border-red-600 text-red-500 hover:bg-red-600 hover:text-white py-2 rounded-lg transition-colors flex items-center justify-center gap-1">
                    <Star size={14} />
                    สูตรเกมส์
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Game Modal */}
        <AnimatePresence>
          {selectedGame && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/90 z-50 flex items-center justify-center"
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="w-screen h-screen bg-black relative"
              >
                <button
                  onClick={handleCloseGame}
                  className="absolute right-4 top-4 text-white/50 hover:text-white z-[60]"
                >
                  <X size={24} />
                </button>
                
                <div className="absolute inset-0 w-full h-full">
                  <iframe
                    src={selectedGame}
                    className="w-screen h-screen border-0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-modals allow-presentation allow-orientation-lock allow-downloads allow-pointer-lock allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                    referrerPolicy="origin"
                  />
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};