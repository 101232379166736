
import { useParams } from 'react-router-dom';
import { ArticleContent } from '../../components/articles/ArticleContent';
import { Helmet } from 'react-helmet-async';

export const ArticlePage = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-black to-red-900/20 py-16">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-2xl text-red-500">ไม่พบบทความที่คุณต้องการ</h1>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>บทความ | NIGOAL</title>
        <meta name="description" content="บทความเกี่ยวกับการเดิมพันออนไลน์" />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-b from-black to-red-900/20 py-16">
        <div className="container mx-auto px-4 max-w-4xl">
          <ArticleContent articleId={id} />
        </div>
      </div>
    </>
  );
};
