export const getRandomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const randomImage = () => {
  const images = [
    '/images/promotions/promotion1.webp',
    '/images/promotions/promotion2.webp',
    '/images/promotions/promotion3.webp',
    '/images/promotions/promotion4.webp',
    '/images/promotions/promotion5.webp',
    '/images/promotions/promotion6.webp',
  ];
  return images[Math.floor(Math.random() * images.length)];
}