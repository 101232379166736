
import { motion } from 'framer-motion';
import { Target, Zap, Gift, Trophy, CreditCard, Shield } from 'lucide-react';

const features = [
  {
    icon: Target,
    title: "ราคาน้ำดีที่สุด",
    description: "ค่าน้ำ 4 ตังค์ ราคาบอลที่ดีที่สุด จ่ายเต็มไม่มีอั้น"
  },
  {
    icon: Zap,
    title: "แทงบอลสดไม่มีดีเลย์",
    description: "ระบบแทงบอลสดเรียลไทม์ อัพเดทราคาทันที"
  },
  {
    icon: Gift,
    title: "โบนัสและโปรโมชั่น",
    description: "รับโบนัสสูงสุด 100% คืนยอดเสีย 0.5% ทุกสัปดาห์"
  },
  {
    icon: Trophy,
    title: "ครบทุกลีกดัง",
    description: "ครอบคลุมทุกการแข่งขัน ทั้งลีกใหญ่และลีกรอง"
  },
  {
    icon: CreditCard,
    title: "ฝาก-ถอนไม่มีขั้นต่ำ",
    description: "ระบบฝาก-ถอนอัตโนมัติ รวดเร็วภายใน 30 วินาที"
  },
  {
    icon: Shield,
    title: "มั่นคงปลอดภัย 100%",
    description: "ระบบความปลอดภัยมาตรฐานสากล เชื่อถือได้"
  }
];

export const SportsFeatures = () => {
  return (
    <div className="py-16 bg-gradient-to-b from-black to-red-900/20">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold text-white mb-4">
            จุดเด่นของการแทงบอลออนไลน์
          </h2>
          <p className="text-gray-300 max-w-2xl mx-auto">
            ระบบแทงบอลที่ดีที่สุด พร้อมฟีเจอร์ครบครันเพื่อประสบการณ์การเดิมพันที่เหนือชั้น
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gradient-to-br from-red-900/30 to-black p-6 rounded-xl"
            >
              <feature.icon className="text-red-500 w-12 h-12 mb-4" />
              <h3 className="text-xl font-bold text-white mb-2">{feature.title}</h3>
              <p className="text-gray-300">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};