export const GAME_CONFIG = {
  WINNING_NUMBER: '555', // Changed winning combination to 555
  INITIAL_COINS: 100,
  SPIN_COST: 10,
  WIN_AMOUNT: 50,
  MAX_COINS: 100,
  SPIN_DURATION: 3000,
  HISTORY_LIMIT: 10, // Number of history items to show
} as const;

export const generateRandomNumber = (): string => 
  Math.floor(Math.random() * 10).toString();

export const checkWinningCombination = (numbers: string[]): boolean =>
  numbers.join('') === GAME_CONFIG.WINNING_NUMBER;

export const calculateNewCoins = (current: number, isWin: boolean): number => {
  const afterSpinCost = current - GAME_CONFIG.SPIN_COST;
  return isWin ? Math.min(afterSpinCost + GAME_CONFIG.WIN_AMOUNT, GAME_CONFIG.MAX_COINS) : afterSpinCost;
};

export const generateSpinKey = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const length = 8;
  let key = '';
  for (let i = 0; i < length; i++) {
    key += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return key;
};