import React from 'react';
import { motion } from 'framer-motion';

export const DemoGames: React.FC = () => {
  return (
    <div className="py-16 bg-gradient-to-b from-black to-red-900/20">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold text-white mb-4">
            เกมส์ยอดนิยมจาก nigoal
          </h2>
          <p className="text-gray-300 max-w-2xl mx-auto">
            รวมเกมส์ทำเงินยอดนิยม พร้อมให้ทดลองเล่นฟรีจาก nigoal
          </p>
        </motion.div>
      </div>
    </div>
  );
};