import { SlotHero } from '../../components/slots/SlotHero';
import { PopularSlots } from '../../components/slots/PopularSlots';
import { SlotFeatures } from '../../components/slots/SlotFeatures';
import { SlotGuide } from '../../components/slots/SlotGuide';
import { SlotSEOContent } from '../../components/slots/SlotSEOContent';
import { CoinRain } from '../../components/effects/CoinRain';
import { BrandLink } from '../../components/common/BrandLink';
import { motion } from 'framer-motion';

export const SlotsPage = () => {
  return (
    <div className="min-h-screen bg-black">
      <SlotHero />
      <PopularSlots />
      <SlotFeatures />
      <SlotGuide />
      <SlotSEOContent />
      <CoinRain />

      {/* Hero Section with BrandLinks */}
      <div className="relative bg-gradient-to-b from-black to-red-900/20 py-10">
        <div className="container mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center max-w-4xl mx-auto"
          >
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-4">
              สล็อตออนไลน์ <BrandLink name="NIGOAL" />
            </h1>
            <p className="text-lg md:text-xl text-gray-300 max-w-2xl mx-auto mb-8">
              เล่นสล็อตออนไลน์กับ <BrandLink name="nigoal555" /> ค่ายเกมชั้นนำระดับโลก 
              พร้อมโบนัสและโปรโมชั่นมากมาย
            </p>
            <div className="space-x-4">
              <button className="bg-red-600 hover:bg-red-700 text-white py-3 px-8 rounded-full font-medium transition-colors">
                เล่นเลย
              </button>
              <button className="bg-transparent hover:bg-white/10 text-white border border-white py-3 px-8 rounded-full font-medium transition-colors">
                ทดลองเล่นฟรี
              </button>
            </div>
          </motion.div>
        </div>
      </div>

      {/* FAQ Section */}
      <section className="py-10 bg-gray-900">
        <div className="container mx-auto px-4">
          <div className="text-center mb-10">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-white mb-4">คำถามที่พบบ่อย</h2>
            <p className="text-base md:text-lg text-gray-300 max-w-2xl mx-auto">
              ข้อสงสัยเกี่ยวกับการเล่นสล็อตออนไลน์บน <BrandLink name="nigoal555" />
            </p>
          </div>
          
          <div className="max-w-3xl mx-auto space-y-4">
            <div className="bg-red-900/30 rounded-lg p-4">
              <h3 className="text-lg font-semibold text-white">สล็อตออนไลน์ <BrandLink name="NIGOAL" /> มีเกมอะไรบ้าง?</h3>
              <p className="text-gray-300 mt-2">
                <BrandLink name="NIGOAL" /> มีเกมสล็อตให้เลือกเล่นมากกว่า 1,000 เกม จากค่ายเกมชั้นนำทั่วโลก เช่น PG Slot, Joker Gaming, Pragmatic Play และค่ายดังอื่นๆ อีกมากมาย ครอบคลุมทุกธีมและรูปแบบการเล่น
              </p>
            </div>
            
            <div className="bg-red-900/30 rounded-lg p-4">
              <h3 className="text-lg font-semibold text-white">ทำไมต้องเลือกเล่นสล็อตที่ <BrandLink name="nigoal555" />?</h3>
              <p className="text-gray-300 mt-2">
                <BrandLink name="nigoal555" /> มีจุดเด่นคือ ระบบที่เสถียร การเดิมพันที่ยุติธรรม โบนัสและโปรโมชั่นที่คุ้มค่า การฝาก-ถอนที่รวดเร็วภายใน 30 วินาที และความปลอดภัยสูงสุด พร้อมกับบริการลูกค้าตลอด 24 ชั่วโมง
              </p>
            </div>
            
            <div className="bg-red-900/30 rounded-lg p-4">
              <h3 className="text-lg font-semibold text-white"><BrandLink name="NIGOAL" /> มีโบนัสและโปรโมชั่นอะไรบ้าง?</h3>
              <p className="text-gray-300 mt-2">
                <BrandLink name="NIGOAL" /> มีโบนัสและโปรโมชั่นมากมาย เช่น โบนัสต้อนรับสมาชิกใหม่ โบนัสเติมเงิน ฟรีสปิน คืนยอดเสีย และโปรโมชั่นพิเศษตามเทศกาลต่างๆ ติดตามโปรโมชั่นล่าสุดได้ที่หน้าโปรโมชั่นของเรา
              </p>
            </div>
            
            <div className="bg-red-900/30 rounded-lg p-4">
              <h3 className="text-lg font-semibold text-white">เล่นสล็อตบน <BrandLink name="nigoal" /> บนมือถือได้หรือไม่?</h3>
              <p className="text-gray-300 mt-2">
                ได้ <BrandLink name="nigoal" /> รองรับการเล่นบนทุกอุปกรณ์ ทั้งคอมพิวเตอร์ แท็บเล็ต และสมาร์ทโฟนทุกรุ่น ทั้งระบบ iOS และ Android ไม่ต้องดาวน์โหลดแอพ เพียงเข้าผ่านเว็บบราวเซอร์ก็เล่นได้ทันที
              </p>
            </div>
            
            <div className="bg-red-900/30 rounded-lg p-4">
              <h3 className="text-lg font-semibold text-white">ฝาก-ถอนกับ <BrandLink name="nigoal555" /> ทำอย่างไร?</h3>
              <p className="text-gray-300 mt-2">
                การฝาก-ถอนกับ <BrandLink name="nigoal555" /> ทำได้ง่ายผ่านระบบอัตโนมัติที่รวดเร็วภายใน 30 วินาที รองรับธนาคารชั้นนำทุกแห่งและวอลเล็ตยอดนิยม ไม่มีขั้นต่ำในการฝาก และถอนขั้นต่ำเพียง 100 บาทเท่านั้น
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};