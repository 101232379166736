import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Clock } from 'lucide-react';
import { LoadingSpinner } from '../common/LoadingSpinner';
import type { Article } from '../../types/article';
import { randomImage } from '../../utils/random';

interface ArticlesSidebarProps {
  currentArticleId: string;
}

export const ArticlesSidebar: React.FC<ArticlesSidebarProps> = ({ currentArticleId }) => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadArticles = async () => {
      try {
        const response = await fetch('/static/articles/index.json');
        const data = await response.json();
        setArticles(data.filter((article: Article) => article.id !== currentArticleId).slice(0, 5));
      } catch (error) {
        console.error('Failed to load related articles:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadArticles();
  }, [currentArticleId]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div className="bg-gradient-to-br from-red-900/30 to-black rounded-xl p-6">
      <h2 className="text-xl font-bold text-white mb-6">บทความที่เกี่ยวข้อง</h2>
      <div className="space-y-6">
        {articles.map((article, index) => (
          <motion.div
            key={article.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <Link to={article.url} className="group block">
              <img
                src={article.og_image}
                alt={article.title}
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src = randomImage();
                }}
                className="w-full aspect-video object-cover rounded-lg mb-3"
              />
              <h3 className="text-white font-semibold group-hover:text-red-500 transition-colors line-clamp-2 mb-2">
                {article.title}
              </h3>
              <div className="flex items-center gap-2 text-sm text-gray-400">
                <Clock size={14} />
                {new Date(article.created_at).toLocaleDateString('th-TH')}
              </div>
            </Link>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

