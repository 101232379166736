import { useEffect } from 'react';
import { ArticlesHero } from '../../components/articles/ArticlesHero';
import { ArticlesList } from '../../components/articles/ArticlesList';
import { ArticlesSidebar } from '../../components/articles/ArticlesSidebar';
import { CoinRain } from '../../components/effects/CoinRain';

export const ArticlesPage = () => {
  useEffect(() => {
    const loadData = async () => {
      try {
        // Your data fetching logic
      } catch (error) {
        console.error('Error loading articles:', error);
      }
    };

    loadData();
  }, []);

  return (
    <div className="min-h-screen bg-black pt-16">
      <ArticlesHero />
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          <div className="lg:col-span-3">
            <ArticlesList />
          </div>
          <div className="lg:col-span-1">
            <ArticlesSidebar currentArticleId="" />
            <CoinRain />
          </div>
        </div>
      </div>
    </div>
  );
};