import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { Navbar } from './components/layout/Navbar';
import { Footer } from './components/layout/Footer';
import { HomePage } from './pages/home/HomePage';
import { SlotsPage } from './pages/slots/SlotsPage';
import { CasinoPage } from './pages/casino/CasinoPage';
import { SportsPage } from './pages/sports/SportsPage';
import { LotteryPage } from './pages/lottery/LotteryPage';
import { CockfightPage } from './pages/cockfight/CockfightPage';
import { ArticlesPage } from './pages/articles/ArticlesPage';
import { ArticlePage } from './pages/articles/ArticlePage';
import { DemoPage } from './pages/demo/DemoPage';
// Promotion pages
import { WelcomeBonusPage } from './pages/promotions/WelcomeBonusPage';
import { CashbackPage } from './pages/promotions/CashbackPage';
import { ReferFriendPage } from './pages/promotions/ReferFriendPage';
import { FreeCreditPage } from './pages/promotions/FreeCreditPage';
// Help pages
import { RegistrationPage } from './pages/help/RegistrationPage';
import { DepositWithdrawPage } from './pages/help/DepositWithdrawPage';
import { TermsPage } from './pages/help/TermsPage';
import { ContactPage } from './pages/help/ContactPage';
import { CoinRainProvider } from './contexts/CoinRainContext';
import { useScrollToTop } from './hooks/useScrollToTop';
import { ScrollButtons } from './components/navigation/ScrollButtons';
// Line page
import LinePage from './pages/line';

// Create inline ScrollToTop component
const ScrollToTop = () => {
  useScrollToTop();
  return null;
};

export default function App() {
  return (
    <HelmetProvider>
      <CoinRainProvider>
        <BrowserRouter>
          <Helmet>
            {/* Base SEO tags that should apply to all pages */}
            <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
            <meta name="author" content="NIGOAL" />
            <link rel="canonical" href="https://www.azulejosalcor.com/" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
            <meta http-equiv="content-language" content="th" />
            <meta name="language" content="Thai" />
            <meta name="geo.region" content="TH" />
            <meta name="geo.placename" content="Thailand" />
            <meta name="revisit-after" content="1 days" />
            <meta name="rating" content="general" />
            <meta name="format-detection" content="telephone=no" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-status-bar-style" content="black" />
            <meta name="apple-mobile-web-app-title" content="NIGOAL" />
            <meta name="application-name" content="NIGOAL" />
            <meta name="theme-color" content="#000000" />
            
            {/* Google Tag Manager */}
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-DB78TY7858"></script>
            <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-DB78TY7858', {
                  'page_title': 'NIGOAL - เว็บทดลองเล่นออนไลน์อันดับ 1 ในไทย',
                  'anonymize_ip': true
                });
              `}
            </script>
            
            {/* DNS Prefetch & Preconnect for Performance */}
            <link rel="dns-prefetch" href="//www.googletagmanager.com" />
            <link rel="preconnect" href="//www.googletagmanager.com" crossOrigin="anonymous" />
            <link rel="dns-prefetch" href="//fonts.googleapis.com" />
            <link rel="preconnect" href="//fonts.googleapis.com" crossOrigin="anonymous" />
            
            {/* Verification Code - Replace with your actual code */}
            <meta name="google-site-verification" content="YOUR_VERIFICATION_CODE" />
          </Helmet>
          <ScrollToTop />
          <div className="min-h-screen bg-black flex flex-col">
            <Navbar />
            <main className="flex-grow">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/slots" element={<SlotsPage />} />
                <Route path="/casino" element={<CasinoPage />} />
                <Route path="/sports" element={<SportsPage />} />
                <Route path="/lottery" element={<LotteryPage />} />
                <Route path="/cockfight" element={<CockfightPage />} />
                <Route path="/articles" element={<ArticlesPage />} />
                <Route path="/articles/:id" element={<ArticlePage />} />
                <Route path="/demo" element={<DemoPage />} />
                
                {/* Promotion pages */}
                <Route path="/promotions/welcome-bonus" element={<WelcomeBonusPage />} />
                <Route path="/promotions/cashback" element={<CashbackPage />} />
                <Route path="/promotions/refer-friend" element={<ReferFriendPage />} />
                <Route path="/promotions/free-credit" element={<FreeCreditPage />} />
                
                {/* Help pages */}
                <Route path="/help/registration" element={<RegistrationPage />} />
                <Route path="/help/deposit-withdraw" element={<DepositWithdrawPage />} />
                <Route path="/help/terms" element={<TermsPage />} />
                <Route path="/help/contact" element={<ContactPage />} />
                {/* Line page */}
                <Route path="/line" element={<LinePage />} />
              </Routes>
            </main>
            <Footer />
            <ScrollButtons />
          </div>
        </BrowserRouter>
      </CoinRainProvider>
    </HelmetProvider>
  );
}