
import { motion } from 'framer-motion';
import { Star, Shield, Clock, Gift, Zap, Users } from 'lucide-react';

const features = [
  {
    icon: Shield,
    title: "nigoal ระบบทดลองเล่นปลอดภัย",
    description: "ระบบทดลองเล่นที่แยกออกจากระบบเล่นจริง ปลอดภัย 100%"
  },
  {
    icon: Clock,
    title: "nigoal เล่นได้ไม่จำกัดเวลา",
    description: "ทดลองเล่นได้ตลอด 24 ชั่วโมง ไม่มีการจำกัดเวลาเล่น"
  },
  {
    icon: Gift,
    title: "nigoal เครดิตฟรีไม่อั้น",
    description: "รับเครดิตฟรีได้ไม่จำกัด เมื่อเครดิตหมดรับใหม่ได้ทันที"
  },
  {
    icon: Zap,
    title: "nigoal เล่นได้ทุกอุปกรณ์",
    description: "รองรับการเล่นผ่านมือถือ แท็บเล็ต และคอมพิวเตอร์"
  },
  {
    icon: Users,
    title: "nigoal ไม่ต้องสมัครสมาชิก",
    description: "เข้าเล่นได้ทันทีโดยไม่ต้องสมัครสมาชิกหรือกรอกข้อมูลใดๆ"
  },
  {
    icon: Star,
    title: "nigoal อัพเดทเกมใหม่ทุกวัน",
    description: "มีเกมสล็อตใหม่ๆ อัพเดททุกวัน พร้อมสูตรและเทคนิคการเล่น"
  }
];

export const SlotFeatures = () => {
  return (
    <div className="py-16 bg-gradient-to-b from-red-900/20 to-black">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold text-white mb-4">
            จุดเด่นของระบบทดลองเล่นสล็อตฟรี
          </h2>
          <p className="text-gray-300 max-w-2xl mx-auto">
            ระบบทดลองเล่นสล็อตที่ดีที่สุด พร้อมฟีเจอร์ครบครันเพื่อประสบการณ์การเล่นที่สมจริงที่สุด
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gradient-to-br from-red-900/30 to-black p-6 rounded-xl"
            >
              <feature.icon className="text-red-500 w-12 h-12 mb-4" />
              <h3 className="text-xl font-bold text-white mb-2">{feature.title}</h3>
              <p className="text-gray-300">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};