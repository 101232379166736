
import { motion } from 'framer-motion';
import { BookOpen, Target, Coins, Trophy } from 'lucide-react';

const steps = [
  {
    icon: BookOpen,
    title: "เลือกเกมที่ต้องการ",
    description: "เลือกจากรายการเกมสล็อตยอดนิยมที่เรารวบรวมมาให้"
  },
  {
    icon: Target,
    title: "กดปุ่มทดลองเล่น",
    description: "กดปุ่ม 'ทดลองเล่น' เพื่อเข้าสู่โหมดเล่นฟรี"
  },
  {
    icon: Coins,
    title: "รับเครดิตฟรี",
    description: "รับเครดิตฟรี 100,000 บาทสำหรับทดลองเล่น"
  },
  {
    icon: Trophy,
    title: "เริ่มเล่นได้ทันที",
    description: "สนุกกับการเล่นสล็อตฟรีได้ทันทีไม่มีเงื่อนไข"
  }
];

export const SlotGuide = () => {
  return (
    <div className="py-16 bg-gradient-to-b from-black to-red-900/20">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold text-white mb-4">
            วิธีทดลองเล่นสล็อตฟรี
          </h2>
          <p className="text-gray-300 max-w-2xl mx-auto">
            เริ่มต้นทดลองเล่นสล็อตฟรีได้ง่ายๆ เพียงไม่กี่ขั้นตอน
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
          {steps.map((step, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gradient-to-br from-red-900/30 to-black p-6 rounded-xl text-center"
            >
              <div className="bg-red-900/30 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                <step.icon className="text-red-500 w-8 h-8" />
              </div>
              <h3 className="text-xl font-bold text-white mb-2">{step.title}</h3>
              <p className="text-gray-300">{step.description}</p>
            </motion.div>
          ))}
        </div>

        <div className="mt-12 prose prose-invert max-w-none">
          <h3 className="text-2xl font-bold text-white mb-4">เพิ่มเติมเกี่ยวกับการทดลองเล่นสล็อตฟรี</h3>
          <div className="text-gray-300 space-y-4">
            <p>
              การทดลองเล่นสล็อตฟรีเป็นวิธีที่ดีที่สุดในการทำความเข้าใจกับเกมสล็อตต่างๆ ก่อนเริ่มเล่นจริง 
              คุณสามารถเรียนรู้กฎกติกา รูปแบบการเล่น และอัตราการจ่ายเงินของแต่ละเกมได้โดยไม่ต้องเสี่ยงเสียเงินจริง
            </p>
            <p>
              ระบบทดลองเล่นของเราจำลองการเล่นจริงทุกอย่าง ทั้งรูปแบบการหมุน โบนัสพิเศษ 
              และอัตราการออกรางวัล ทำให้คุณได้รับประสบการณ์การเล่นที่เหมือนจริงที่สุด
            </p>
            <p>
              นอกจากนี้ เรายังมีบทความแนะนำเทคนิคการเล่น สูตรสล็อต และการวิเคราะห์รูปแบบการออกรางวัล 
              อัพเดทใหม่ทุกวัน เพื่อให้คุณเพิ่มโอกาสในการชนะเมื่อเล่นจริง
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};