import { useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageCircle, AlertTriangle, Check } from 'lucide-react';
import { TubeBurst } from '../effects/TubeBurst';
import { 
  GAME_CONFIG,
  generateRandomNumber,
  checkWinningCombination,
  calculateNewCoins,
  generateSpinKey
} from '../../utils/luckySpinUtils';
import type { SpinHistory } from '../../types/game';
import { QRCodeSVG } from 'qrcode.react';

// สถานะการส่งข้อมูลไป API
type ApiStatus = 'idle' | 'sending' | 'success' | 'error' | 'retrying';

export const LuckySpin = () => {
  const [numbers, setNumbers] = useState(['0', '0', '0']);
  const [spinning, setSpinning] = useState(false);
  const [coins, setCoins] = useState<number>(GAME_CONFIG.INITIAL_COINS);
  const [won, setWon] = useState(false);
  const [currentKey, setCurrentKey] = useState<string>('');
  const [history, setHistory] = useState<SpinHistory[]>([]);
  const [apiStatus, setApiStatus] = useState<ApiStatus>('idle');
  const [apiErrorMessage, setApiErrorMessage] = useState<string>('');

  // ฟังก์ชันสำหรับส่งข้อมูลไป API พร้อมระบบ retry
  const sendDataToAPI = useCallback(async (isWin: boolean, key: string, numbers: string[], retryCount = 0, delay = 1000) => {
    const maxRetries = 3;
    
    try {
      setApiStatus('sending');
      setApiErrorMessage('');
      
      const response = await fetch('https://notion-api-rust.onrender.com/spin-results', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          key: key,
          datetime: new Date().toISOString(),
          number: parseInt(numbers.join('')),
          is_win: isWin,
          checked: false
        }),
      });

      if (!response.ok) {
        throw new Error(`API request failed: ${response.status}`);
      }

      // ตรวจสอบว่า response มี content หรือไม่ และ content type ถูกต้องหรือไม่
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        try {
          // อาจไม่มี JSON response กลับมาในบางกรณี
          const text = await response.text();
          const data = text ? JSON.parse(text) : {};
          console.log('API response:', data);
        } catch (jsonError) {
          console.log('Response is not valid JSON, but request succeeded');
        }
      } else {
        console.log('Response received but not JSON format');
      }
      
      setApiStatus('success');
    } catch (error) {
      console.error('Error sending spin result:', error);
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      setApiErrorMessage(errorMessage);
      
      // ถ้าเป็น 429 (Too Many Requests) และยังไม่เกินจำนวน retry ที่กำหนด
      if (errorMessage.includes('429') && retryCount < maxRetries) {
        setApiStatus('retrying');
        console.log(`Retrying in ${delay}ms... (${retryCount + 1}/${maxRetries})`);
        
        // Exponential backoff: ทุกครั้งที่ retry จะรอนานขึ้นเป็นเท่าตัว
        setTimeout(() => {
          sendDataToAPI(isWin, key, numbers, retryCount + 1, delay * 2);
        }, delay);
      } else {
        setApiStatus('error');
      }
    }
  }, []);

  const spin = useCallback(() => {
    if (spinning || coins < GAME_CONFIG.SPIN_COST) return;
    
    setSpinning(true);
    setWon(false);
    setApiStatus('idle');
    
    // Generate and set new key immediately when spin starts
    const newKey = generateSpinKey();
    setCurrentKey(newKey);

    let spinInterval = setInterval(() => {
      setNumbers(prev => prev.map(() => generateRandomNumber()));
    }, 100);

    setTimeout(() => {
      clearInterval(spinInterval);
      
      const newNumbers = Array(3).fill(0).map(() => 
        Math.random() < 0.1 ? '5' : generateRandomNumber()
      );
      
      const isWin = checkWinningCombination(newNumbers);
      
      setNumbers(newNumbers);
      setWon(isWin);
      setCoins(current => calculateNewCoins(current, isWin));
      setSpinning(false);

      // Add to history
      setHistory(prev => {
        const newHistory = [{
          id: Date.now().toString(),
          numbers: newNumbers,
          key: newKey,
          timestamp: new Date(),
          isWin
        }, ...prev];
        
        // Keep only the last HISTORY_LIMIT items
        return newHistory.slice(0, GAME_CONFIG.HISTORY_LIMIT);
      });
      
      // ส่งข้อมูลไป API เฉพาะเมื่อชนะ (isWin เป็น true) เท่านั้น
      if (isWin) {
        sendDataToAPI(isWin, newKey, newNumbers);
      } else {
        // รีเซ็ตสถานะ API เมื่อไม่ได้ส่งข้อมูล
        setApiStatus('idle');
      }
    }, GAME_CONFIG.SPIN_DURATION);
  }, [spinning, coins, sendDataToAPI]);

  // const resetGame = useCallback(() => {
  //   setNumbers(['0', '0', '0']);
  //   setCoins(GAME_CONFIG.INITIAL_COINS);
  //   setWon(false);
  //   setSpinning(false);
  // }, []);

  const generateVerificationData = (spinData: SpinHistory) => {
    return `NIGOAL555-${spinData.key}-${spinData.numbers.join('')}-${spinData.timestamp.getTime()}`;
  };

  // ฟังก์ชันแสดงสถานะการส่งข้อมูล API
  const renderApiStatus = () => {
    switch (apiStatus) {
      case 'sending':
        return (
          <div className="text-yellow-400 text-xs flex items-center gap-1 justify-center mt-2">
            <div className="animate-spin h-3 w-3 border-2 border-yellow-400 rounded-full border-t-transparent"></div>
            <span>กำลังส่งข้อมูล...</span>
          </div>
        );
      case 'retrying':
        return (
          <div className="text-orange-400 text-xs flex items-center gap-1 justify-center mt-2">
            <div className="animate-spin h-3 w-3 border-2 border-orange-400 rounded-full border-t-transparent"></div>
            <span>กำลังลองใหม่...</span>
          </div>
        );
      case 'success':
        return (
          <div className="text-green-400 text-xs flex items-center gap-1 justify-center mt-2">
            <Check size={12} />
            <span>ส่งข้อมูลสำเร็จ</span>
          </div>
        );
      case 'error':
        return (
          <div className="text-red-400 text-xs flex items-center gap-1 justify-center mt-2">
            <AlertTriangle size={12} />
            <span>ส่งข้อมูลไม่สำเร็จ: {apiErrorMessage}</span>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="relative py-16 bg-gradient-to-b from-red-900/20 via-black to-red-900/20">
      <div className="container mx-auto px-4">
        <div className="max-w-2xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="text-center mb-8"
          >
            <h2 className="text-3xl md:text-4xl font-bold text-red-600 mb-4">Lucky Spin</h2>
            <p className="text-gray-300">
              หมุนเพื่อลุ้นรับรางวัล! เมื่อได้เลข 555 รับทันทีเครดิตฟรี {GAME_CONFIG.WIN_AMOUNT} บาท แคปรูปทักไลน์ได้เลย!
            </p>
          </motion.div>
          
          <div className="bg-gradient-to-br from-red-900/30 to-black p-4 md:p-8 rounded-2xl shadow-2xl relative overflow-hidden border border-red-900/30">
            <AnimatePresence>
              <TubeBurst isVisible={won} />
            </AnimatePresence>

            <div className="flex justify-center gap-2 md:gap-4 mb-4 md:mb-8">
              {numbers.map((number, index) => (
                <motion.div
                  key={index}
                  animate={spinning ? { rotateX: 360 } : won ? {
                    scale: [1, 1.2, 1],
                    transition: { 
                      duration: 0.5,
                      repeat: Infinity,
                      repeatType: "reverse"
                    }
                  } : {}}
                  transition={{ duration: 0.3, repeat: spinning ? Infinity : 0 }}
                  className={`w-16 md:w-20 h-20 md:h-24 bg-gradient-to-b from-red-950 to-black rounded-lg flex items-center justify-center border-2 ${won ? 'border-yellow-500 shadow-lg shadow-yellow-500/50' : 'border-red-600'}`}
                >
                  <span className={`text-2xl md:text-4xl font-bold ${won ? 'text-yellow-500' : 'text-white'}`}>
                    {number}
                  </span>
                </motion.div>
              ))}
            </div>

            <div className="flex flex-col md:flex-row justify-between items-center gap-4 mb-4">
              <div className="text-yellow-500 font-bold text-lg md:text-xl">
                Coins: {coins}/{GAME_CONFIG.MAX_COINS}
              </div>
              <div className="flex gap-2 w-full md:w-auto">
                {/* ปิดการใช้งานปุ่มเริ่มใหม่ชั่วคราว
                {coins < GAME_CONFIG.SPIN_COST && (
                  <button
                    onClick={resetGame}
                    className="flex-1 md:flex-none px-4 md:px-6 py-2 md:py-3 rounded-full font-bold text-white bg-red-600 hover:bg-red-700 transition-all button-glow-red"
                  >
                    เริ่มใหม่
                  </button>
                )}
                */}
                <button
                  onClick={spin}
                  disabled={spinning || coins < GAME_CONFIG.SPIN_COST}
                  className={`flex-1 md:flex-none px-4 md:px-6 py-2 md:py-3 rounded-full font-bold text-white transition-all ${
                    spinning || coins < GAME_CONFIG.SPIN_COST
                      ? 'bg-gray-600 cursor-not-allowed'
                      : 'bg-red-600 hover:bg-red-700 button-glow-red'
                  }`}
                >
                  {spinning ? 'กำลังหมุน...' : `หมุน (${GAME_CONFIG.SPIN_COST} Coins)`}
                </button>
              </div>
            </div>

            {won && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-center relative"
              >
                <div className="absolute -right-2 -top-2 opacity-30 pointer-events-none">
                  <QRCodeSVG 
                    value={generateVerificationData(history[0])}
                    size={80}
                    className="rotate-12"
                  />
                </div>
                
                <div className="text-yellow-500 font-bold mb-4 relative">
                  <div className="absolute -rotate-12 text-xs text-gray-400 opacity-30 top-1 right-4">
                    {new Date().toLocaleString()}
                  </div>
                  🎉 ยินดีด้วย! คุณได้รับเครดิตฟรี {GAME_CONFIG.WIN_AMOUNT} บาท! 🎉
                </div>

                <div className="text-xs text-gray-400 mb-2">
                  Verification: {currentKey}-{numbers.join('')}
                </div>

                <button
                  onClick={() => window.open('https://access.line.me/oauth2/v2.1/login?returnUri=%2Foauth2%2Fv2.1%2Fauthorize%2Fconsent%3Fresponse_type%3Dcode%26client_id%3D1656488536%26redirect_uri%3Dhttps%253A%252F%252Fapp.nigoal555.com%252FLine%252FcallBack%26scope%3Dprofile%2520openid%2520email%26state%3D10d1d0133a36670e966b6b56116158d2f8984b8b827dda8c33c5c7331453092c&loginChannelId=1656488536&loginState=voFCk8bhCogUROks2uxQiz#/', '_blank')}
                  className="inline-flex items-center gap-2 px-4 md:px-6 py-2 md:py-3 bg-green-600 hover:bg-green-700 text-white rounded-full transition-colors button-glow-green"
                >
                  <MessageCircle size={20} />
                  <span>แคปรูปทักไลน์เลย!</span>
                </button>
                
                {/* แสดงสถานะการส่ง API */}
                {renderApiStatus()}
              </motion.div>
            )}

            {/* Current Key Display */}
            {spinning && currentKey && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-center mb-4"
              >
                <span className="text-yellow-500 font-mono font-bold text-xl">
                  KEY: {currentKey}
                </span>
              </motion.div>
            )}

            {/* History Table */}
            <div className="mt-8">
              <h3 className="text-xl font-bold text-white mb-4">ประวัติการเล่น</h3>
              <div className="bg-black/50 rounded-lg overflow-hidden">
                <table className="w-full text-sm">
                  <thead>
                    <tr className="bg-red-900/30">
                      <th className="px-4 py-2 text-left text-white">เวลา</th>
                      <th className="px-4 py-2 text-left text-white">Key</th>
                      <th className="px-4 py-2 text-left text-white">ตัวเลข</th>
                      <th className="px-4 py-2 text-left text-white">ผลลัพธ์</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.map((item) => (
                      <tr key={item.id} className="border-b border-gray-800">
                        <td className="px-4 py-2 text-gray-400">
                          {item.timestamp.toLocaleTimeString()}
                        </td>
                        <td className="px-4 py-2 font-mono text-yellow-500">
                          {item.key}
                        </td>
                        <td className="px-4 py-2 text-white">
                          {item.numbers.join(' ')}
                        </td>
                        <td className="px-4 py-2">
                          <span className={`px-2 py-1 rounded text-xs ${
                            item.isWin 
                              ? 'bg-green-500/20 text-green-400' 
                              : 'bg-red-500/20 text-red-400'
                          }`}>
                            {item.isWin ? 'ชนะ' : 'แพ้'}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};