import { motion } from 'framer-motion';
import { Trophy, Target, Zap, DollarSign } from 'lucide-react';
import { BrandLink } from '../common/BrandLink';

export const SportsHero = () => {
  return (
    <div className="relative bg-gradient-to-b from-black to-red-900/20 py-16">
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center max-w-4xl mx-auto"
        >
          <h1 className="text-2xl md:text-3xl font-bold text-white mb-6">
            <BrandLink name="NIGOAL" /> แทงบอลออนไลน์ <span className="text-red-500">ราคาน้ำดีที่สุด</span>
          </h1>
          <p className="text-xl text-gray-300 mb-8">
            <BrandLink name="nigoal555" /> แทงบอลออนไลน์ ครบทุกลีกดัง ราคาน้ำดีที่สุด พร้อมระบบวิเคราะห์สถิติ และข้อมูลการแข่งขันแบบเรียลไทม์
          </p>
          
          <div className="grid md:grid-cols-4 gap-6 mb-12">
            <div className="bg-gradient-to-br from-red-900/30 to-black p-6 rounded-xl">
              <Trophy className="text-red-500 w-12 h-12 mx-auto mb-4" />
              <h3 className="text-xl font-bold text-white mb-2"><BrandLink name="nigoal" /> ครบทุกลีกดัง</h3>
              <p className="text-gray-300">พรีเมียร์ลีก ลาลีกา บุนเดสลีกา และอื่นๆ</p>
            </div>
            
            <div className="bg-gradient-to-br from-red-900/30 to-black p-6 rounded-xl">
              <Target className="text-red-500 w-12 h-12 mx-auto mb-4" />
              <h3 className="text-xl font-bold text-white mb-2"><BrandLink name="nigoal" /> ราคาน้ำดีที่สุด</h3>
              <p className="text-gray-300">ค่าน้ำ 4 ตังค์ ราคาบอลที่ดีที่สุด</p>
            </div>
            
            <div className="bg-gradient-to-br from-red-900/30 to-black p-6 rounded-xl">
              <Zap className="text-red-500 w-12 h-12 mx-auto mb-4" />
              <h3 className="text-xl font-bold text-white mb-2"><BrandLink name="nigoal" /> แทงบอลสด</h3>
              <p className="text-gray-300">ระบบแทงบอลสดเรียลไทม์ ไม่มีดีเลย์</p>
            </div>

            <div className="bg-gradient-to-br from-red-900/30 to-black p-6 rounded-xl">
              <DollarSign className="text-red-500 w-12 h-12 mx-auto mb-4" />
              <h3 className="text-xl font-bold text-white mb-2"><BrandLink name="nigoal" /> โบนัสสูงสุด</h3>
              <p className="text-gray-300">รับโบนัสสูงสุด 100% ทุกยอดฝาก</p>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <a 
              href="https://app.nigoal555s.com/Register/reg/d0tWemxYRFBtRjZxaUxhVnR0bVd5Zz09" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="bg-red-600 hover:bg-red-700 text-white px-8 py-3 rounded-full font-semibold transition-colors inline-block"
            >
              <BrandLink name="nigoal555" /> สมัครสมาชิก รับโบนัส 100%
            </a>
            <button className="border-2 border-red-600 text-red-500 hover:bg-red-600 hover:text-white px-8 py-3 rounded-full font-semibold transition-colors">
              <BrandLink name="nigoal" /> ดูทีเด็ดบอลวันนี้
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  );
};