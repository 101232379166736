
import { motion } from 'framer-motion';
import { Swords, Clock, Trophy, Target, Shield } from 'lucide-react';

const matches = [
  {
    title: "ไก่ชนสด",
    image: "https://images.unsplash.com/photo-1495556650867-99590cea3657?w=800",
    description: "ถ่ายทอดสดไก่ชนจากสนามชนไก่ชั้นนำ",
    time: "ตลอด 24 ชั่วโมง",
    features: ["ภาพคมชัด HD", "เสียงชัดเจน", "ไม่มีดีเลย์", "ดูได้ทุกอุปกรณ์"]
  },
  {
    title: "ไก่ชนย้อนหลัง",
    image: "https://images.unsplash.com/photo-1550355291-bbee04a92027?w=800",
    description: "รวมคู่ชนไก่ยอดนิยมย้อนหลัง",
    time: "อัพเดททุกวัน",
    features: ["คู่ชนเด็ด", "สถิติครบถ้วน", "วิเคราะห์มวย", "ดูได้ไม่จำกัด"]
  },
  {
    title: "ไก่ชนวันนี้",
    image: "https://images.unsplash.com/photo-1548550023-2bdb3c5beed7?w=800",
    description: "โปรแกรมการชนไก่ประจำวัน",
    time: "ตั้งแต่ 10:00 น.",
    features: ["ราคาน้ำดี", "มวยคู่เอก", "วิเคราะห์มวย", "สถิติย้อนหลัง"]
  }
];

const features = [
  {
    icon: Clock,
    title: "ถ่ายทอดสด 24 ชม.",
    description: "ชมการถ่ายทอดสดตลอด 24 ชั่วโมง จากสนามชนไก่ชั้นนำ"
  },
  {
    icon: Trophy,
    title: "ราคาน้ำดีที่สุด",
    description: "ราคาน้ำดีที่สุด จ่ายเต็มทุกบิล ไม่มีเลื่อน"
  },
  {
    icon: Target,
    title: "ระบบเสถียร",
    description: "ระบบถ่ายทอดสดเสถียร ภาพคมชัดระดับ HD ไม่มีสะดุด"
  },
  {
    icon: Shield,
    title: "มั่นคงปลอดภัย",
    description: "ระบบการเดิมพันที่ปลอดภัย มั่นคง เชื่อถือได้ 100%"
  }
];

const upcomingMatches = [
  {
    title: "คู่เอกประจำวัน",
    time: "14:00 น.",
    location: "สนามมาตรฐาน กรุงเทพ",
    odds: "ต่อ 1.5",
    details: ["พันธุ์เหลืองหางขาว", "น้ำหนัก 3.2 กก.", "สถิติชนะ 8/10"]
  },
  {
    title: "คู่รองประจำวัน",
    time: "15:30 น.",
    location: "สนามมาตรฐาน ชลบุรี",
    odds: "ต่อ 1",
    details: ["พันธุ์เขียวเหลือง", "น้ำหนัก 3.0 กก.", "สถิติชนะ 7/10"]
  }
];

export const CockfightPage = () => {
  return (
    <div className="pt-16 min-h-screen bg-gradient-to-b from-black via-red-900/20 to-black">
      <div className="container mx-auto px-4 py-8">
        {/* Hero Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <div className="flex items-center justify-center gap-3 mb-4">
            <Swords className="text-red-500" size={32} />
            <h1 className="text-3xl font-bold text-white">ไก่ชนออนไลน์</h1>
          </div>
          <p className="text-gray-300 max-w-2xl mx-auto">
            ถ่ายทอดสดไก่ชนตลอด 24 ชั่วโมง พร้อมระบบเดิมพันที่ทันสมัย 
            ราคาน้ำดีที่สุด จ่ายจริง จ่ายเต็ม
          </p>
        </motion.div>

        {/* Features */}
        <div className="grid md:grid-cols-4 gap-6 mb-12">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gradient-to-br from-red-900/30 to-black p-6 rounded-xl text-center"
            >
              <feature.icon className="text-red-500 w-12 h-12 mx-auto mb-4" />
              <h3 className="text-xl font-bold text-white mb-2">{feature.title}</h3>
              <p className="text-gray-300">{feature.description}</p>
            </motion.div>
          ))}
        </div>

        {/* Live Matches */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
          {matches.map((match, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gradient-to-br from-red-900/30 to-black rounded-xl overflow-hidden group"
            >
              <div className="relative">
                <img 
                  src={match.image} 
                  alt={match.title} 
                  className="w-full h-48 object-cover transform group-hover:scale-110 transition-transform duration-500"
                />
                <div className="absolute top-2 right-2 bg-red-600 text-white px-2 py-1 rounded text-sm font-bold">
                  {match.time}
                </div>
              </div>
              
              <div className="p-4">
                <h3 className="text-xl font-bold text-white mb-2">{match.title}</h3>
                <p className="text-gray-400 text-sm mb-3">{match.description}</p>
                
                <div className="flex flex-wrap gap-2 mb-4">
                  {match.features.map((feature, idx) => (
                    <span key={idx} className="text-xs bg-red-900/30 text-red-400 px-2 py-1 rounded">
                      {feature}
                    </span>
                  ))}
                </div>

                <button className="w-full bg-red-600 hover:bg-red-700 text-white py-2 rounded-lg transition-colors">
                  รับชม / แทงไก่ชน
                </button>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Upcoming Matches */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-white mb-6">โปรแกรมไก่ชนวันนี้</h2>
          <div className="grid md:grid-cols-2 gap-6">
            {upcomingMatches.map((match, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-gradient-to-br from-red-900/30 to-black p-6 rounded-xl"
              >
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-xl font-bold text-white">{match.title}</h3>
                  <span className="text-yellow-500 font-bold">{match.odds}</span>
                </div>
                <div className="space-y-2 text-gray-300">
                  <p>เวลา: {match.time}</p>
                  <p>สนาม: {match.location}</p>
                  <div className="flex flex-wrap gap-2 mt-3">
                    {match.details.map((detail, idx) => (
                      <span key={idx} className="text-sm bg-red-900/30 text-red-400 px-2 py-1 rounded">
                        {detail}
                      </span>
                    ))}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};