import React from 'react';
import { Link } from 'react-router-dom';

interface BrandLinkProps {
  name: string;
  className?: string;
}

export const BrandLink: React.FC<BrandLinkProps> = ({ name, className }) => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <Link 
      to="/" 
      className={`font-bold text-red-500 hover:text-red-400 transition-colors ${className || ''}`}
      onClick={handleClick}
    >
      {name}
    </Link>
  );
}; 