import React from 'react';
import { motion } from 'framer-motion';

interface TubeBurstProps {
  isVisible: boolean;
}

export const TubeBurst: React.FC<TubeBurstProps> = ({ isVisible }) => {
  if (!isVisible) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="absolute inset-0 pointer-events-none"
    >
      {/* Burst rays */}
      {[...Array(12)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute top-1/2 left-1/2 w-1 h-20 bg-yellow-500/50"
          initial={{ 
            rotate: i * 30,
            scale: 0,
            opacity: 1
          }}
          animate={{ 
            scale: [0, 1.5],
            opacity: [1, 0],
          }}
          transition={{
            duration: 1,
            repeat: 2,
            delay: i * 0.1
          }}
          style={{
            transformOrigin: 'center bottom',
          }}
        />
      ))}

      {/* Light flash */}
      <motion.div
        className="absolute inset-0 bg-yellow-500/30"
        initial={{ opacity: 0 }}
        animate={{ 
          opacity: [0, 0.5, 0],
        }}
        transition={{
          duration: 0.5,
          repeat: 3,
        }}
      />

      {/* Particles */}
      {[...Array(20)].map((_, i) => (
        <motion.div
          key={`particle-${i}`}
          className="absolute top-1/2 left-1/2 w-2 h-2 rounded-full bg-yellow-500"
          initial={{ 
            x: 0,
            y: 0,
            scale: 0,
            opacity: 1
          }}
          animate={{ 
            x: Math.random() * 200 - 100,
            y: Math.random() * 200 - 100,
            scale: [0, 1, 0],
            opacity: [1, 1, 0],
          }}
          transition={{
            duration: 1,
            repeat: 2,
            delay: i * 0.1
          }}
        />
      ))}
    </motion.div>
  );
};