
import { motion } from 'framer-motion';
import { Play, Star } from 'lucide-react';

interface CasinoGame {
  title: string;
  image: string;
  provider: string;
  minBet: string;
  maxBet: string;
  features: string[];
}

const casinoGames: CasinoGame[] = [
  // {
  //   title: "บาคาร่าออนไลน์",
  //   image: "https://images.unsplash.com/photo-1511193311914-0346f16efe90?w=800",
  //   provider: "SA Gaming",
  //   minBet: "10",
  //   maxBet: "100,000",
  //   features: ["ดีลเลอร์สาวสวย", "โต๊ะไม่จำกัด", "มีสูตรแนะนำ"]
  // },
  // {
  //   title: "รูเล็ตสด",
  //   image: "https://images.unsplash.com/photo-1606167668584-78701c57f13d?w=800",
  //   provider: "Evolution Gaming",
  //   minBet: "50",
  //   maxBet: "200,000",
  //   features: ["รูเล็ตหลายรูปแบบ", "วงล้อคุณภาพ", "ระบบวิเคราะห์สถิติ"]
  // },
  // {
  //   title: "ไฮโลไทย",
  //   image: "https://images.unsplash.com/photo-1518544801976-3e159e50e5bb?w=800",
  //   provider: "Sexy Gaming",
  //   minBet: "20",
  //   maxBet: "50,000",
  //   features: ["ไฮโลสไตล์ไทย", "เล่นง่าย", "อัตราจ่ายสูง"]
  // }
];

export const LiveCasino = () => {
  return (
    <div className="py-8 md:py-16 bg-gradient-to-b from-red-900/20 to-black">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-8 md:mb-12"
        >
          {/* <h2 className="mobile-heading text-white mb-3 md:mb-4">
            คาสิโนสดยอดนิยม
          </h2>
          <p className="mobile-text text-gray-300 max-w-2xl mx-auto">
            เลือกเล่นคาสิโนสดจากค่ายดังระดับโลก พร้อมดีลเลอร์สาวสวย และระบบถ่ายทอดสด HD
          </p> */}
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
          {casinoGames.map((game, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gradient-to-br from-red-900/30 to-black rounded-xl overflow-hidden group"
            >
              <div className="relative">
                <img 
                  src={game.image} 
                  alt={game.title} 
                  className="w-full h-40 md:h-48 object-cover transform group-hover:scale-110 transition-transform duration-500"
                />
                <div className="absolute top-2 right-2 bg-red-600 text-white px-2 py-1 rounded text-xs md:text-sm font-bold">
                  Live
                </div>
              </div>
              
              <div className="p-4">
                <h3 className="text-lg md:text-xl font-bold text-white mb-2">{game.title}</h3>
                <p className="text-sm text-gray-400 mb-2">ค่าย: {game.provider}</p>
                
                <div className="flex flex-wrap gap-2 mb-3">
                  {game.features.map((feature, idx) => (
                    <span key={idx} className="text-xs bg-red-900/30 text-red-400 px-2 py-1 rounded">
                      {feature}
                    </span>
                  ))}
                </div>
                
                <div className="flex justify-between text-xs md:text-sm text-gray-400 mb-4">
                  <span>เดิมพันต่ำสุด: {game.minBet}</span>
                  <span>สูงสุด: {game.maxBet}</span>
                </div>

                <div className="grid grid-cols-2 gap-2">
                  <button className="mobile-button bg-red-600 hover:bg-red-700 text-white transition-colors flex items-center justify-center gap-1">
                    <Play size={14} />
                    เล่นเกม
                  </button>
                  <button className="mobile-button border border-red-600 text-red-500 hover:bg-red-600 hover:text-white transition-colors flex items-center justify-center gap-1">
                    <Star size={14} />
                    ทดลองเล่น
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};