import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

interface BreadcrumbItem {
  label: string;
  path: string;
  isLast: boolean;
}

interface BreadcrumbsProps {
  customItems?: BreadcrumbItem[];
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ customItems }) => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  // แมปสำหรับแปลงเส้นทาง URL เป็นชื่อที่อ่านได้ง่าย พร้อมคำสำคัญ NIGOAL
  const pathMappings: { [key: string]: string } = {
    '': 'NIGOAL เว็บพนันออนไลน์อันดับ 1',
    slots: 'NIGOAL สล็อตออนไลน์ ทดลองเล่นฟรี',
    casino: 'NIGOAL คาสิโนออนไลน์ บาคาร่า รูเล็ต',
    sports: 'NIGOAL แทงบอลออนไลน์ ราคาน้ำดีที่สุด',
    demo: 'ทดลองเล่น NIGOAL ฟรีไม่ต้องฝาก',
    articles: 'บทความ NIGOAL รวมเคล็ดลับการเล่นเกม',
    help: 'ช่วยเหลือ NIGOAL บริการลูกค้า',
    promotions: 'โปรโมชั่น NIGOAL โบนัสฝากแรก 100%',
    line: 'ติดต่อ NIGOAL ผ่านไลน์ บริการ 24 ชม.',
    'welcome-bonus': 'NIGOAL โบนัสต้อนรับสมาชิกใหม่ 100%',
    cashback: 'NIGOAL เงินคืนทุกยอดเสีย 5%',
    'refer-friend': 'NIGOAL ชวนเพื่อนรับโบนัส 10%',
    'free-credit': 'NIGOAL เครดิตฟรี ไม่ต้องฝาก',
    registration: 'วิธีสมัครสมาชิก NIGOAL ง่ายๆใน 1 นาที',
    'deposit-withdraw': 'วิธีฝาก-ถอนเงิน NIGOAL ระบบออโต้',
    terms: 'ข้อตกลงและเงื่อนไข NIGOAL',
    contact: 'ติดต่อ NIGOAL ฝ่ายบริการลูกค้า 24 ชม.'
  };

  // สร้างรายการขนมปังที่จะแสดง
  const breadcrumbs = customItems || pathnames.map((name, index) => {
    const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
    const isLast = index === pathnames.length - 1;
    const label = pathMappings[name] || `NIGOAL ${name}`;
    
    return {
      label,
      path: routeTo,
      isLast
    };
  });

  // ถ้าไม่มี customItems และอยู่ที่หน้าแรก ให้แสดงเพียง "NIGOAL หน้าแรก"
  if (!customItems && pathnames.length === 0) {
    breadcrumbs.push({
      label: pathMappings[''],
      path: '/',
      isLast: true
    });
  }

  // สร้าง Schema.org BreadcrumbList สำหรับ SEO
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": pathMappings[''],
        "item": "https://www.azulejosalcor.com/"
      },
      ...breadcrumbs.map((breadcrumb, index) => ({
        "@type": "ListItem",
        "position": index + 2,
        "name": breadcrumb.label,
        "item": `https://www.azulejosalcor.com${breadcrumb.path}`
      }))
    ]
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
      </Helmet>
      
      <nav className="bg-black/50 py-2 px-4 text-sm breadcrumbs" aria-label="NIGOAL Navigation Breadcrumbs">
        <ol className="flex flex-wrap items-center space-x-1" itemScope itemType="https://schema.org/BreadcrumbList">
          {!customItems && (
            <li className="flex items-center" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
              <Link to="/" className="hover:text-red-500 transition-colors" itemProp="item">
                <span itemProp="name">{pathMappings['']}</span>
              </Link>
              <meta itemProp="position" content="1" />
              {pathnames.length > 0 && (
                <span className="mx-1 text-gray-400">/</span>
              )}
            </li>
          )}

          {breadcrumbs.map((breadcrumb, index) => (
            <li key={breadcrumb.path} className="flex items-center" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
              {breadcrumb.isLast ? (
                <span className="text-red-500" itemProp="name">{breadcrumb.label}</span>
              ) : (
                <>
                  <Link to={breadcrumb.path} className="hover:text-red-500 transition-colors" itemProp="item">
                    <span itemProp="name">{breadcrumb.label}</span>
                  </Link>
                  <span className="mx-1 text-gray-400">/</span>
                </>
              )}
              <meta itemProp="position" content={String(index + 2)} />
            </li>
          ))}
        </ol>
      </nav>
    </>
  );
}; 