
import { motion } from 'framer-motion';
import { Ticket, Clock, Calculator, Gift } from 'lucide-react';

const lotteries = [
  {
    title: "หวยรัฐบาล",
    image: "https://images.unsplash.com/photo-1518544801976-3e159e50e5bb?w=800",
    description: "หวยรัฐบาลไทย งวดวันที่ 1 และ 16 ของทุกเดือน",
    payout: "จ่ายสูงสุดบาทละ 900",
    features: ["รางวัลที่ 1", "เลขหน้า 3 ตัว", "เลขท้าย 3 ตัว", "เลขท้าย 2 ตัว"]
  },
  {
    title: "หวยยี่กี",
    image: "https://images.unsplash.com/photo-1522778119026-d647f0596c20?w=800",
    description: "ออกรางวัลทุก 15 นาที ตลอด 24 ชั่วโมง",
    payout: "จ่ายสูงสุดบาทละ 850",
    features: ["88 รอบต่อวัน", "เล่นง่าย", "ได้เงินไว", "ลุ้นได้ทุก 15 นาที"]
  },
  {
    title: "หวยหุ้นไทย",
    image: "https://images.unsplash.com/photo-1590283603385-17ffb3a7f29f?w=800",
    description: "อ้างอิงดัชนีหุ้น SET50 ออกผลทุกวันจันทร์-ศุกร์",
    payout: "จ่ายสูงสุดบาทละ 850",
    features: ["เปิดเช้า-เย็น", "อ้างอิงตลาดหุ้น", "ผลออกจริง", "เชื่อถือได้"]
  },
  {
    title: "หวยหุ้นต่างประเทศ",
    image: "https://images.unsplash.com/photo-1611974789855-9c2a0a7236a3?w=800",
    description: "หวยหุ้นต่างประเทศ ฮั่งเส็ง นิเคอิ ดาวโจนส์",
    payout: "จ่ายสูงสุดบาทละ 850",
    features: ["หลายประเทศ", "หลายรอบ", "มาตรฐานสากล", "ผลออกแน่นอน"]
  },
  {
    title: "หวยลาว",
    image: "https://images.unsplash.com/photo-1553729459-efe14ef6055d?w=800",
    description: "หวยพัฒนาลาว ออกรางวัลทุกวันจันทร์ พุธ ศุกร์",
    payout: "จ่ายสูงสุดบาทละ 850",
    features: ["สามรอบต่อสัปดาห์", "ผลออกเร็ว", "จ่ายเต็ม", "เชื่อถือได้"]
  },
  {
    title: "หวยมาเลย์",
    image: "https://images.unsplash.com/photo-1602526432604-029a709e131c?w=800",
    description: "หวยมาเลเซีย ออกรางวัลทุกวันพุธ เสาร์ อาทิตย์",
    payout: "จ่ายสูงสุดบาทละ 850",
    features: ["สามรอบต่อสัปดาห์", "ผลออกตรงเวลา", "มั่นคง", "จ่ายจริง"]
  }
];

const features = [
  {
    icon: Clock,
    title: "แทงได้ 24 ชั่วโมง",
    description: "ระบบแทงหวยออนไลน์อัตโนมัติ เปิดให้บริการตลอด 24 ชั่วโมง"
  },
  {
    icon: Calculator,
    title: "จ่ายสูงสุด 900 บาท",
    description: "อัตราจ่ายสูงสุดบาทละ 900 จ่ายจริง จ่ายเต็ม ไม่มีอั้น"
  },
  {
    icon: Gift,
    title: "โปรโมชั่นพิเศษ",
    description: "รับโบนัสแทงหวยสูงสุด 20% พร้อมคืนยอดเสีย 10% ทุกสัปดาห์"
  }
];

export const LotteryPage = () => {
  return (
    <div className="pt-16 min-h-screen bg-gradient-to-b from-black via-red-900/20 to-black">
      <div className="container mx-auto px-4 py-8">
        {/* Hero Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <div className="flex items-center justify-center gap-3 mb-4">
            <Ticket className="text-red-500" size={32} />
            <h1 className="text-3xl font-bold text-white">หวยออนไลน์</h1>
          </div>
          <p className="text-gray-300 max-w-2xl mx-auto">
            แทงหวยออนไลน์ ครบทุกหวย จ่ายสูงสุดบาทละ 900 
            ระบบอัตโนมัติ ฝาก-ถอนไว แทงได้ตลอด 24 ชั่วโมง
          </p>
        </motion.div>

        {/* Features */}
        <div className="grid md:grid-cols-3 gap-6 mb-12">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gradient-to-br from-red-900/30 to-black p-6 rounded-xl text-center"
            >
              <feature.icon className="text-red-500 w-12 h-12 mx-auto mb-4" />
              <h3 className="text-xl font-bold text-white mb-2">{feature.title}</h3>
              <p className="text-gray-300">{feature.description}</p>
            </motion.div>
          ))}
        </div>
        
        {/* Lottery Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {lotteries.map((lottery, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gradient-to-br from-red-900/30 to-black rounded-xl overflow-hidden group"
            >
              <div className="relative">
                <img 
                  src={lottery.image} 
                  alt={lottery.title} 
                  className="w-full h-48 object-cover transform group-hover:scale-110 transition-transform duration-500"
                />
                <div className="absolute top-2 right-2 bg-red-600 text-white px-2 py-1 rounded text-sm font-bold">
                  {lottery.payout}
                </div>
              </div>
              
              <div className="p-4">
                <h3 className="text-xl font-bold text-white mb-2">{lottery.title}</h3>
                <p className="text-gray-400 text-sm mb-3">{lottery.description}</p>
                
                <div className="flex flex-wrap gap-2 mb-4">
                  {lottery.features.map((feature, idx) => (
                    <span key={idx} className="text-xs bg-red-900/30 text-red-400 px-2 py-1 rounded">
                      {feature}
                    </span>
                  ))}
                </div>

                <button className="w-full bg-red-600 hover:bg-red-700 text-white py-2 rounded-lg transition-colors">
                  แทงหวย
                </button>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};