
import { SportsHero } from '../../components/sports/SportsHero';
import { LiveSports } from '../../components/sports/LiveSports';
import { SportsFeatures } from '../../components/sports/SportsFeatures';
import { SportsGuide } from '../../components/sports/SportsGuide';
import { SportsSEOContent } from '../../components/sports/SportsSEOContent';
import { CoinRain } from '../../components/effects/CoinRain';

export const SportsPage = () => {
  return (
    <div className="min-h-screen bg-black">
      <SportsHero />
      <LiveSports />
      <SportsFeatures />
      <SportsGuide />
      <SportsSEOContent />
      <CoinRain />
    </div>
  );
};