import { motion } from 'framer-motion';
import { Shield, Zap, Gift, Users, CreditCard, Clock } from 'lucide-react';

const features = [
  {
    icon: Shield,
    title: "NIGOAL ระบบความปลอดภัยสูง",
    description: "มาตรฐานความปลอดภัยระดับสากล การเข้ารหัส SSL 256-bit"
  },
  {
    icon: Zap,
    title: "NIGOAL ระบบถ่ายทอดสด HD",
    description: "ภาพคมชัดระดับ Full HD ไม่มีสะดุด เสียงชัดเจน"
  },
  {
    icon: Gift,
    title: "NIGOALโบนัสและโปรโมชั่น",
    description: "รับโบนัสต้อนรับ 100% พร้อมโปรโมชั่นคืนยอดเสียทุกสัปดาห์"
  },
  {
    icon: Users,
    title: "NIGOALดีลเลอร์มืออาชีพ",
    description: "ดีลเลอร์สาวสวยระดับพรีเมียม ผ่านการฝึกอบรมมาอย่างดี"
  },
  {
    icon: CreditCard,
    title: "NIGOAL ฝาก-ถอนไม่มีขั้นต่ำ",
    description: "ระบบฝาก-ถอนอัตโนมัติ รวดเร็วภายใน 30 วินาที"
  },
  {
    icon: Clock,
    title: "NIGOAL เปิดให้บริการ 24 ชม.",
    description: "เล่นได้ตลอด 24 ชั่วโมง พร้อมทีมงานดูแลตลอดเวลา"
  }
];

export const CasinoFeatures = () => {
  return (
    <div className="py-16 bg-gradient-to-b from-black to-red-900/20">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold text-white mb-4">
            NIGOAL จุดเด่นของคาสิโนออนไลน์
          </h2>
          <p className="text-gray-300 max-w-2xl mx-auto">
            มาตรฐานระดับโลก พร้อมระบบที่ทันสมัยที่สุด เพื่อประสบการณ์การเล่นที่ดีที่สุด จาก nigoal
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gradient-to-br from-red-900/30 to-black p-6 rounded-xl"
            >
              <feature.icon className="text-red-500 w-12 h-12 mb-4" />
              <h3 className="text-xl font-bold text-white mb-2">{feature.title}</h3>
              <p className="text-gray-300">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};