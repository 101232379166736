import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { HomeHero } from '../../components/home/HomeHero';
import { HomeFeatures } from '../../components/home/HomeFeatures';
import { HomeGames } from '../../components/home/HomeGames';
import { Promotions } from '../../components/home/Promotions';
import { LuckySpin } from '../../components/games/LuckySpin';
import { DemoGameContainer } from '../../components/demo/DemoGameContainer';
import { CoinRain } from '../../components/effects/CoinRain';
import { Breadcrumbs } from '../../components/navigation/Breadcrumbs';
import { motion } from 'framer-motion';
import { HomeInfo } from '../../components/home/HomeInfo';
import { BrandLink } from '../../components/common/BrandLink';

export const HomePage: React.FC = () => {
  // เพิ่ม state สำหรับควบคุมการแสดง CoinRain
  const [showCoinRain, setShowCoinRain] = useState(false);

  // เริ่มแสดง CoinRain หลังจากโหลดหน้าเสร็จ
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCoinRain(true);
    }, 1000); // หน่วงเวลา 1 วินาที

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>NIGOAL555 | เว็บพนันออนไลน์อันดับ 1 สล็อต บาคาร่า คาสิโน กีฬา เครดิตฟรี 100,000</title>
        <meta name="title" content="NIGOAL555 | เว็บพนันออนไลน์อันดับ 1 สล็อต บาคาร่า คาสิโน กีฬา เครดิตฟรี 100,000" />
        <meta name="description" content="NIGOAL555 - เว็บพนันออนไลน์อันดับ 1 ในไทย ไม่ต้องฝาก ไม่ต้องสมัคร เครดิตฟรี 100,000 บาท สล็อต บาคาร่า คาสิโน แทงบอล ระบบออโต้รวดเร็ว ฝาก-ถอนภายใน 30 วินาที" />
        <meta name="keywords" content="NIGOAL, nigoal, nigoal555" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.azulejosalcor.com/" />
        <meta property="og:title" content="NIGOAL | ทดลองเล่นเกมส์ฟรี สล็อต บาคาร่า คาสิโน กีฬา เครดิตฟรี 100,000" />
        <meta property="og:description" content="NIGOAL - เล่นเกมส์ฟรีไม่ต้องฝาก ไม่ต้องสมัคร เครดิตฟรี 100,000 ระบบออโต้รวดเร็ว ฝาก-ถอนภายใน 30 วินาที" />
        <meta property="og:image" content="images/promotions/logo.webp" />
        
        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.azulejosalcor.com/" />
        <meta property="twitter:title" content="NIGOAL555 | ทดลองเล่นเกมส์ฟรี สล็อต บาคาร่า คาสิโน กีฬา" />
        <meta property="twitter:description" content="NIGOAL - เล่นเกมส์ฟรีไม่ต้องฝาก ไม่ต้องสมัคร เครดิตฟรี 100,000 บาท" />
        <meta property="twitter:image" content="images/promotions/logo.webp" />
        
        {/* Additional SEO Tags */}
        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
        <meta name="author" content="NIGOAL555" />
        <meta name="application-name" content="NIGOAL555" />
        <meta name="msapplication-TileColor" content="#e11d48" />
        <meta name="theme-color" content="#000000" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
        
        {/* Canonical URL */}
        <link rel="canonical" href="https://www.azulejosalcor.com/" />
        
        {/* Preconnect to essential domains */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        
        {/* Structured Data / Schema.org */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "NIGOAL555 - ทดลองเล่นเกมส์ออนไลน์ฟรี",
            "url": "https://www.azulejosalcor.com/",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.azulejosalcor.com/search?q={search_term_string}",
              "query-input": "required name=search_term_string"
            },
            "description": "เว็บทดลองเล่นเกมส์ออนไลน์ฟรี ไม่ต้องฝาก ไม่ต้องสมัคร เล่นได้ทันที เครดิตฟรี 100,000 บาท",
            "publisher": {
              "@type": "Organization",
              "name": "NIGOAL",
              "logo": {
                "@type": "ImageObject",
                "url": "images/promotions/logo.webp"
              }
            }
          }
        `}</script>
        
        {/* Breadcrumb Schema */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "หน้าแรก",
                "item": "https://www.azulejosalcor.com/"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "ทดลองเล่นเกมส์ฟรี",
                "item": "https://www.azulejosalcor.com/demo"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "โปรโมชั่น",
                "item": "https://www.azulejosalcor.com/promotions"
              },
              {
                "@type": "ListItem",
                "position": 4,
                "name": "บทความ",
                "item": "https://www.azulejosalcor.com/articles"
              }
            ]
          }
        `}</script>
        
        {/* FAQ Schema */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "NIGOAL คืออะไร?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "NIGOAL คือเว็บทดลองเล่นเกมออนไลน์อันดับ 1 ในประเทศไทย ที่ให้บริการเกมพนันออนไลน์ครบวงจร ไม่ว่าจะเป็นการแทงบอล คาสิโน สล็อต และบาคาร่า ด้วยระบบที่ทันสมัยและปลอดภัย 100%"
                }
              },
              {
                "@type": "Question",
                "name": "nigoal555 มีบริการอะไรบ้าง?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "nigoal555 มีบริการเกมออนไลน์ครบวงจร ทั้งการแทงบอล คาสิโน สล็อต บาคาร่า พร้อมระบบฝาก-ถอนอัตโนมัติที่รวดเร็วภายใน 30 วินาที"
                }
              },
              {
                "@type": "Question",
                "name": "NIGOAL ให้บริการทดลองเล่นฟรีหรือไม่?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "ใช่ NIGOAL ให้บริการทดลองเล่นฟรี ไม่ต้องฝากเงิน ไม่ต้องสมัครสมาชิก พร้อมเครดิตทดลองเล่นสูงถึง 100,000 บาท เพื่อให้ผู้เล่นได้ทดลองเล่นเกมทุกประเภทก่อนตัดสินใจ"
                }
              }
            ]
          }
        `}</script>
      </Helmet>
      
      {/* Breadcrumbs */}
      <Breadcrumbs />
      
      <HomeHero />
      <div className="py-8 md:py-16 bg-gradient-to-b from-black to-red-900/20">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="text-center mb-8 md:mb-12"
          >
            <h1 className="text-3xl md:text-4xl font-bold text-white mb-3 md:mb-4">
              <BrandLink name="NIGOAL" /> ทดลองเล่นเกมส์ฟรี
            </h1>
            <p className="text-sm md:text-base text-gray-300 max-w-2xl mx-auto">
              เล่นได้ฟรี ไม่ต้องฝาก ไม่ต้องสมัครสมาชิก พร้อมเครดิตทดลองเล่น <strong className="text-yellow-500">100,000 บาท</strong>
            </p>
          </motion.div>
          <div className="max-w-3xl mx-auto">
            <DemoGameContainer title="Fortune Tiger (สล็อต)" />
          </div>
        </div>
      </div>
      <HomeFeatures />
      <LuckySpin />
      <HomeGames />
      <Promotions />
      
      {/* เพิ่มเนื้อหาคุณภาพเพื่อ SEO */}
      <section className="py-10 bg-gradient-to-b from-black to-black-900">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <h1 className="text-3xl font-bold text-white mb-6"><BrandLink name="NIGOAL" /> - เว็บทดลองเล่นเกมออนไลน์อันดับ 1 ในประเทศไทย</h1>
            <p>
              <BrandLink name="NIGOAL" /> (นิโกล หรือ <BrandLink name="nigoal" />) คือเว็บทดลองเล่นเกมออนไลน์อันดับ 1 ในประเทศไทย ที่ให้บริการเกมพนันออนไลน์ครบวงจร ไม่ว่าจะเป็นการแทงบอล คาสิโน สล็อต และบาคาร่า ด้วยระบบที่ทันสมัยและปลอดภัย 100% เราเป็นผู้นำในอุตสาหกรรมเกมออนไลน์ที่มุ่งมั่นในการนำเสนอประสบการณ์การเล่นเกมที่ดีที่สุดให้กับผู้เล่นชาวไทย
            </p>
            
            <h2 className="text-2xl font-semibold text-white mt-6">ทำไมต้องเลือก <BrandLink name="NIGOAL" />?</h2>
            <p>
              <BrandLink name="nigoal555" /> ได้รับความไว้วางใจจากผู้เล่นกว่าแสนคนทั่วประเทศไทย ด้วยระบบที่มีความเสถียร ปลอดภัย และมีความน่าเชื่อถือสูง เรามีจุดเด่นที่ทำให้ <BrandLink name="nigoal" /> แตกต่างจากเว็บอื่นๆ ดังนี้
            </p>
            
            <ul className="list-disc pl-5 space-y-2">
              <li><strong className="text-red-500">ระบบฝาก-ถอนออโต้</strong> - ด้วยเทคโนโลยีล่าสุด ทำให้การฝากและถอนเงินของคุณเสร็จสิ้นภายใน 30 วินาที ไม่ต้องรอนาน</li>
              <li><strong className="text-red-500">ทดลองเล่นฟรี</strong> - <BrandLink name="NIGOAL" /> มอบเครดิตทดลองเล่นฟรีมูลค่าสูงถึง 100,000 บาท โดยไม่ต้องฝากเงินหรือสมัครสมาชิก</li>
              <li><strong className="text-red-500">เกมครบวงจร</strong> - รวมเกมออนไลน์ชั้นนำจากทั่วโลกไว้ในที่เดียว ทั้งกีฬา คาสิโน สล็อต และบาคาร่า</li>
              <li><strong className="text-red-500">ความปลอดภัยสูง</strong> - ระบบรักษาความปลอดภัยมาตรฐานระดับสากล ปกป้องข้อมูลและธุรกรรมทางการเงินของคุณ</li>
              <li><strong className="text-red-500">บริการลูกค้า 24/7</strong> - ทีมงานมืออาชีพของ <BrandLink name="nigoal" /> พร้อมให้บริการตลอด 24 ชั่วโมง ทุกวัน</li>
            </ul>
            
            <h2 className="text-2xl font-semibold text-white mt-6">บริการเกมออนไลน์ที่ <BrandLink name="NIGOAL" /></h2>
            <p>
              <BrandLink name="NIGOAL" /> นำเสนอเกมออนไลน์หลากหลายประเภทให้คุณได้เลือกเล่นตามความชอบบนแพลตฟอร์ม <BrandLink name="nigoal" />:
            </p>
            
            <div className="prose prose-lg max-w-4xl mx-auto text-gray-300">
              <h3 className="text-xl font-medium text-white mt-4">การแทงบอลออนไลน์บน <BrandLink name="NIGOAL" /></h3>
              <p>
                ครอบคลุมการแข่งขันฟุตบอลจากลีกชั้นนำทั่วโลก มีอัตราต่อรองที่ดีที่สุด และรูปแบบการเดิมพันที่หลากหลาย ทั้งแบบ 1x2, แฮนดิแคป, สูง/ต่ำ และอื่นๆ อีกมากมาย พร้อมด้วยระบบถ่ายทอดสดคุณภาพสูงให้คุณไม่พลาดทุกจังหวะการแข่งขันกับ <BrandLink name="nigoal" />
              </p>
              
              <h3 className="text-xl font-medium text-white mt-4">คาสิโนออนไลน์ <BrandLink name="NIGOAL" /></h3>
              <p>
                <BrandLink name="nigoal555" /> รวมเกมคาสิโนยอดนิยมไว้มากมาย ทั้งบาคาร่า รูเล็ต แบล็คแจ็ค ไฮโล และเกมไพ่อื่นๆ ด้วยดีลเลอร์สาวสวยที่ถ่ายทอดสดตรงจากคาสิโนชั้นนำ คุณจะได้สัมผัสประสบการณ์การเล่นคาสิโนเสมือนจริงกับ <BrandLink name="nigoal" />
              </p>
              
              <h3 className="text-xl font-medium text-white mt-4">สล็อตออนไลน์ <BrandLink name="NIGOAL" /></h3>
              <p>
                <BrandLink name="nigoal" /> รวมเกมสล็อตมากกว่า 1,000 เกม จากค่ายเกมชั้นนำทั่วโลก ทั้ง PG Slot, Joker Gaming, Pragmatic Play และอื่นๆ อีกมากมาย มีทั้งเกมสล็อตคลาสสิกและเกมสล็อตวิดีโอธีมใหม่ล่าสุด พร้อมแจ็คพอตและโบนัสมากมายที่ <BrandLink name="nigoal555" />
              </p>
              
              <h3 className="text-xl font-medium text-white mt-4">บาคาร่าออนไลน์ <BrandLink name="NIGOAL" /></h3>
              <p>
                เกมไพ่ยอดนิยมที่ <BrandLink name="NIGOAL" /> นำเสนอด้วยรูปแบบการเล่นที่หลากหลาย ทั้งบาคาร่าทั่วไป, Speed Baccarat, และ Super Six โดยมีห้องให้เลือกเล่นมากมาย และมีอัตราการจ่ายเงินที่สูงที่สุดที่ <BrandLink name="nigoal" />
              </p>
              
              <h2 className="text-2xl font-semibold text-white mt-6">ระบบฝาก-ถอนออโต้ที่รวดเร็วของ <BrandLink name="NIGOAL" /></h2>
              <p>
                <BrandLink name="nigoal555" /> ภูมิใจนำเสนอระบบฝาก-ถอนอัตโนมัติที่รวดเร็วและเชื่อถือได้ ด้วยเทคโนโลยีล่าสุด ทำให้การทำธุรกรรมของคุณเสร็จสิ้นภายในเวลาเพียง 30 วินาที รองรับการฝากผ่านธนาคารชั้นนำทุกแห่งในประเทศไทย และระบบการชำระเงินอิเล็กทรอนิกส์ยอดนิยม ไม่มีขั้นต่ำในการฝาก และถอนขั้นต่ำเพียง 100 บาทเท่านั้นที่ <BrandLink name="nigoal" />
              </p>
              
              <h2 className="text-2xl font-semibold text-white mt-6">โปรโมชั่นสุดพิเศษจาก <BrandLink name="NIGOAL" /></h2>
              <p>
                <BrandLink name="NIGOAL" /> มอบโปรโมชั่นสุดพิเศษมากมายให้กับสมาชิกทุกคน ไม่ว่าจะเป็นโบนัสต้อนรับสมาชิกใหม่ โบนัสเติมเงิน คืนยอดเสีย และโปรโมชั่นพิเศษตามเทศกาลต่างๆ คุณจะไม่พลาดทุกข้อเสนอพิเศษกับ <BrandLink name="nigoal555" />
              </p>
              
              <h2 className="text-2xl font-semibold text-white mt-6">ทำไม <BrandLink name="NIGOAL" /> ถึงเป็นตัวเลือกที่ดีที่สุด</h2>
              <p>
                <BrandLink name="nigoal" /> มุ่งมั่นที่จะมอบประสบการณ์การเล่นเกมออนไลน์ที่ดีที่สุดให้กับผู้เล่นชาวไทย ด้วยระบบที่เสถียร ปลอดภัย และใช้งานง่าย เรามีทีมงานมืออาชีพที่พร้อมให้บริการและช่วยเหลือคุณตลอด 24 ชั่วโมง หากคุณกำลังมองหาเว็บเกมออนไลน์ที่ครบวงจรและน่าเชื่อถือ <BrandLink name="nigoal555" /> คือคำตอบสุดท้ายของคุณ
              </p>
              
              <h2 className="text-2xl font-semibold text-white mt-6">ร่วมสนุกกับ <BrandLink name="NIGOAL" /> วันนี้</h2>
              <p>
                สมัครสมาชิกกับ <BrandLink name="nigoal" /> วันนี้ เพื่อรับสิทธิพิเศษมากมาย และสัมผัสประสบการณ์การเล่นเกมออนไลน์ที่เหนือระดับ เพียงแค่ลงทะเบียนง่ายๆ คุณก็จะสามารถเข้าถึงโลกแห่งความบันเทิงที่ไม่มีวันสิ้นสุดกับ <BrandLink name="nigoal555" />
              </p>
            </div>
          </motion.div>
        </div>
      </section>
      
      {/* FAQ Section */}
      <section className="py-10 bg-black">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl md:text-3xl font-bold text-white mb-6 text-center">คำถามที่พบบ่อยเกี่ยวกับ <BrandLink name="NIGOAL" /></h2>
          
          <div className="max-w-3xl mx-auto space-y-4">
            <div className="bg-red-900/30 rounded-lg p-4">
              <h3 className="text-lg font-semibold text-white"><BrandLink name="NIGOAL" /> คืออะไร?</h3>
              <p className="text-gray-300 mt-2"><BrandLink name="NIGOAL" /> คือเว็บทดลองเล่นเกมออนไลน์อันดับ 1 ในประเทศไทย ที่ให้บริการเกมพนันออนไลน์ครบวงจร ไม่ว่าจะเป็นการแทงบอล คาสิโน สล็อต และบาคาร่า ด้วยระบบที่ทันสมัยและปลอดภัย 100%</p>
            </div>
            
            <div className="bg-red-900/30 rounded-lg p-4">
              <h3 className="text-lg font-semibold text-white"><BrandLink name="nigoal555" /> มีบริการอะไรบ้าง?</h3>
              <p className="text-gray-300 mt-2"><BrandLink name="nigoal555" /> มีบริการเกมออนไลน์ครบวงจร ทั้งการแทงบอล คาสิโน สล็อต บาคาร่า พร้อมระบบฝาก-ถอนอัตโนมัติที่รวดเร็วภายใน 30 วินาที</p>
            </div>
            
            <div className="bg-red-900/30 rounded-lg p-4">
              <h3 className="text-lg font-semibold text-white"><BrandLink name="NIGOAL" /> ให้บริการทดลองเล่นฟรีหรือไม่?</h3>
              <p className="text-gray-300 mt-2">ใช่ <BrandLink name="NIGOAL" /> ให้บริการทดลองเล่นฟรี ไม่ต้องฝากเงิน ไม่ต้องสมัครสมาชิก พร้อมเครดิตทดลองเล่นสูงถึง 100,000 บาท เพื่อให้ผู้เล่นได้ทดลองเล่นเกมทุกประเภทก่อนตัดสินใจ</p>
            </div>
            
            <div className="bg-red-900/30 rounded-lg p-4">
              <h3 className="text-lg font-semibold text-white">ระบบฝาก-ถอนของ <BrandLink name="nigoal555" /> เป็นอย่างไร?</h3>
              <p className="text-gray-300 mt-2">ระบบฝาก-ถอนของ <BrandLink name="nigoal555" /> เป็นระบบอัตโนมัติที่รวดเร็ว ทำรายการเสร็จภายใน 30 วินาที มั่นคงและปลอดภัย 100% รองรับการทำธุรกรรมตลอด 24 ชั่วโมง</p>
            </div>
            
            <div className="bg-red-900/30  rounded-lg p-4">
              <h3 className="text-lg font-semibold text-white"><BrandLink name="NIGOAL" /> มีบริการช่วยเหลือลูกค้าหรือไม่?</h3>
              <p className="text-gray-300 mt-2"><BrandLink name="NIGOAL" /> มีทีมงานคุณภาพที่พร้อมให้บริการช่วยเหลือลูกค้าตลอด 24 ชั่วโมง ผ่านช่องทางไลน์ โทรศัพท์ และแชทอัตโนมัติบนเว็บไซต์ ให้คำปรึกษาและแก้ไขปัญหาด้วยความเป็นมืออาชีพ</p>
            </div>
          </div>
        </div>
      </section>
      
      {/* HTML Sitemap - เพิ่ม SEO และการค้นพบหน้าต่างๆ */}
      <section className="py-8 bg-gray-900/50">
        <div className="container mx-auto px-4">
          <div className="text-center mb-6">
            <h2 className="text-xl sm:text-2xl font-bold text-white">เว็บไซต์ <BrandLink name="NIGOAL" /></h2>
            <p className="text-sm text-gray-400 mt-1">เลือกหมวดหมู่ที่คุณสนใจ</p>
          </div>
          
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 max-w-4xl mx-auto">
            <div className="bg-black/30 rounded-lg p-4">
              <h3 className="text-base font-semibold text-red-500 mb-2">หน้าหลัก</h3>
              <ul className="space-y-1 text-sm">
                <li><a href="/" className="text-gray-300 hover:text-white transition-colors">หน้าแรก</a></li>
                <li><a href="/about" className="text-gray-300 hover:text-white transition-colors">เกี่ยวกับเรา</a></li>
                <li><a href="/promotions" className="text-gray-300 hover:text-white transition-colors">โปรโมชั่น</a></li>
                <li><a href="/help" className="text-gray-300 hover:text-white transition-colors">ช่วยเหลือ</a></li>
              </ul>
            </div>
            
            <div className="bg-black/30 rounded-lg p-4">
              <h3 className="text-base font-semibold text-red-500 mb-2">เกมส์</h3>
              <ul className="space-y-1 text-sm">
                <li><a href="/slots" className="text-gray-300 hover:text-white transition-colors">สล็อต</a></li>
                <li><a href="/casino" className="text-gray-300 hover:text-white transition-colors">คาสิโน</a></li>
                <li><a href="/sports" className="text-gray-300 hover:text-white transition-colors">กีฬา</a></li>
                <li><a href="/lottery" className="text-gray-300 hover:text-white transition-colors">หวย</a></li>
              </ul>
            </div>
            
            <div className="bg-black/30 rounded-lg p-4">
              <h3 className="text-base font-semibold text-red-500 mb-2">ทดลองเล่น</h3>
              <ul className="space-y-1 text-sm">
                <li><a href="/demo" className="text-gray-300 hover:text-white transition-colors">ทดลองเล่นฟรี</a></li>
                <li><a href="/demo/slots" className="text-gray-300 hover:text-white transition-colors">สล็อตออนไลน์</a></li>
                <li><a href="/demo/casino" className="text-gray-300 hover:text-white transition-colors">คาสิโนออนไลน์</a></li>
                <li><a href="/demo/sports" className="text-gray-300 hover:text-white transition-colors">กีฬาออนไลน์</a></li>
              </ul>
            </div>
            
            <div className="bg-black/30 rounded-lg p-4">
              <h3 className="text-base font-semibold text-red-500 mb-2">บทความ</h3>
              <ul className="space-y-1 text-sm">
                <li><a href="/articles" className="text-gray-300 hover:text-white transition-colors">บทความทั้งหมด</a></li>
                <li><a href="/articles/slots-tips" className="text-gray-300 hover:text-white transition-colors">เทคนิคเล่นสล็อต</a></li>
                <li><a href="/articles/casino-guide" className="text-gray-300 hover:text-white transition-colors">วิธีเล่นคาสิโน</a></li>
                <li><a href="/articles/sports-betting" className="text-gray-300 hover:text-white transition-colors">แทงบอลออนไลน์</a></li>
              </ul>
            </div>
          </div>
          
          <div className="mt-8 text-center">
            <p className="text-xs text-gray-500">
              © {new Date().getFullYear()} <BrandLink name="NIGOAL" />. All Rights Reserved. <a href="/terms" className="text-gray-400 hover:text-white transition-colors">Terms of Service</a> | <a href="/privacy" className="text-gray-400 hover:text-white transition-colors">Privacy Policy</a>
            </p>
          </div>
        </div>
      </section>
      
      <HomeInfo />
      {/* เพิ่ม CoinRain component */}
      <CoinRain isVisible={showCoinRain} />
    </>
  );
};