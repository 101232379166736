
import { Facebook, Instagram, MessageCircle, Youtube } from 'lucide-react';

const socials = [
  { icon: Facebook, label: "Facebook", href: "#", color: "hover:text-blue-500" },
  { icon: Instagram, label: "Instagram", href: "#", color: "hover:text-pink-500" },
  { icon: MessageCircle, label: "Line Official", href: "#", color: "hover:text-green-500" },
  { icon: Youtube, label: "Youtube", href: "#", color: "hover:text-red-500" }
];

export const FooterSocial = () => {
  return (
    <div>
      <h3 className="text-lg font-bold text-white mb-4">ติดตามเรา</h3>
      <div className="flex space-x-4">
        {socials.map((social, index) => (
          <a
            key={index}
            href={social.href}
            className={`text-gray-400 ${social.color} transition-colors`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={social.label}
          >
            <social.icon size={24} />
          </a>
        ))}
      </div>
    </div>
  );
};