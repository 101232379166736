import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { User, Clock } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import type { Article } from '../../types/article';
import { FC } from 'react';
import { randomImage } from '../../utils/random';
interface ArticleContentProps {
  articleId: string;
}

export const ArticleContent: FC<ArticleContentProps> = ({ articleId }) => {
  const [article, setArticle] = useState<Article | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        setIsLoading(true);
        
        const response  = await fetch(`/static/articles/${articleId}.json`);
        const staticContent = await response.json();
        setArticle(staticContent.data);
      } catch (err) {
        setError('เกิดข้อผิดพลาดในการโหลดบทความ');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchArticle();
  }, [articleId]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-200" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 py-8">
        {error}
      </div>
    );
  }

  if (!article) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{article.title} | NiGoal555</title>
        <meta name="description" content={article.meta_description} />
        {/* Open Graph Tags */}
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="NIGOAL" />
        <meta property="og:title" content={article.og_title || article.title} />
        <meta property="og:description" content={article.og_description || article.meta_description} />
        <meta property="og:url" content={`https://www.azulejosalcor.com/articles/${articleId}`} />
        {article.og_image && <meta property="og:image" content={article.og_image} />}
        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={article.og_title || article.title} />
        <meta name="twitter:description" content={article.og_description || article.meta_description} />
        {article.og_image && <meta name="twitter:image" content={article.og_image} />}
        {/* Additional SEO Tags */}
        {article.canonical_url && <link rel="canonical" href={article.canonical_url} />}
        <meta name="author" content="nigoal555" />
        <meta name="keywords" content={article.content_keywords?.join(', ') || ''} />
        <meta name="robots" content="index, follow" />
        {/* Article Specific Metadata */}
        <meta property="article:published_time" content={article.created_at} />
        {article.updated_at && <meta property="article:modified_time" content={article.updated_at} />}
        <meta property="article:author" content="nigoal555" />
        {article.tags?.map((tag: string) => (
          <meta property="article:tag" content={tag} key={tag} />
        ))}
      </Helmet>

      <motion.article
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-gradient-to-br from-red-900/30 to-black rounded-xl overflow-hidden shadow-xl"
      >
        {article.og_image && (
          <div className="relative h-[300px] md:h-[400px]">
            <img 
              src={article.og_image}
              alt={`${article.title} - บทความเกี่ยวกับ ${article.tags?.join(', ') || 'NIGOAL'}`}
              className="w-full h-full object-cover"
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src = randomImage();
              }}
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent" />
          </div>
        )}

        <div className="p-6 md:p-8">
          {/* Article Header */}
          <header className="mb-8">
            <h1 className="text-3xl md:text-4xl font-bold text-red-500 mb-4 leading-tight">
              {article.title}
            </h1>
            
            <div className="flex flex-wrap gap-4 text-sm text-gray-400">
              <span className="flex items-center gap-2 bg-red-900/30 px-3 py-1.5 rounded-full">
                <User size={16} />
                nigoal555
              </span>
              <span className="flex items-center gap-2 bg-red-900/30 px-3 py-1.5 rounded-full">
                <Clock size={16} />
                {new Date(article.created_at).toLocaleDateString('th-TH')}
              </span>
            </div>
          </header>

          {/* Article Content */}
          <div className="prose prose-invert prose-lg max-w-none">
            {article.meta_description && (
              <div className="bg-red-900/20 border-l-4 border-red-500 p-4 rounded mb-8">
                <p className="text-lg text-gray-200 m-0">
                  {article.meta_description}
                </p>
              </div>
            )}
            
            <div 
              className="article-content"
              dangerouslySetInnerHTML={{ 
                __html: processArticleContent(article.content_html || '') 
              }} 
            />
          </div>
        </div>
      </motion.article>

      <style>{`
        .article-content {
          color: #e5e7eb;
          line-height: 1.8;
        }
        
        .article-content h2 {
          font-size: 1.875rem;
          font-weight: 700;
          color: #ef4444;
          margin-top: 2rem;
          margin-bottom: 1rem;
        }
        
        .article-content h3 {
          font-size: 1.5rem;
          font-weight: 600;
          color: #f87171;
          margin-top: 1.5rem;
          margin-bottom: 0.75rem;
        }
        
        .article-content p {
          margin-bottom: 1.25rem;
        }
        
        .article-content img {
          border-radius: 0.75rem;
          margin: 2rem 0;
          width: 100%;
          height: auto;
          object-fit: cover;
        }
        
        .article-content ul, .article-content ol {
          margin: 1.5rem 0;
          padding-left: 1.5rem;
        }
        
        .article-content li {
          margin: 0.5rem 0;
        }
        
        .article-content a {
          color: #ef4444;
          text-decoration: underline;
          transition: color 0.2s;
        }
        
        .article-content a:hover {
          color: #f87171;
        }
        
        .article-content blockquote {
          border-left: 4px solid #ef4444;
          padding-left: 1rem;
          margin: 1.5rem 0;
          font-style: italic;
          color: #9ca3af;
        }
        
        .article-content pre {
          background: rgba(139, 0, 0, 0.2);
          padding: 1rem;
          border-radius: 0.5rem;
          overflow-x: auto;
          margin: 1.5rem 0;
        }
        
        .article-content code {
          background: rgba(139, 0, 0, 0.2);
          padding: 0.2rem 0.4rem;
          border-radius: 0.25rem;
          font-size: 0.875rem;
        }
      `}</style>
    </>
  );
};

const processArticleContent = (content: string): string => {
  if (!content) return '<p>ไม่พบเนื้อหาบทความ</p>';
  
  return content.replace(
    /<img(.*?)src="([^"]*)"(.*?)>/g,
    (match, before, src, after) => {
      const altMatch = match.match(/alt="([^"]*)"/);
      let altText = altMatch ? altMatch[1] : '';
      
      if (!altText || altText === 'image') {
        const imgName = src.split('/').pop()?.split('.')[0] || '';
        altText = imgName
          .replace(/-/g, ' ')
          .replace(/_/g, ' ')
          .replace(/\d+$/, '')
          .trim();
        
        altText = altText.split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
          
        if (!altText.includes('NIGOAL')) {
          altText = `NIGOAL ${altText}`;
        }
      }
      
      if (altMatch) {
        before = before.replace(/alt="[^"]*"/, `alt="${altText}"`);
      } else {
        before = before + ` alt="${altText}"`;
      }
      
      return `<img${before}src="${src}" loading="lazy" onerror="this.onerror=null; this.src='/images/default-article.jpg'" class="rounded-xl shadow-lg"${after}>`;
    }
  );
};