import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Gamepad2, DollarSign, Shield, Gift } from 'lucide-react';
import { Link } from 'react-router-dom';
const bannerImages = [
  {
    url: "/images/promotions/br1.webp",
    title: "NIGOAL555 สล็อตออนไลน์ - เกมสล็อตคุณภาพจากค่ายดังทั่วโลก"
  },
  {
    url: "/images/promotions/br2.webp",
    title: "NIGOAL555 คาสิโนสด - บาคาร่า รูเล็ต และเกมคาสิโนออนไลน์ชั้นนำ"
  },
  {
    url: "/images/promotions/br1.webp",
    title: "NIGOAL555 แทงบอลออนไลน์ - ครบทุกลีก ราคาน้ำดีที่สุด"
  }
];

export const HomeHero = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % bannerImages.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-black via-red-900 to-black pt-16">
      {/* Banner Carousel */}
      <div className="w-full h-[150px] md:h-[500px] relative overflow-hidden border-y-4 border-red-600/50">
        <div className="absolute inset-0 bg-black/40 z-10" /> {/* Overlay */}
        
        <AnimatePresence mode="wait">
          <motion.div
            key={currentIndex}
            initial={{ opacity: 0, scale: 1.1 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.5 }}
            className="absolute inset-0"
          >
            <img 
              src={bannerImages[currentIndex].url}
              alt={bannerImages[currentIndex].title}
              className="w-full h-full object-cover"
            />
          </motion.div>
        </AnimatePresence>

        {/* Floating Images */}
        <div className="absolute inset-0 z-20 overflow-hidden">
          <motion.img
            src="/images/promotions/555.webp"
            alt="NIGOAL555 โปรโมชั่นเครดิตฟรี - รับเครดิตฟรี 100% สำหรับสมาชิกใหม่"
            className="absolute w-20 h-20 md:w-48 md:h-48 object-cover rounded-lg border-4 border-yellow-500/50 shadow-lg"
            animate={{
              x: ["0%", "20%", "0%"],
              y: ["0%", "10%", "0%"],
              rotate: [0, 5, 0],
            }}
            transition={{
              duration: 10,
              repeat: Infinity,
              ease: "linear"
            }}
            style={{ left: "10%", top: "20%" }}
          />

          <motion.img
            src="/images/promotions/promotion1.webp"
            alt="NIGOAL โปรโมชั่นสมาชิกใหม่ - รับโบนัสต้อนรับ 100% สำหรับการฝากครั้งแรก"
            className="absolute w-20 h-20 md:w-48 md:h-48 object-cover rounded-lg border-4 border-red-500/50 shadow-lg"
            animate={{
              x: ["0%", "-20%", "0%"],
              y: ["0%", "-10%", "0%"],
              rotate: [0, -5, 0],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              ease: "linear"
            }}
            style={{ right: "10%", bottom: "20%" }}
          />

          <motion.img
            src="/images/promotions/promotion3.webp"
            alt="NIGOAL โปรโมชั่นคืนยอดเสีย 5% - รับเงินคืนทุกยอดการเล่นแม้เสีย"
            className="absolute w-16 h-16 md:w-40 md:h-40 object-cover rounded-lg border-4 border-blue-500/50 shadow-lg"
            animate={{
              x: ["0%", "15%", "0%"],
              y: ["0%", "-15%", "0%"],
              rotate: [0, 10, 0],
            }}
            transition={{
              duration: 12,
              repeat: Infinity,
              ease: "linear"
            }}
            style={{ right: "30%", top: "30%" }}
          />
        </div>

        {/* Carousel Indicators */}
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-2 z-30">
          {bannerImages.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-3 h-3 rounded-full transition-colors ${
                index === currentIndex ? 'bg-red-500' : 'bg-white/50'
              }`}
            />
          ))}
        </div>
      </div>

      <div className="container mx-auto px-4 py-16">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            className="text-center lg:text-left"
          >
            <h1 className="text-2xl lg:text-4xl font-bold text-red-500 mb-6">
              NIGOAL555 เว็บพนันออนไลน์อันดับ 1
            </h1>
            <p className="text-l text-gray-300 mb-8">
              เว็บพนันออนไลน์ที่ดีที่สุด รวมเกมพนันครบวงจร สล็อต คาสิโน บาคาร่า
              กีฬา nigoal login ระบบฝาก-ถอนอัตโนมัติ 24 ชั่วโมง
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
            <a 
                href="https://app.nigoal555s.com/Register/reg/dFc0OFRJTkRMV3FDeDRvU214LzFsQT09" 
                target="_blank"
                rel="noopener noreferrer"
                className="bg-red-600 hover:bg-red-700 text-white px-8 py-3 rounded-full font-semibold transition-colors"
              >
                สมัครสมาชิก รับโบนัส 100%
              </a>
              <Link 
                to="/demo"
                className="border-2 border-red-600 text-red-500 hover:bg-red-600 hover:text-white px-8 py-3 rounded-full font-semibold transition-colors"
              >
                ทดลองเล่นสล็อตฟรี
              </Link>
            </div>
          </motion.div>

          <div className="grid grid-cols-2 gap-6">
            {[
              {
                icon: Gamepad2,
                title: 'เกมส์ครบครัน',
                desc: 'สล็อต บาคาร่า กีฬา หวย',
              },
              {
                icon: DollarSign,
                title: 'โบนัสสูงสุด',
                desc: 'รับโบนัสต้อนรับ 100%',
              },
              {
                icon: Shield,
                title: 'ปลอดภัย 100%',
                desc: 'ระบบความปลอดภัยมาตรฐานสากล',
              },
              {
                icon: Gift,
                title: 'โปรโมชั่นมากมาย',
                desc: 'แจกเครดิตฟรีทุกวัน',
              },
            ].map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                className="bg-gradient-to-br from-red-900/50 to-black p-6 rounded-xl backdrop-blur-sm border border-red-900/30"
              >
                <item.icon className="text-red-500 mb-4" size={40} />
                <h3 className="text-xl font-semibold text-white mb-2">
                  {item.title}
                </h3>
                <p className="text-gray-300">{item.desc}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
